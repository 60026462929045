import React, { useCallback, useEffect, useState } from 'react'
import { ColumnWidthOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Drawer, Spin, Empty, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import Gantt from './Gantt'
import Toolbar from './Toolbar'
import { GanttPageWrapperLeft, GanttWrapper } from './styled'
import ModalLinkGanttToDataTree from './ModalLinkGanttToDataTree'
import ModalLinkGanttToSavedQuery from './ModalLinkGanttToSavedQuery'
import { checkIsValidId } from './Gantt/ganttUtils'

const DrawerGanttPanel = ({
  projectGanttStore,
  commonStore,
  projectStore,
  viewer,
}) => {
  const { t } = useTranslation()
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  })
  const [drawerWidth, setDrawerWidth] = useState(
    isTabletOrMobileDevice ? 410 : 950
  )
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  moment.locale(`${commonStore.language}`)
  moment.locale(`${commonStore.language}`)
  const [currentZoom, setCurrentZoom] = useState({
    status: 'Days',
    isUpdate: true,
  })
  const [ganttData, setGanttData] = useState()
  const [currentProjectGanttId, setCurrentProjectGanttId] = useState('')
  const [zoomToFit, setZoomToFit] = useState({ status: false, isUpdate: true })

  //#region event for mouse and touche split panel
  const handleTouchStart = e => {
    e.preventDefault()
    if (e.touches.length !== 1) return null
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd, { passive: false })
    document.addEventListener('touchcancel', handleTouchEnd, { passive: false })
  }

  const handleTouchMove = useCallback(e => {
    const touch = e.touches[0] || e.changedTouches[0]
    const target = document.elementFromPoint(touch.clientX, touch.clientY)
    const newWidth =
      touch.clientX - (target && target.offsetRight ? target.offsetRight : 0)
    const minDrawerWidth = 360
    if (newWidth < 0) {
      setDrawerWidth(10)
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth)
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth)
    }
  }, [])

  const handleTouchEnd = () => {
    document.removeEventListener('touchend', handleTouchEnd, { passive: false })
    document.removeEventListener('touchcancel', handleTouchEnd, {
      passive: false,
    })
    document.removeEventListener('touchmove', handleTouchMove, {
      passive: false,
    })
  }

  const handleMouseDown = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback(e => {
    let offsetRight = e.clientX - document.body.offsetLeft
    let minDrawerWidth = 410
    if (offsetRight > minDrawerWidth) {
      setDrawerWidth(offsetRight)
    }
    if (offsetRight > window.innerWidth) {
      setDrawerWidth(window.innerWidth)
    }
  }, [])
  //#endregion

  const handleChangeScreenSize = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleChangeScreenSize)

  useEffect(() => {
    if (drawerWidth > screenWidth) {
      setDrawerWidth(screenWidth)
    }
  }, [screenWidth])

  const handleZoomChange = zoom => {
    setCurrentZoom(zoom)
  }
  const handleZoomtoFit = state => {
    setZoomToFit(state)
  }

  const resetLightboxUI = () => {
    projectGanttStore.setSelectedNodeInDataTree([])
    projectGanttStore.setSelectedSavedQuery([])
    projectGanttStore.setCurrentColorPicker('')
    projectGanttStore.setCurrentAlpha('0.5')
    projectGanttStore.setColorTaskEnable()
  }

  const handleErrorMessage = (type, action) =>{
    if(!type || !action) return
    message.error(t(`gantt.failed-to-${action}-${type}`))
    return t(`gantt.failed-to-${action}-${type}`)
  }

  const logDataUpdate = async (type, action, item, id, isDeletedOCreatedNew) => {
    const updateGantt = res => {
      resetLightboxUI()
      projectGanttStore.getProject4dGantt(projectStore.projectDetail.id)
      if (res?.status === 'error') {
        res.id = id
        return res
      }
      if (res?.id) {
        return res?.id
      }
    }

    const handleValidateTask = (type, action) => {
      const errorMessage = handleErrorMessage(type, action)
      return updateGantt({ status: 'error', errorMessage })
    }

    if (type === 'task') {
      let newGanttTask = {
        text: item.text,
        description: item?.description || '',
        duration: item.duration,
        parent: item.parent + '',
        progress: item.progress || 0,
        gantt: currentProjectGanttId,
        dataTree: projectGanttStore.isLightboxOpen ? projectGanttStore.selectedNodeInDataTree : item.dataTree,
        priority: item.priority || 'normal',
        taskType: item.taskType || 'new',
        highlightColor: (projectGanttStore.isLightboxOpen ? projectGanttStore.currentColorPicker : item.highlightColor) || '#ff0000',
        highlightAlpha: (projectGanttStore.isLightboxOpen ? projectGanttStore.currentAlpha : item.highlightAlpha) || '0.5',
        type: item.type || 'task',
        savedQuery: projectGanttStore.isLightboxOpen ? projectGanttStore.selectedSavedQuery : item.savedQuery,
        highlightEnable: projectGanttStore.isLightboxOpen ? (projectGanttStore.highlightEnable !== undefined ? projectGanttStore.highlightEnable : true) : item.highlightEnable,
        colorTaskEnable: projectGanttStore.isLightboxOpen ? (projectGanttStore.colorTaskEnable !== undefined ? projectGanttStore.colorTaskEnable : item.colorTaskEnable) : item.colorTaskEnable,
        render: Array.isArray(item?.render) ? (item.render.length > 0 ? item.render[0] : '') : item?.render ?? '',
        start_date: moment(item?.start_date || moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm'),
        end_date: moment(item?.end_date || moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm'),
      };

      if (item?.planned_start) {
        newGanttTask.planned_start = item.planned_start
      }
      if (item?.planned_end) {
        newGanttTask.planned_end = item.planned_end
      }

      // create thi kiem tra parent id
      // update thi kiem tra task id va parent id
      // delete thi kiem tra task id
      // eslint-disable-next-line default-case
      switch (action) {
        case 'create':
          if (Number(item.parent) !== 0 && !checkIsValidId(item.parent)) {
            return handleValidateTask(type, action)
          }
          break
        case 'update':
          if (!checkIsValidId(item.id) || (Number(item.parent) !== 0 && !checkIsValidId(item.parent))) {
            return handleValidateTask(type, action)
          }
          break
        case 'delete':
          if (!checkIsValidId(item.id)) {
            return handleValidateTask(type, action)
          }
          break
      }

      // set gantt default it saved in closed drawer gantt
      if (action !== 'delete') {
        const _defaultGantt = {
          ...projectGanttStore.ganttDefaults,
          userId: projectStore.projectDetail.currentUser._id,
          type: newGanttTask.type,
          taskType: newGanttTask.taskType,
          priority: newGanttTask.priority,
          progress: Number(newGanttTask?.progress) || 0,
          highlightEnable: newGanttTask.highlightEnable,
          colorTaskEnable: newGanttTask.colorTaskEnable,
          highlightColor: newGanttTask.highlightColor,
          highlightAlpha: newGanttTask.highlightAlpha,
        }
        let _projectMetadata = projectStore.projectDetail.metadata
        if (_projectMetadata && _projectMetadata.ganttDefault && _projectMetadata.ganttDefault.length > 0) {
          let index = projectStore.projectDetail.metadata.ganttDefault.findIndex(item => item.userId === projectStore.projectDetail.currentUser._id)
          if (index > -1) {
            const temp = [..._projectMetadata.ganttDefault]
            temp[index] = _defaultGantt
            _projectMetadata.ganttDefault = temp
          } else {
            let _data = _defaultGantt
            _projectMetadata.ganttDefault.push(_data)
          }
        } else {
          _projectMetadata.ganttDefault = [_defaultGantt]
        }
        projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
          projectStore.projectDetail.metadata = res.metadata
          projectGanttStore.setGanttDefaults(_defaultGantt)
        }).catch(err => {
          console.log(err)
        });
      }

      // case if new task or delete and action is update then only change type
      if (isDeletedOCreatedNew && action === 'update') {
        newGanttTask = {
          type: item.type || 'task',
        }
      }

      const ganttAction = {
        create: () => projectGanttStore.createGanttTask(newGanttTask),
        update: () => projectGanttStore.updateGanttTask(item.id, newGanttTask),
        delete: () => projectGanttStore.deleteGanttTask(item.id),
      }

      return await ganttAction[action]?.().then(updateGantt).catch(() => handleErrorMessage(type, action))
    } else if (type === 'link') {
      const newGanttLink = {
        source: item.source,
        target: item.target,
        type: item.type,
        gantt: currentProjectGanttId,
      }

      if (!checkIsValidId(item.source) || !checkIsValidId(item.target)) {
        return handleValidateTask(type, action)
      }

      const ganttAction = {
        create: () => projectGanttStore.createGanttLink(newGanttLink),
        update: () => projectGanttStore.updateGanttLink(item.id, newGanttLink),
        delete: () => projectGanttStore.deleteGanttLink(item.id),
      }

      return await ganttAction[action]?.().then(updateGantt).catch(() => handleErrorMessage(type, action))
    }
  }

  // handle add project gantt
  const handleCreateProject4dGantt = () => {
    const newGantt = {
      name: `project_gantt_${projectStore.projectDetail._id}`,
      project: projectStore.projectDetail._id,
    }
    projectGanttStore.createProject4dGantt(newGantt)
  }

  useEffect(() => {
    if (projectGanttStore.isShowGanttPanel) {
      let defaulTask = projectStore.projectDetail?.metadata?.ganttDefault?.find(item => item.userId === projectStore.projectDetail.currentUser._id);
      if (defaulTask) {
        projectGanttStore.setGanttDefaults(defaulTask)
      }
      projectGanttStore
        .getProject4dGantt(projectStore.projectDetail.id)
        .then(res => {
          if (res && !res?.length) {
            handleCreateProject4dGantt()
          }
        })
    }

    return () => {
      setGanttData()
      projectGanttStore.setUndoCount(0)
      projectGanttStore.setRedoCount(0)
      projectGanttStore.setBackOrForwardCount(0)
      projectGanttStore.setIsPlay4d(false)
      projectGanttStore.setProjectGanttData([]);
      projectGanttStore.setPlayerSpeed(1);
      projectGanttStore.setGroupType('task')
      projectGanttStore.setFilterTask('')
      projectGanttStore.setToggleChart(true)
      projectGanttStore.setShowColorTask(true)
      projectGanttStore.setIsCondensed(false)
    }
  }, [projectGanttStore.isShowGanttPanel])

  // effect on/off coloring task
  useEffect(() => {
    if (projectGanttStore.projectGanttData?.length > 0) {
      handlePreprocessProjectGanttData()
    }
  }, [projectGanttStore.showColorTask])
  

  const handlePreprocessProjectGanttData = () => {
    if (!projectGanttStore.projectGanttData?.length) {
      setGanttData()
      return
    }
    // current phase just use one gantt chart so we only need to element at the beginning
    const { data, links, _id } = projectGanttStore.projectGanttData[0]
    setCurrentProjectGanttId(_id ?? '')

    if (data?.length) {
      data.forEach(element => {
        if (element?.start_date) {
          element.start_date = new Date(element.start_date) // parse date
        }
        if (element?.end_date) {
          element.end_date = new Date(element?.end_date) // parse date
        }
        if (element?.planned_start) {
          element.planned_start = new Date(element.planned_start) // parse date
        }
        if (element?.planned_end) {
          element.planned_end = new Date(element.planned_end) // parse date
        }
        // Tasks Coloring
        element.color = projectGanttStore.showColorTask? (element.colorTaskEnable === true ? (element.highlightColor || '#20b56d') : element.type === 'project' ? '#20b56d': '#537cfa') : element.type === 'project' ? '#20b56d': '#537cfa'
      })
    }

    setGanttData({ data, links })
  }

  useEffect(() => {
    handlePreprocessProjectGanttData()
  }, [projectGanttStore.projectGanttData])

  const onCancel = () => {
    projectGanttStore.setIsShowGanttPanel(false)
  }

  return (
    <Drawer
      className={
        projectGanttStore.isShowGanttPanel
          ? 'project-setting-drawer custom-wraper-splitPanel'
          : 'project-setting-drawer'
      }
      bodyStyle={{ padding: '0', overflow: 'hidden' }}
      placement="left"
      width={drawerWidth}
      closable={false}
      visible={projectGanttStore.isShowGanttPanel}
      maskClosable={false}
      mask={false}
      destroyOnClose={true}
      forceRender>
      <GanttPageWrapperLeft baseline={projectGanttStore.isShowBaseline}>
        {isTabletOrMobileDevice ? (
          <div className="splitpanel-mobile-left">
            <Button
              onTouchStart={e => handleTouchStart(e)}
              type="dashed"
              shape="circle"
              className="btnSplitPanel">
              <ColumnWidthOutlined />
            </Button>
          </div>
        ) : (
          <div
            onMouseDown={e => handleMouseDown(e)}
            className="splitpanel-left"
          />
        )}
        <GanttWrapper>
          <Spin spinning={projectGanttStore.isLoading}>
            {ganttData ? (
              <>
                <Toolbar
                  zoom={currentZoom}
                  zoomToFit={zoomToFit}
                  onZoomChange={handleZoomChange}
                  onToggleZoomToFit={handleZoomtoFit}
                />
                <Gantt
                  viewer={viewer}
                  tasks={ganttData}
                  zoom={currentZoom}
                  zoomToFit={zoomToFit}
                  onDataUpdated={logDataUpdate}
                  onZoomChange={handleZoomChange}
                  onToggleZoomToFit={handleZoomtoFit}
                />
              </>
            ) : (
              <>
                <CloseOutlined
                  className="close-gantt-icon"
                  style={{ fontSize: 16 }}
                  onClick={() => onCancel()}
                />
                <Empty />
              </>
            )}
          </Spin>
        </GanttWrapper>
      </GanttPageWrapperLeft>
      <ModalLinkGanttToDataTree />
      <ModalLinkGanttToSavedQuery viewer={viewer} />
    </Drawer>
  )
}

export default inject(
  'commonStore',
  'projectGanttStore',
  'projectStore'
)(observer(DrawerGanttPanel))
