import { observable, action, decorate } from 'mobx'
import { SketchRequest, SketchElementRequest } from '../requests'
import TreeUtils from '../tree-utils';

class SketchingStore {
  isLoading = false
  currentSketchId = false // id of current selected sketch
  sketchToolVisible = false
  drawMode = false
  sketchMode = ''
  isRemoveShape = false
  isEndDrawing = false
  currentEditSketch = false // data of current selected sketch
  resetSketch = false
  visibleSketches = []
  sketchFormVisible = false
  sketch = {
    color: { color: '#ffffff', alpha: 0.5 },
    height: 0,
    extrudedHeight: 0,
    name: '',
    points: [],
    width: 0,
    lineHorizontalPositions: [],
    rotation: 0,
    readonlyHeight: false,
    font: '',
    sizeLabel: 0,
    pointDragger: []
  }
  arrSketches = []
  sketchViewHeight = 0;
  zoomToSketch = false
  currentSketchElement = {}
  sketchLibraryElements = []
  drawMoreSketchMode = false
  drawMore = false
  multipleMode = false
  geometrySketch = false
  resetGeometrySketch = false
  currentEditEntity = false
  selectedNode = null
  settings = false
  filterMode = false
  treeDataFilter = false
  checkedKeysModel = []
  objectCustomAttributes = {
    fileLevel: [],
    objectLevel: []
  }
  currentObjectAttribute = false
  visibleModalObjectAtt = false
  isEditingModelAttribute = false
  sketchViews = []
  sketchMeshModelViews = []
  isStopDragging = false;
  keyTreeNodeSaveSketch = null;
  listGanttNodeSaveSketch = [];
  listOriginalGanttNodeSaveSketch = [];

  setListOriginalGanttNodeSaveSketch = list => {
    this.listOriginalGanttNodeSaveSketch = list
  }

  setKeyTreeNodeSaveSketch = key => {
    this.keyTreeNodeSaveSketch = key;
  }

  setListGanttNodeSaveSketch = list => {
    this.listGanttNodeSaveSketch = list;
  }

  setStopDragging = payload => {
    this.isStopDragging = payload
  }

  setSketchMeshModelViews = (newModel, clear) => {
    if (clear) {
      this.sketchMeshModelViews = newModel || []
    } else {
      let isExist = this.sketchMeshModelViews.find(c => c.key === newModel?.key)
      if (!isExist) {
        this.sketchMeshModelViews.push(newModel)
      } else {
        let index = this.sketchMeshModelViews.findIndex(c => c.key === newModel?.key)
        this.sketchMeshModelViews[index] = newModel;
      }
    }
  }

  setSketchView = (payload) => {
    this.sketchViews = payload;
  }

  setIsEditingModelAttribute = stt => {
    this.isEditingModelAttribute = stt
  }

  setVisibleModalObjectAtt = data => {
    this.visibleModalObjectAtt = data
  }

  setCurrentObjectAttribute = data => {
    this.currentObjectAttribute = data
  }

  setObjectCustomAttributes = (data) => {
    this.objectCustomAttributes = data
  }

  setTreeDataFilter = (c) => {
    this.treeDataFilter = c
  }

  setFilterMode = (stt) => {
    this.filterMode = stt
  }

  setSelectedNode = (node) => {
    this.selectedNode = node
  }

  setSettings = (stt) => {
    this.settings = stt
  }

  setCurrentEditEntity = data => {
    this.currentEditEntity = data
  }

  setResetGeometrySketch = data => {
    this.resetGeometrySketch = data
  }

  setGeometrySketch = data => {
    this.geometrySketch = data
  }

  showProjectSketchDrawer = false
  widthSketchOrganzation = 800
  loadingSketchOrgranization = false
  setLoadingSketchOrgranization = state => {
    this.loadingSketchOrgranization = state
  }
  setMultipleMode = state => {
    this.multipleMode = state
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  setDrawMore = state => {
    this.drawMore = state
  }

  setDrawMoreSketchMode = state => {
    this.drawMoreSketchMode = state
  }

  setZoomToSketch = sketchId => {
    this.zoomToSketch = sketchId
  }

  setVisibleSketches = p => {
    this.visibleSketches = p
  }

  setSketchViewHeight = p => {
    this.sketchViewHeight = p
  }
  setShowProjectSketchsDrawer = state => {
    this.showProjectSketchDrawer = state
  }
  setSketchFormVisible = visible => {
    this.sketchFormVisible = visible
  }

  setSketchProps = props => {
    if (props.hasOwnProperty('color')) {
      this.sketch.color = props.color
    }
    if (props.hasOwnProperty('height')) {
      this.sketch.height = props.height
    }
    if (props.hasOwnProperty('font')) {
      this.sketch.font = props.font
    }
    if (props.hasOwnProperty('extrudedHeight')) {
      this.sketch.extrudedHeight = props.extrudedHeight
    }
    if (props.hasOwnProperty('name')) {
      this.sketch.name = props.name
    }
    if (props.hasOwnProperty('description')) {
      this.sketch.description = props.description
    }
    if (props.hasOwnProperty('points')) {
      this.sketch.points = props.points
    }
    if (props.hasOwnProperty('width')) {
      this.sketch.width = props.width
    }
    if (props.hasOwnProperty('lineHorizontalPositions')) {
      this.sketch.lineHorizontalPositions = props.lineHorizontalPositions
    }
    if (props.hasOwnProperty('rotation')) {
      this.sketch.rotation = props.rotation
    }
    if (props.hasOwnProperty('readonlyHeight')) {
      this.sketch.readonlyHeight = props.readonlyHeight
    }
    if (props.hasOwnProperty('sizeLabel')) {
      this.sketch.sizeLabel = props.sizeLabel
    }
    if (props.hasOwnProperty('pointDragger')) {
      this.sketch.pointDragger = props.pointDragger
    }
  }
  setCurrentSketchId(id) {
    this.currentSketchId = id
  }

  setSketches = s => {
    this.arrSketches = s
  }

  deleteSketch(sketchId) {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      SketchRequest.deleteSketch(sketchId)
        .then((response) => {
          const index = this.arrSketches.findIndex(x => x.id === sketchId)
          const temp = [...this.arrSketches]
          temp.splice(index, 1)
          this.setSketches(temp)
          return response
        })
        .then((res) => {
          this.setLoadingProgress(false)
          resolve(res)
        })
        .catch(error => {
          reject(error)
          console.log(error)
          this.setLoadingProgress(false)
        })
    })
  }

  /**
   * Get list of sketch by projectId
   * @param {*} projectId 
   */
  async getSketchesByProjectId(projectId) {
    return new Promise((resolve, reject) => {
      SketchRequest.getSketchByProjectId(projectId).then((response) => {
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  setSketchMode = modeId => {
    this.sketchMode = modeId
  }

  //set Show button sketch Line, Area bottom bar
  setSketchToolVisible = (state) => {
    this.sketchToolVisible = state
  }

  setDrawMode = isDrawMode => {
    this.drawMode = isDrawMode
  }

  setRemovingShape = isRemove => {
    this.isRemoveShape = isRemove
  }

  setEndDrawing = isEndDrawing => {
    this.isEndDrawing = isEndDrawing
  }

  setCurrentEditSketch = currentEditSketch => {
    this.currentEditSketch = currentEditSketch
  }

  setResetSketch = sketchid => {
    this.resetSketch = sketchid
  }

  /**
   * Method Post Delete multi Sketch
   * @param {*} body: {projectId: required, deleteData: { sketchId: required, treeNodeKey: required }},
   * @returns
   */
  async deleteSketchObject(body) {
    return new Promise((resolve, reject) => {
      SketchRequest.deleteSketchObject(body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  async mergeSketchLibraryElement(projectId) {
    return new Promise((resolve, reject) => {
      SketchElementRequest.mergeSketchLibraryElement(projectId).then((response) => {
        this.sketchLibraryElements = response.data
        resolve(response);
      }).catch(err => {
        // console.log(err);
        reject(err)
      })
    })
  }

  async getSketchElements(type, prj, org) {
    return new Promise((resolve, reject) => {
      SketchElementRequest.get(type, prj, org).then((response) => {
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  async createSketchElements(body) {
    return new Promise((resolve, reject) => {
      SketchElementRequest.create(body).then((response) => {
        this.currentSketchElement = response.data
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  async updateSketchElement(id, body) {
    return new Promise((resolve, reject) => {
      SketchElementRequest.update(id, body).then((response) => {
        this.currentSketchElement = response.data
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  setSketchLibraryElements = (data) => {
    this.sketchLibraryElements = data
  }

  setCurrentSketchElement = (data) => {
    this.currentSketchElement = data
  }
  setWidthSketchOrganzation = data => {
    this.widthSketchOrganzation = data
  }
  setExistExpandedKeys = (treeData, expanded) => {
    let keyExpands = []
    if (treeData) {
      expanded.map(item => {
        const node = TreeUtils.searchTreeNode(treeData, 'key', item);
        if (node && !keyExpands.includes(node.key)) {
          keyExpands.push(node.key)
        }
      })
    }
    return keyExpands
  }
  setCheckedKeysModel = (data) => {
    this.checkedKeysModel = data
  }
}

decorate(SketchingStore, {
  sketchToolVisible: observable,
  sketchMode: observable,
  drawMode: observable,
  setSketchToolVisible: action,
  setDrawMode: action,
  setSketchMode: action,

  isRemoveShape: observable,
  setRemovingShape: action,

  isEndDrawing: observable,
  setEndDrawing: action,

  currentEditSketch: observable,
  setCurrentEditSketch: action,

  setResetSketch: action,
  resetSketch: observable,

  deleteSketch: action,
  currentSketchId: observable,
  setCurrentSketchId: action,

  sketchViewHeight: observable,
  setSketchViewHeight: action,

  sketchFormVisible: observable,
  setSketchFormVisible: action,
  sketch: observable,
  setSketchProps: action,

  arrSketches: observable,
  setSketches: action,

  visibleSketches: observable,
  setVisibleSketches: action,

  zoomToSketch: observable,
  setZoomToSketch: action,
  getSketchesByProjectId: action,
  getSketchElements: action,
  updateSketchElement: action,
  currentSketchElement: observable,
  sketchLibraryElements: observable,
  setCurrentSketchElement: action,
  setSketchLibraryElements: action,
  createSketchElements: action,

  deleteSketchObject: action,
  setDrawMoreSketchMode: action,
  drawMoreSketchMode: observable,
  setDrawMore: action,
  drawMore: observable,
  setMultipleMode: action,
  multipleMode: observable,
  setGeometrySketch: action,
  geometrySketch: observable,
  setResetGeometrySketch: action,
  resetGeometrySketch: observable,
  setCurrentEditEntity: action,
  currentEditEntity: observable,

  showProjectSketchDrawer: observable,
  setShowProjectSketchsDrawer: action,
  widthSketchOrganzation: observable,
  setWidthSketchOrganzation: action,
  loadingSketchOrgranization: observable,
  setLoadingSketchOrgranization: action,
  setSelectedNode: action,
  selectedNode: observable,
  setSettings: action,
  settings: observable,
  mergeSketchLibraryElement: action,
  setFilterMode: action,
  filterMode: observable,
  treeDataFilter: observable,
  setTreeDataFilter: action,
  setExistExpandedKeys: action,
  setCheckedKeysModel: action,
  checkedKeysModel: observable,

  setVisibleModalObjectAtt: action,
  visibleModalObjectAtt: observable,
  setCurrentObjectAttribute: action,
  currentObjectAttribute: observable,
  setObjectCustomAttributes: action,
  objectCustomAttributes: observable,
  setIsEditingModelAttribute: action,
  isEditingModelAttribute: observable,
  sketchViews: observable,
  setSketchView: action,
  setSketchMeshModelViews: action,
  sketchMeshModelViews: observable,
  isStopDragging: observable,
  setStopDragging: action,
  keyTreeNodeSaveSketch: observable,
  setKeyTreeNodeSaveSketch: action,
  listGanttNodeSaveSketch: observable,
  setListGanttNodeSaveSketch: action,
  listOriginalGanttNodeSaveSketch: observable,
  setListOriginalGanttNodeSaveSketch: action,
})

export default new SketchingStore()
