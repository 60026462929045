import React, { useEffect, useRef } from 'react'
import { Button, DatePicker, Popover, Select, Input, Space, Tooltip, Popconfirm  } from 'antd'
import {
  ButtonCustom,
  MoreOptionWrapper,
  OptionWrapper,
  ToolbarWrapper,
  ButtonCustom1
} from './styled'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import en from 'antd/lib/date-picker/locale/en_US'
import fi from 'antd/lib/date-picker/locale/fi_FI'
import sv from 'antd/lib/date-picker/locale/sv_SE'
import es from 'antd/lib/date-picker/locale/es_ES'
import vi from 'antd/lib/date-picker/locale/vi_VN'

import { ReactComponent as FileImportIcon } from '../../../../../assets/svgs/file-import.svg'
import { ReactComponent as FileExportIcon } from '../../../../../assets/svgs/file-export.svg'
import { ReactComponent as BaselineUpdateIcon } from '../../../../../assets/svgs/baseline-update.svg'
import { ReactComponent as RedoIcon } from '../../../../../assets/svgs/redo_icon.svg'
import { ReactComponent as UndoIcon } from '../../../../../assets/svgs/undo_icon.svg'
import { ReactComponent as GridIcon } from '../../../../../assets/svgs/grid.svg'
import { ReactComponent as GanttIcon } from '../../../../../assets/svgs/gantt_panel.svg'
import { ReactComponent as CriticalPathIcon } from '../../../../../assets/svgs/critical-path.svg'
import { ReactComponent as BaselineIcon } from '../../../../../assets/svgs/baseline.svg'
import { ReactComponent as ResourcesIcon } from '../../../../../assets/svgs/resources.svg'
import { ReactComponent as AutoScheduleIcon } from '../../../../../assets/svgs/autoschedule.svg'
import { ReactComponent as ColorIcon } from '../../../../../assets/svgs/color_icon.svg'
import { ReactComponent as FitIcon } from '../../../../../assets/svgs/auto_fit.svg'
import { ReactComponent as CondensedIcon } from '../../../../../assets/svgs/condensed.svg'
import { ReactComponent as DragIcon } from '../../../../../assets/svgs/drag.svg'
import { ReactComponent as SelectModelIcon } from '../../../../../assets/svgs/select-model.svg'
import { ReactComponent as SelectObjectIcon } from '../../../../../assets/svgs/select-object.svg'
import { ReactComponent as GridSelectionIcon } from '../../../../../assets/svgs/grid-select-column.svg'
import { ReactComponent as Icon4DPlay } from '../../../../../assets/svgs/playback-play.svg'
import { ReactComponent as Icon4DPause } from '../../../../../assets/svgs/playback-pause.svg'
import { ReactComponent as Icon4DBack } from '../../../../../assets/svgs/playback-backward.svg'
import { ReactComponent as Icon4DForward } from '../../../../../assets/svgs/playback-forward.svg'
import { ReactComponent as SketchSingle } from '../../../../../assets/svgs/Sketch_Single.svg'
import { ReactComponent as SketchLine } from '../../../../../assets/svgs/Sketch_Line.svg'
import { ReactComponent as SketchArea } from '../../../../../assets/svgs/Sketch_Area.svg'
import { ReactComponent as SketchVolume } from '../../../../../assets/svgs/Sketch_Volume.svg'
import { ReactComponent as CloseIcon } from '../../../../../assets/svgs/Close.svg'

import SVGIcon from '../../../../elements/SVGIcon'
import { handleAddDateTime } from '../Gantt/ganttUtils'
import GridColumnMenu from './GridColumnMenu'
import ModalIEGantt from './ModalIEGantt'
import HelpButton from '../../../../elements/HelpButton';

const Toolbar = ({
  zoom,
  onZoomChange,
  zoomToFit,
  onToggleZoomToFit,
  projectGanttStore,
  commonStore,
  projectStore,
  adminStore,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const { t } = useTranslation()
  const {
    playerMode,
    setPlayerMode,
    isPlay4d,
    setIsPlay4d,
    setBackOrForwardCount,
    isShowGrid,
    setIsShowGrid,
    showChart,
    setToggleChart,
    isShowBaseline,
    setIsShowBaseline,
    setIsOpenModalExportnImportProject,
    playerSpeed,
    setPlayerSpeed,
    setSketchDrawer,
    setGroupType,
    groupType,
    setFilterTask,
    filterTask,
    setShowColorTask,
    showColorTask,
    setIsCondensed,
    isCondensed
  } = projectGanttStore

  const checkingFeatureRole = type => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  const handleZoomChange = value => {
    if (onZoomChange) {
      onZoomChange({ status: value, isUpdate: true })
    }
  }

  const handleZoomToFit = () => {
    onToggleZoomToFit({ status: !zoomToFit.status, isUpdate: true })
  }

  const handleChangeCriticalPath = () => {
    projectGanttStore.setIsShowCriticalPath(!projectGanttStore.isShowCriticalPath)
  }
  const handleChangeAutoScheduling = () => {
    projectGanttStore.setIsAutoScheduling(!projectGanttStore.isAutoScheduling)
  }

  const handleUndo = () => {
    projectGanttStore.setUndoCount()
  }
  const handleRedo = () => {
    projectGanttStore.setRedoCount()
  }

  const handleSetTime = value => {
    projectGanttStore.setCurrentViewingTime(value);
  }

  const content = (
    <OptionWrapper>
      <GridColumnMenu />
    </OptionWrapper>
  )

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(
    () => {
      jumpForward()
    },
    isPlay4d ? playerSpeed * 1000 : null
  )

  useEffect(() => {
    if (playerMode === 'dragDate') {
      setBackOrForwardCount(0)
    }
  }, [playerMode])

  const playVideo = () => {
    setIsPlay4d(true)
  }

  const pauseVideo = () => {
    setIsPlay4d(false)
  }

  const jumpForward = () => {
    const updateDate = handleAddDateTime(
      projectGanttStore.currentViewingTime.toDate(),
      1,
      zoom.status
    )
    handleSetTime(updateDate)
    if (!isPlay4d && playerMode === 'dragGantt') {
      setBackOrForwardCount();
    }
  }

  const jumpBackward = () => {
    const updateDate = handleAddDateTime(
      projectGanttStore.currentViewingTime.toDate(),
      -1,
      zoom.status
    )
    handleSetTime(updateDate)
    if (!isPlay4d && playerMode === 'dragGantt') {
      setBackOrForwardCount();
    }
  }

  const handleToggleGrid = () => {
    setIsShowGrid(!isShowGrid)
  }

  const handleToggleChart = () => {
    setToggleChart(!showChart)
  }

  const handleShowColorTask = () => {
    setShowColorTask(!showColorTask)
  }

  const handleGenericBaseline = () => {
    if (!projectGanttStore.projectGanttData?.length) {
      return
    }
    const { data, _id } = projectGanttStore.projectGanttData[0]
    if (!data?.length) return
    projectGanttStore.baselineGenerator({ data }).then(() => {
      projectGanttStore.getProject4dGantt(projectStore.projectDetail.id)
    })
  }

  const handleToggleShowBaseline = () => {
    setIsShowBaseline(!isShowBaseline)
  }

  const handleOpenExportnImportProject = type => {
    setIsOpenModalExportnImportProject({ type, open: true })
  }

  // handle click button draw sketch
  const handleClickDrawSketch = type => {
    setSketchDrawer({ task: '', isNewTask: false, sketchId: '', open: false, drawSketchType: type })
  }

  const handleFilterTask = value => {
    setFilterTask(value)
  }

  const handleChangeDragMode = () => {
    if(playerMode === 'dragDate') {
      setPlayerMode('dragGantt')
    } else {
      setPlayerMode('dragDate')
    }
  }

  const onCancel = () => {
    projectGanttStore.setIsShowGanttPanel(false)
  }

  const handleToggleCondensed = () => {
    setIsCondensed(!isCondensed)
  }

  return (
    <ToolbarWrapper>
      <MoreOptionWrapper>
      <Tooltip
          title={t('commons.help')}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined}
        >
          <Button
            style={{ top: '-5px', boxShadow: 'none' }}
            icon={
              <SVGIcon
                width={32} height={32}
                content={
                  <HelpButton
                    fontsize={20}
                    helppage={"4dgantt"}
                    styles={`
                          padding: 5px;
                          border-radius: 3px;`}
                  />
                }
              />
            }
          />
        </Tooltip>
        <ButtonCustom1
          style={{width: '20px', height: '20px'}}
          icon={<SVGIcon content={<CloseIcon />} />}
          onClick={() => onCancel()}
        />
      </MoreOptionWrapper>
      <div className="toolbar-component pb-7">
        <Space>
          {checkingFeatureRole('feature_4d_gantt_edit') && (
            <>
              <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('commons.import')}>
                <ButtonCustom
                  icon={<SVGIcon content={<FileImportIcon />} />}
                  onClick={() => handleOpenExportnImportProject('import')}
                />
              </Tooltip>
              <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('commons.export')}>
                <ButtonCustom
                  icon={<SVGIcon content={<FileExportIcon />} />}
                  onClick={() => handleOpenExportnImportProject('export')}
                />
              </Tooltip>
              <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.save-as-baseline')}>
                <Popconfirm
                  title={t('gantt.are-you-sure-you-want-to-overwrite-existing-baseline')}
                  onConfirm={handleGenericBaseline}
                  okText={t('commons.ok')}
                  cancelText={t('commons.cancel')}
                  placement="leftBottom"
                >
                  <ButtonCustom
                    icon={<SVGIcon content={<BaselineUpdateIcon />} />}
                  />
                </Popconfirm>
              </Tooltip>
            </>
          )}
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.undo')}>
            <ButtonCustom
              icon={<SVGIcon content={<UndoIcon />} className={projectGanttStore.isActiveUndo ? "": "onoffstyle"}/>}
              onClick={() => handleUndo()}
              disabled={!projectGanttStore.isActiveUndo}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.redo')}>
            <ButtonCustom
              icon={<SVGIcon content={<RedoIcon />} className={projectGanttStore.isActiveRedo ? "": "onoffstyle"}/>}
              onClick={() => handleRedo()}
              disabled={!projectGanttStore.isActiveRedo}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.grid')} ${isShowGrid ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<GridIcon />} className={isShowGrid ? "": "onoffstyle"}/>}
              onClick={handleToggleGrid}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.gantt-panel')} ${showChart ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<GanttIcon />} />}
              onClick={handleToggleChart}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.critical-path')} ${projectGanttStore.isShowCriticalPath ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<CriticalPathIcon />} className={projectGanttStore.isShowCriticalPath ? "": "onoffstyle"}/>}
              onClick={handleChangeCriticalPath}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.baseline')} ${isShowBaseline ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<BaselineIcon />} className={isShowBaseline ? "": "onoffstyle"}/>}
              onClick={handleToggleShowBaseline}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.resource-panel')}>
            <ButtonCustom
              icon={<SVGIcon content={<ResourcesIcon />} />}
            // onClick={handleToggleShowBaseline}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.auto-schedule')} ${projectGanttStore.isAutoScheduling ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<AutoScheduleIcon />} className={projectGanttStore.isAutoScheduling ? "": "onoffstyle"}/>}
              onClick={handleChangeAutoScheduling}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.coloring-task')} ${projectGanttStore.showColorTask ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<ColorIcon />} className={projectGanttStore.showColorTask ? "": "onoffstyle"}/>}
              onClick={handleShowColorTask}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.fit')} ${zoomToFit.status ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<FitIcon />} className={zoomToFit.status ? "": "onoffstyle"}/>}
              onClick={handleZoomToFit}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${t('gantt.condensed')} ${projectGanttStore.isCondensed ? t('commons.off'): t('commons.on')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<CondensedIcon />} className={projectGanttStore.isCondensed ? "": "onoffstyle"}/>}
              onClick={handleToggleCondensed}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={`${playerMode === "dragDate" ? t('drag-gantt'): t('drag-date')}`}>
            <ButtonCustom
              icon={<SVGIcon content={<DragIcon />} className={playerMode === "dragDate" ? "": "onoffstyle"}/>}
              onClick={handleChangeDragMode}
            />
          </Tooltip>
          <Select
            id={zoom ? `lable-zoom-gantt${zoom}` : zoom}
            className={`gantt-control radio-label ${zoom ? 'radio-label-active' : ''
              } lable-zoom-gantt`}
            style={{ width: 120 }}
            onChange={handleZoomChange}
            value={zoom.status}
            options={[
              { value: 'Hours', label: t('hours') },
              { value: 'Days', label: t('days') },
              { value: 'Weeks', label: t('weeks') },
              { value: 'Months', label: t('months') },
              { value: 'Quarters', label: t('quarters') },
              { value: 'Years', label: t('years') },
            ]}
          />
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.select-model')}>
            <ButtonCustom
              icon={<SVGIcon content={<SelectModelIcon />} />}
            // onClick={handleChangeAutoScheduling}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.select-object')}>
            <ButtonCustom
              icon={<SVGIcon content={<SelectObjectIcon />} />}
            // onClick={handleChangeAutoScheduling}
            />
          </Tooltip>
        </Space>
      </div>
      <div className="toolbar-component">
        <Space>
          <Input onChange={e => handleFilterTask(e.target.value)} value={filterTask} placeholder="Filter..." className="gantt_input_styled" style={{width: 70}} />
          <Select
            className={'gantt-control'}
            style={{ width: 100 }}
            onChange={value => setGroupType(value)}
            value={groupType}
            options={[
              { value: 'task', label: 'Task' },
              { value: 'priority', label: 'Priority' },
              { value: 'taskType', label: 'Type' },
            ]}
          />
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.colums-selection')}>
            <Popover content={content} title={t('gantt.show-columns')} trigger="click">
              <ButtonCustom
                icon={<SVGIcon content={<GridSelectionIcon />} />}
              />
            </Popover>
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.backward')}>
            <ButtonCustom
              icon={<SVGIcon content={<Icon4DBack />} />}
              onClick={jumpBackward}
            />
          </Tooltip>
          <DatePicker
            locale={{
              ...(commonStore.language === 'fi'
                ? fi
                : commonStore.language === 'sv'
                  ? sv
                  : commonStore.language === 'es'
                    ? es
                    : commonStore.language === 'vi'
                      ? vi
                      : en),
              lang: {
                ...(commonStore.language === 'fi'
                  ? fi
                  : commonStore.language === 'sv'
                    ? sv
                    : commonStore.language === 'es'
                      ? es
                      : commonStore.language === 'vi'
                        ? vi
                        : en
                ).lang,
                now: t('now'),
                ok: t('commons.ok'),
              },
            }}
            value={projectGanttStore.currentViewingTime}
            showTime
            placeholder={t('select-time')}
            onChange={handleSetTime}
            onOk={handleSetTime}
            style={{ width: 215 }}
            allowClear={false}
            disabled={projectGanttStore.playerMode === 'dragGantt'}
          />
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.forward')}>
            <ButtonCustom
              icon={<SVGIcon content={<Icon4DForward />} />}
              onClick={jumpForward}
            />
          </Tooltip>
          <Select
            className={'gantt-control'}
            style={{ width: 70 }}
            onChange={value => setPlayerSpeed(value)}
            value={playerSpeed}
            options={[
              { value: 4, label: '4 ' },
              { value: 2, label: '2' },
              { value: 1, label: '1' },
              { value: 1 / 2, label: '1/2' },
              { value: 1 / 4, label: '1/4' },
            ]}
          />
          {isPlay4d ? (
            <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.pause')}>
              <ButtonCustom
                icon={<SVGIcon content={<Icon4DPause />} />}
                onClick={pauseVideo}
              />
            </Tooltip>
          ) : (
            <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('gantt.play')}>
              <ButtonCustom
                icon={<SVGIcon content={<Icon4DPlay />} />}
                onClick={playVideo}
              />
            </Tooltip>
          )}
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('single')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchSingle />} />}
              onClick={() => handleClickDrawSketch('single')}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('line')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchLine />} />}
              onClick={() => handleClickDrawSketch('line')}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('area')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchArea />} />}
              onClick={() => handleClickDrawSketch('area')}
            />
          </Tooltip>
          <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('volume')}>
            <ButtonCustom
              icon={<SVGIcon content={<SketchVolume />} />}
              onClick={() => handleClickDrawSketch('volume')}
            />
          </Tooltip>
        </Space>
      </div>
      <ModalIEGantt />
    </ToolbarWrapper>
  )
}

export default inject(
  'adminStore',
  'commonStore',
  'projectGanttStore',
  'projectStore'
)(observer(Toolbar))
