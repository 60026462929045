import { inject, observer } from 'mobx-react';
import React from 'react';
import {message } from 'antd';
import { HelpButtonContainer } from './style';
import { ReactComponent as HelpIcon } from '../../../assets/svgs/question-icon.svg'
import SVGIcon from '../SVGIcon'
import { useTranslation } from 'react-i18next';

const HelpButton = ({ projectStore, fontsize = 16, styles, helppage, hovered, icon }) => {
    const {t} = useTranslation()
    const mapHelperToolToHtmlLink = () =>{
        if (!helppage || !projectStore.featuresHelpList) return

        if (projectStore.featuresHelpList[helppage]) {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: projectStore.featuresHelpList[helppage],
            }).click();
        }else{
            message.warning(t('link-not-available'))
        }
    }

    return (
        <HelpButtonContainer hovered={hovered} styles={styles} onClick={() => mapHelperToolToHtmlLink()}>
            {icon || <SVGIcon content={<HelpIcon className='question-icon'/>} width={fontsize} height={fontsize} />}
        </HelpButtonContainer>
    );
};

export default inject('projectStore')(observer(HelpButton))