import React, { useCallback, useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import './skins/dhtmlxgantt_material.css'
import './Gantt.css'
import {
  ACTUAL_DATE_ZOOMINGS,
  COLUMNS,
  DATE_ZOOMINGS,
  DateShow,
  applyColorChanges,
  handleAddDateTime,
  onComponentDidMount,
  preProcessingData,
  removeDuplicateText,
  simplifiedGanttDataToGetSavedQuery,
} from './ganttUtils'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { message, Modal } from 'antd'
import { uniqBy } from 'lodash'
import { applySavedQueryHighlight, onClearAlignmentCachingObject } from '../ModalLinkGanttToSavedQuery/utils'
import { useTranslation } from 'react-i18next'
import { vi } from './locales/vi'
import Utils from '../../../../../utils';
import {
  GanttContainer
} from '../styled'

const gantt = window.gantt
const dhx = window.dhx

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame

const cancelAnimationFrame =
  window.cancelAnimationFrame || window.mozCancelAnimationFrame

let cachedSettings = {}

let dateShow = new DateShow()

var isPaused = false

let previousUndoStack = []
let previousRedoStack = []

let lastMiddlePosition = 0

const Gantt = ({
  tasks,
  zoom,
  zoomToFit,
  onDataUpdated,
  projectGanttStore,
  onToggleZoomToFit,
  onZoomChange,
  viewer,
  projectStore,
  sketchingStore,
  adminStore,
  objectQueryStore,
  commonStore,
}) => {
  const { t } = useTranslation();
  var intervalID = 0
  var intervalID2
  let ganttContainer = useRef(null)
  let dataProcessor = useRef(null)
  let isResizeRow = useRef(false)
  const [ganttReady, setGanttReady] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  let isNewTask = false;
  let isDeletedOCreatedNew = false;

  const checkingFeatureRole = type => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  const quarterConfig = () => {
    gantt.date.year_quarter_start = function (date) {
      return date
    }

    gantt.date.add_year_quarter = function (date, inc) {
      var temp_date = new Date(date)
      gantt.date.year_start(temp_date)
      gantt.date.month_start(temp_date)
      gantt.date.day_start(temp_date)

      var q1 = new Date(temp_date)
      var q2 = gantt.date.add(q1, 3, 'month')
      var q3 = gantt.date.add(q2, 3, 'month')
      var q4 = gantt.date.add(q3, 3, 'month')
      var next_year = gantt.date.add(q4, 3, 'month')

      if (+q1 <= +date && +date < +q2) return q2
      if (+q2 <= +date && +date < +q3) return q3
      if (+q3 <= +date && +date < +q4) return q4
      if (+q4 <= +date && +date < +next_year) return next_year
    }
  }

  const initZoom = () => {
    quarterConfig()
    const quarter_template = function (date) {
      return 'Q' + (Math.floor(date.getMonth() / 3) + 1)
    }
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 200,
          scales: [
            {
              unit: 'week',
              step: 1,
              format: function (date) {
                var dateToStr = gantt.date.date_to_str('%d %M')
                var endDate = gantt.date.add(date, 6, 'day')
                var weekNum = gantt.date.date_to_str('%W')(date)
                return (
                  '#' +
                  weekNum +
                  ', ' +
                  dateToStr(date) +
                  ' - ' +
                  dateToStr(endDate)
                )
              },
            },
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H' },
          ],
        },
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 150,
          scales: [
            { unit: 'month', step: 1, format: '%M' },
            { unit: 'week', step: 1, format: 'Week #%W' },
            { unit: 'day', step: 1, format: '%d %M' },
          ],
        },
        {
          name: 'Weeks',
          scale_height: 50,
          min_column_width: 80,
          scales: [
            { unit: 'year_quarter', step: 1, template: quarter_template },
            { unit: 'month', step: 1, format: '%M' },
            {
              unit: 'week',
              step: 1,
              format: function (date) {
                const weekNum = gantt.date.date_to_str('%W')(date)
                return '#' + weekNum
              },
            },
          ],
        },
        {
          name: 'Months',
          scale_height: 60,
          min_column_width: 80,
          scales: [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'year_quarter', step: 1, template: quarter_template },
            { unit: 'month', step: 1, format: '%M' },
          ],
        },
        {
          name: 'Quarters',
          height: 50,
          min_column_width: 70,
          scales: [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'year_quarter', step: 1, template: quarter_template },
          ],
        },
        {
          name: 'Years',
          scale_height: 50,
          min_column_width: 60,
          scales: [{ unit: 'year', step: 1, format: '%Y' }],
        },
      ],
      useKey: 'shiftKey',
      trigger: 'wheel',
      element: function () {
        return gantt.$root.querySelector('.gantt_task')
      },
    })

    gantt.ext.zoom.attachEvent(
      'onAfterZoom',
      function (level, config) {
        onZoomChange({ status: config.name, isUpdate: false })
        updateMarkerTime(false)
      },
      { id: 'afterZoom' }
    )
  }

  function toggleMode(toggle) {
    if (toggle) {
      //Saving previous scale state for future restore
      saveConfig()
      setZoomToFit()
    } else {
      //Restore previous scale state
      restoreConfig()
      gantt.render()
    }
  }

  function saveConfig() {
    var config = gantt.config
    const scaleConfigs = gantt.ext.zoom.getLevels()
    const currentScale = gantt.ext.zoom.getCurrentLevel()

    cachedSettings = {}
    cachedSettings.scales = config.scales
    cachedSettings.start_date = config.start_date
    cachedSettings.end_date = config.end_date
    cachedSettings.scroll_position = gantt.getScrollState()
    cachedSettings.name = scaleConfigs[currentScale].name
  }

  function restoreConfig() {
    applyConfig(cachedSettings)
  }

  function applyConfig(config, dates) {
    if (config.scales) gantt.config.scales = config.scales
    if(config.name) onZoomChange({ status: config.name, isUpdate: false })

    // restore the previous scroll position
    if (config.scroll_position) {
      setTimeout(function () {
        gantt.scrollTo(config.scroll_position.x, config.scroll_position.y)
      }, 4)
    }
  }

  function setZoomToFit() {
    var project = gantt.getSubtaskDates(),
      areaWidth = gantt.$task.offsetWidth,
      scaleConfigs = gantt.ext.zoom.getLevels()

    for (var i = 0; i < scaleConfigs.length; i++) {
      var columnCount = getUnitsBetween(
        project.start_date,
        project.end_date,
        scaleConfigs[i].scales[scaleConfigs[i].scales.length - 1].unit,
        scaleConfigs[i].scales[0].step
      )
      if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
        break
      }
    }

    if (i == scaleConfigs.length) {
      i--
    }

    gantt.ext.zoom.setLevel(scaleConfigs[i].name)
    applyConfig(scaleConfigs[i], project)
  }

  // get number of columns in timeline
  function getUnitsBetween(from, to, unit, step) {
    var start = new Date(from),
      end = new Date(to)
    var units = 0
    while (start.valueOf() < end.valueOf()) {
      units++
      start = gantt.date.add(start, step, unit)
    }
    return units
  }

  useEffect(() => {
    if (projectGanttStore.isShowGrid) {
      gantt.config.layout = gridAndChart;
    } else {
      gantt.config.layout = onlyChart;
    }
    gantt.init("gantt_here");

  }, [projectGanttStore.isShowGrid])

  useEffect(() => {
    gantt.config.layout = projectGanttStore.showChart ? gridAndChart : onlyGrid
    gantt.init("gantt_here");
  }, [projectGanttStore.showChart])

  const setZoom = value => {
    updateMarkerTime(false)
    if (!gantt.ext.zoom.getLevels()) {
      initZoom()
    }
    gantt.ext.zoom.setLevel(value)
    if (zoomToFit) onToggleZoomToFit({ status: false, isUpdate: false })
  }

  const initGanttDataProcessor = () => {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    //const onDataUpdated = onDataUpdated;
    dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise(async (resolve, reject) => {
        console.log(type, action)
        let taskId
        if (onDataUpdated && !isResizeRow?.current) {
          taskId = await onDataUpdated(type, action, item, id, isDeletedOCreatedNew)
        }

        if(taskId.status === 'error'){
          return reject()
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});
        return taskId ? resolve({ id: taskId }) : resolve()
      })
    })
  }
  /**
   * custom layout
   */
  const onlyGrid = {
    css: "gantt_container",
    cols: [
      {
        rows: [
          { view: "grid", scrollX: "gridScroll", scrollY: "scrollVer" },
        ]
      },
      { view: "scrollbar", id: "gridScroll", group: "horizontal" }
    ]
  };

  const onlyChart = {
    css: "gantt_container",
    cols: [
      {
        rows:[
          {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
          {view: "scrollbar", id: "scrollHor", group:"horizontal"}
        ]
      },
      {view: "scrollbar", id: "scrollVer"}
    ]
  };

  const gridAndChart = {
    css: "gantt_container",
    cols: [
      {
        width: 220,
        minWidth: 220,
        maxWidth: 600,
        rows: [
          { view: "grid", scrollX: "gridScroll", scrollY: "scrollVer" },

          // horizontal scrollbar for the grid
          { view: "scrollbar", id: "gridScroll", group: "horizontal" }
        ]
      },
      { resizer: true, width: 1 },
      {
        rows: [
          { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },

          // horizontal scrollbar for the timeline
          { view: "scrollbar", id: "scrollHor", group: "horizontal" }
        ]
      },
      { view: "scrollbar", id: "scrollVer" }
    ]
  };

  const gridColumnConfig = () => {
    gantt.serverList("priority", [
      { key: 'hight', label: 'High' },
      { key: 'normal', label: 'Normal' },     
      { key: 'low', label: 'Low' },
    ]);
    gantt.serverList('taskType', [
      { key: 'new', label: 'New' },
      { key: 'demolish', label: 'Demolish' },
      { key: 'temporary', label: 'Temporary' },
    ])
    const textEditor = { type: 'text', map_to: 'text' }
    const dateEditor = {
      type: 'date',
      map_to: 'start_date',
    }
    const durationEditor = {
      type: 'number',
      map_to: 'duration',
      min: 0,
      max: 100,
    }
    const priority = {
      type: 'select',
      map_to: 'priority',
      options: gantt.serverList('priority'),
    }
    const taskType = {
      type: 'select',
      map_to: 'taskType',
      options: gantt.serverList('taskType'),
    }

    const progressEditor = {
      map_to: 'progress',
      type: 'select',
      options: [
        { key: 0, label: 'Not started' },
        { key: 0.1, label: '10%' },
        { key: 0.2, label: '20%' },
        { key: 0.3, label: '30%' },
        { key: 0.4, label: '40%' },
        { key: 0.5, label: '50%' },
        { key: 0.6, label: '60%' },
        { key: 0.7, label: '70%' },
        { key: 0.8, label: '80%' },
        { key: 0.9, label: '90%' },
        { key: 1, label: 'Complete' },
      ],
    }

    window.clickGridButton = function (id, action) {      
      switch (action) {
        case 'edit':
          let task = gantt.getTask(id);
          if (!task?.$group_id) {
            gantt.showLightbox(id)
          }          
          break
        case 'add':
          gantt.createTask(null, id)
          break
        case 'delete':
          gantt.confirm({
            title: gantt.locale.labels.confirm_deleting_title,
            text: gantt.locale.labels.confirm_deleting,
            callback: function (res) {
              if (res) {
                gantt.deleteTask(id)
              }
            },
          })
          break
      }
    }
    const colHeader =
      '<div class="gantt_grid_head_cell gantt_grid_head_add" onclick="gantt.createTask()"></div>'
    const colContent = function (task) {
      return `<i class="fa gantt_button_grid gantt_grid_add fa-plus" onclick="clickGridButton('${task.id}', 'add')"></i>`
      //XDTWIN-3108
      //`<i class="fa gantt_button_grid gantt_grid_edit fa-pencil" onclick="clickGridButton('${task.id}', 'edit')"></i>
      //<i class="fa gantt_button_grid gantt_grid_delete fa-times" onclick="clickGridButton('${task.id}', 'delete')"></i>`
    }

    const formatter = gantt.ext.formatters.durationFormatter({
      enter: 'day',
      store: 'hour',
      format: 'auto',
    })

    let columnsConfig = [
      {
        hide: false,
        name: 'wbs',
        label: t('wbs'),
        //min_width: 50,
        width: 50,
        template: gantt.getWBSCode,
        resize: true,
      },
      {
        hide: false,
        name: 'text',
        label: t('task-name'),
        width: '*',
        tree: true,
        editor: textEditor,
        width: 120,
        resize: true,
      },
      {
        name: 'start_date',
        label: t('start-time'),
        align: 'center',
        editor: dateEditor,
        width: 100,
        resize: true,
        hide: true,
      },
      {
        hide: true,
        name: 'end_date',
        label: t('end'),
        align: 'center',
        width: 100,
        template: function (task) {
          return gantt.templates.date_grid(task.end_date, task)
        },
        resize: true,
      },
      {
        name: 'duration',
        label: t('duration'),
        align: 'center',
        editor: durationEditor,
        width: 80,
        resize: true,
        hide: true,
        template: function (task) {
          return formatter.format(task.duration)
        },
      },
      {
        hide: true,
        name: 'priority',
        label: t('priority'),
        width: 90,
        align: 'center',
        resize: true,
        template: function (item) {
          if (item.priority) {
            var priority = gantt
              .serverList('priority')
              .find(function (option) {
                return option.key === item.priority
              })
            if (priority) {
              return priority.label
            }
          }
          return 'normal'
        },
        editor: priority,
      },
      {
        hide: true,
        name: 'taskType',
        label: t('task-type'),
        width: 90,
        align: 'center',
        resize: true,
        template: function (item) {
          if (item.taskType) {
            var type = gantt
              .serverList('taskType')
              .find(function (option) {
                return option.key === item.taskType
              })
            if (type) {
              return type.label
            }
          }
          return 'New'
        },
        editor: taskType,
      },
      {
        hide: true,
        name: 'progress',
        label: t('gantt.progress'),
        width: 80,
        align: 'center',
        resize: true,
        template: function (item) {
          if (item.progress >= 1) return 'Complete'
          if (item.progress === 0) return 'Not started'
          return Math.round(item.progress * 100) + '%'
        },
        editor: progressEditor,
      },
      //{ name: 'add', label: '', width: 44 },
      {
        hide: false,
        name: 'buttons',
        label: colHeader,
        width: 50,
        //resize: true,
        align: 'center',
        template: colContent,
      },
    ]

    if (!checkingFeatureRole('feature_4d_gantt_edit')) {
      columnsConfig = columnsConfig.filter(col => col.name !== 'buttons')
    }
    // default columns definition
    gantt.config.columns = columnsConfig

    // custom sort wbs column
    gantt.config.columns[0].sort = function (a, b) {
      return custom_sort_wbs_function(a, b);
    };
  }

  const custom_sort_wbs_function = (a, b) => {
    let task1 = gantt.getTask(a.id);
    let task2 = gantt.getTask(b.id);
    return +gantt.getWBSCode(task1) - +gantt.getWBSCode(task2)
  }

  const handleGetNewTask = () => {
    const { data, links } = projectGanttStore.projectGanttData[0]
    return { data, links }
  }
  /**
   * Custom lightbox
   */
  const lightboxConfig = () => {
    gantt.locale.labels.section_priority = t('priority')
    gantt.locale.labels.section_taskType = t('type')
    gantt.locale.labels['section_parent'] = t('gantt.parent-task')
    gantt.locale.labels['section_progress'] = t('gantt.progress')
    gantt.locale.labels['section_categories'] = t('gantt.categories')
    gantt.locale.labels.section_split = t('gantt.display')

    gantt.serverList('customTypeOptions', [
      { key: 'task', label: 'Task' },
      { key: 'project', label: 'Project' },
      { key: 'milestone', label: 'Milestone' },
    ])

    // custom add text field name
    gantt.locale.labels.section_name = t('name');
    gantt.form_blocks["text_field"] = {
      render: function (sns) {
        return "<div class='dhx_cal_ltext'>" +
          "&nbsp;&nbsp;<input name='text' type='text' style='width: 97.7%'></div>";
      },
      set_value: function (node, value, ev) {
        node.querySelector("[name='text']").value = value || "";
      },
      get_value: function (node, ev) {
        return node.querySelector("[name='text']").value;
      },
      focus: function (node) {
        var input = node.querySelector("[name='text']");
        input.focus();
      }
    };
    
    const defaultLightboxConfiguration = [
      { 
        name: "name", 
        map_to: "text", 
        type: "text_field" , 
        focus:true,
        height: 38,
      },
      {
        name: 'description',
        map_to: 'description',
        type: 'textarea',
        height: 100
      },
      {
        name: 'categories',
        height: 38,
        map_to: 'type',
        type: 'select',
        options: gantt.serverList('customTypeOptions'),
      },
      {
        name: 'priority',
        height: 38,
        map_to: 'priority',
        type: 'select',
        options: gantt.serverList('priority'),
      },
      {
        name: 'parent',
        type: 'parent',
        height: 38,
        allow_root: 'true',
        root_label: 'No parent',
        filter: function (id, task) {
          return true
        },
      },
      {
        name: 'taskType',
        height: 38,
        map_to: 'taskType',
        type: 'select',
        options: gantt.serverList('taskType'),
      },
      {
        name: 'highlightColor',
        height: 38,
        type: 'template',
        map_to: 'highlight_color',
      },
      {
        name: 'template',
        height: 38,
        type: 'template',
        map_to: 'my_template',
      },
    ]

    gantt.config.lightbox.sections = [
      ...defaultLightboxConfiguration,
      {
        name: 'progress',
        height: 38,
        map_to: 'progress',
        type: 'select',
        options: [
          { key: 0, label: 'Not started' },
          { key: 0.1, label: '10%' },
          { key: 0.2, label: '20%' },
          { key: 0.3, label: '30%' },
          { key: 0.4, label: '40%' },
          { key: 0.5, label: '50%' },
          { key: 0.6, label: '60%' },
          { key: 0.7, label: '70%' },
          { key: 0.8, label: '80%' },
          { key: 0.9, label: '90%' },
          { key: 1, label: 'Complete' },
        ],
      },
      {
        name: 'time',
        type: 'time',
        height: 40,
        map_to: 'auto',
        time_format: ['%d', '%m', '%Y', '%H:%i'],
      },
    ]
    
    gantt.config.open_split_tasks = true;
    gantt.config.lightbox['project_sections'] = [
      ...defaultLightboxConfiguration,
      {name: "split", height: 38, type:"checkbox", map_to: "render", options:[
        {key:"split", label: t('gantt.split-task')}
      ]},
      {
        name: 'progress',
        height: 38,
        map_to: 'progress',
        type: 'select',        
        options: [
          { key: 0, label: 'Not started' },
          { key: 0.1, label: '10%' },
          { key: 0.2, label: '20%' },
          { key: 0.3, label: '30%' },
          { key: 0.4, label: '40%' },
          { key: 0.5, label: '50%' },
          { key: 0.6, label: '60%' },
          { key: 0.7, label: '70%' },
          { key: 0.8, label: '80%' },
          { key: 0.9, label: '90%' },
          { key: 1, label: 'Complete' },
        ],
      },
      {
        name: 'time',
        type: 'time',
        height: 40,
        map_to: 'auto',
        time_format: ['%d', '%m', '%Y', '%H:%i'],
        readonly: true,
      },
    ]
    gantt.config.lightbox['milestone_sections'] = [
      ...defaultLightboxConfiguration,
      {
        name: 'time',
        type: 'time',
        height: 40,
        map_to: 'auto',
        time_format: ['%d', '%m', '%Y', '%H:%i'],
        single_date: true,
        align: 'left',
      },
    ]

    gantt.locale.labels.section_template = t('gantt.gantt-definition')
    gantt.locale.labels.section_highlightColor = t('gantt.color')

    const handleOpenModalLinkedToDatatree = task => {
      if (!task) return
      if (projectGanttStore.selectedNodeInDataTree?.length) {
      } else if (task?.dataTree) {
        projectGanttStore.setSelectedNodeInDataTree(task?.dataTree)
      }
      projectGanttStore.setIsOpenModalSelectTreeData({
        open: true,
        taskId: task.id,
      })
    }
    const handleOpenModalLinkedToSavedQuery = task => {
      if (!task) return
      if (projectGanttStore.selectedSavedQuery?.length) {
      } else if (task?.savedQuery) {
        projectGanttStore.setSelectedSavedQuery(task?.savedQuery)
      }
      projectGanttStore.setIsOpenModalLinkSavedQuery(true)
    }

    const highlightColorComponent = task => {
      task.highlight_color = `<div class='color_selector_wrapper'>
      <input id="highlight_enable" type="checkbox" ${projectGanttStore.highlightEnable === true || projectGanttStore.highlightEnable === undefined ? 'checked' : " "} /><label for="highlight_enable">${t('gantt.highlight')}</label>&nbsp;
      <input id="color_task_enable" type="checkbox" ${projectGanttStore.colorTaskEnable === true? 'checked' : " "} /><label for="color_task_enable">Gantt</label>&nbsp;
      <input id="highlight" type="color" value="${projectGanttStore.currentColorPicker || '#ff0000'}">

      <input type="range" id="highlight_alpha" min="0" max="1" step="0.1" value="${
        projectGanttStore.currentAlpha || '0.5'
      }"/>
      <input id="highlight_alpha_value" value="${
        projectGanttStore.currentAlpha || '0.5'
      }" class='alpha_value'/>
      </div>
      `
    }

    gantt.attachEvent(
      'onBeforeLightbox',
      function (id) {
        let cTask = gantt.getTask(id)
        let defaulTask = projectStore.projectDetail?.metadata?.ganttDefault?.find(item => item.userId === projectStore.projectDetail.currentUser._id);
        // fill default value for new task
        if (isNewTask && defaulTask) {
          if (defaulTask?.type) cTask.type = defaulTask.type;
          if (defaulTask?.taskType) cTask.taskType = defaulTask.taskType;
          if (defaulTask?.priority) cTask.priority = defaulTask.priority;
          cTask.progress = defaulTask?.progress === 0? "0" : Number(defaulTask?.progress);
          projectGanttStore.setHighlightEnable(defaulTask?.highlightEnable !== undefined ? defaulTask.highlightEnable : true)
          projectGanttStore.setColorTaskEnable(defaulTask?.colorTaskEnable !== undefined ? defaulTask.colorTaskEnable : true)
          projectGanttStore.setCurrentColorPicker(defaulTask?.highlightColor !== undefined ? defaulTask.highlightColor : '#ff0000')
          projectGanttStore.setCurrentAlpha(defaulTask?.highlightAlpha !== undefined ? defaulTask.highlightAlpha : 0.5)        
        } else {
          cTask.progress = cTask?.progress === 0 ? '0' : cTask?.progress;
          cTask?.highlightColor && projectGanttStore.setCurrentColorPicker(cTask?.highlightColor)
          cTask?.highlightAlpha && projectGanttStore.setCurrentAlpha(cTask?.highlightAlpha)
          projectGanttStore.setHighlightEnable(cTask?.highlightEnable !== undefined ? cTask?.highlightEnable : true)
          projectGanttStore.setColorTaskEnable(cTask?.colorTaskEnable !== undefined ? cTask?.colorTaskEnable : false)
        }

        highlightColorComponent(cTask)
        cTask.my_template = `<div class="flex">
          <div>
            <button id='tree_select_btn' >${t('data-tree')}</button>
            <button id='saved_query_select_btn' >${t('gantt.saved-query')}</button>
          </div>
          <div class="righ">          
            <button style="border:0; padding:0" id='btn_sketch_single' title="${t('single')}">
              <div class="sketchIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                    <path d="M910 1691 c-90 -29 -175 -105 -213 -189 -16 -35 -21 -67 -21 -127 0 -60 5 -92 21 -127 40 -88 136 -170 226 -192 l27 -6 0 -238 c0 -199 3 -241 16 -260 19 -28 49 -28 68 0 13 19 16 61 16 260 l0 238 28 6 c89 22 185 104 225 192 30 66 30 188 0 254 -29 63 -96 134 -158 166 -39 20 -68 26 -130 28 -44 2 -91 0 -105 -5z m185 -105 c54 -25 100 -71 122 -123 17 -42 17 -134 0 -176 -35 -83 -130 -147 -217 -147 -87 0 -182 64 -217 147 -7 18 -13 57 -13 88 0 31 6 70 13 88 35 83 130 147 217 147 26 0 66 -10 95 -24z" />
                    <path d="M934 1497 c-52 -45 -23 -121 44 -115 50 4 80 45 63 86 -11 26 -46 52 -71 52 -5 0 -21 -10 -36 -23z" />
                    <path d="M697 834 c-167 -30 -313 -95 -364 -163 -30 -39 -41 -108 -23 -151 18 -43 73 -88 154 -126 98 -46 205 -71 383 -91 144 -16 162 -16 306 0 187 21 287 46 393 97 90 44 137 89 155 149 10 35 9 43 -17 90 -50 93 -186 161 -395 197 -107 18 -136 14 -145 -22 -10 -38 13 -54 96 -65 81 -12 226 -51 271 -74 47 -25 99 -76 99 -97 0 -44 -93 -103 -220 -139 -90 -26 -272 -49 -390 -49 -284 0 -584 88 -606 177 -6 23 -1 33 35 65 24 20 68 47 99 59 54 20 191 52 275 64 49 8 70 44 42 75 -21 23 -40 24 -148 4z" />
                  </g>
                </svg>
              </div>
            </button>
            <button style="border:0; padding:0" id='btn_sketch_line' title="${t('line')}">
              <div class="sketchIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                    <path d="M1296 1734 c-13 -13 -16 -46 -16 -202 l0 -187 -313 -313 -312 -312 -187 0 c-156 0 -189 -3 -202 -16 -24 -23 -24 -415 0 -438 23 -24 415 -24 438 0 13 13 16 46 16 202 l0 187 313 313 312 312 187 0 c156 0 189 3 202 16 24 23 24 415 0 438 -13 14 -47 16 -219 16 -172 0 -206 -2 -219 -16z m362 -216 l3 -138 -146 0 -145 0 0 133 c0 74 3 137 7 141 4 3 68 5 142 4 l136 -3 3 -137z m-1037 -1033 l0 -140 -138 0 -138 0 -3 129 c-1 72 0 136 2 143 4 11 36 13 141 11 l136 -3 0 -140z" />
                  </g>
                </svg>
              </div>
            </button>
            <button style="border:0; padding:0" id='btn_sketch_area' title="${t('area')}">
              <div class="sketchIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                      <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                      <path d="M266 1734 c-13 -13 -16 -43 -16 -173 0 -117 3 -161 13 -169 7 -6 37 -12 67 -14 l55 -3 3 -371 2 -372 -54 -4 c-86 -7 -86 -7 -86 -189 0 -130 3 -160 16 -173 13 -13 43 -16 173 -16 182 0 185 1 182 80 -1 27 3 51 8 55 9 5 658 137 720 147 20 3 24 -2 26 -30 5 -60 11 -62 189 -62 113 0 166 4 174 12 8 8 12 61 12 173 0 112 -4 165 -12 173 -7 7 -38 12 -70 12 l-58 0 0 190 0 190 58 0 c32 0 63 5 70 12 17 17 17 329 0 346 -8 8 -61 12 -174 12 -178 0 -184 -2 -189 -62 -2 -28 -6 -33 -26 -30 -62 10 -711 142 -720 147 -5 4 -9 28 -8 55 3 79 0 80 -182 80 -130 0 -160 -3 -173 -16z m264 -169 l0 -95 -95 0 -95 0 0 88 c0 49 3 92 7 95 3 4 46 7 95 7 l88 0 0 -95z m842 -260 c-3 -90 10 -109 83 -115 l60 -5 0 -185 0 -185 -60 -5 c-73 -6 -86 -25 -83 -115 l2 -60 -373 -75 c-205 -42 -374 -73 -377 -69 -2 4 -4 31 -3 59 1 28 -3 56 -9 62 -7 7 -39 14 -72 16 l-60 4 2 372 3 371 60 3 c33 2 64 7 69 12 5 5 8 32 7 60 -1 28 1 55 3 59 3 4 172 -27 377 -69 l373 -75 -2 -60z m283 70 l0 -90 -92 -3 -93 -3 0 96 0 96 93 -3 92 -3 0 -90z m0 -750 l0 -90 -92 -3 -93 -3 0 96 0 96 93 -3 92 -3 0 -90z m-1125 -190 l0 -96 -92 3 -93 3 -3 93 -3 92 96 0 95 0 0 -95z" />
                    </g>
                  </svg>
              </div>
            </button>
            <button style="border:0; padding:0" id='btn_sketch_volume' title="${t('volume')}">
              <div class="sketchIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                    <path d="M655 1632 c-165 -65 -315 -126 -333 -136 -21 -11 -41 -33 -53 -59 -17 -39 -19 -71 -19 -436 0 -381 1 -396 21 -438 27 -55 25 -54 394 -199 273 -106 303 -116 349 -111 49 4 621 221 673 254 12 8 31 34 43 57 19 40 20 60 20 434 0 428 -3 452 -59 491 -14 10 -166 73 -338 140 -230 89 -322 121 -355 121 -31 0 -126 -33 -343 -118z m645 -83 c143 -56 260 -105 260 -108 0 -13 -554 -222 -574 -217 -42 11 -546 211 -546 217 0 3 55 28 123 54 67 26 190 74 272 106 102 40 159 58 178 54 15 -2 144 -50 287 -106z m-637 -302 l287 -112 0 -387 c0 -214 -4 -388 -8 -388 -5 0 -134 49 -287 109 -181 71 -286 117 -297 131 -17 20 -18 54 -18 397 0 353 1 375 18 369 9 -4 146 -57 305 -119z m997 -251 c0 -341 -2 -376 -18 -395 -11 -14 -120 -63 -305 -135 l-287 -114 0 392 0 391 298 117 c163 64 300 117 305 117 4 1 7 -167 7 -373z" />
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </div>
        `
        if (isNewTask && defaulTask) {
          return true
        }

        const { data } = handleGetNewTask()
        if (data?.length > 0) {
          const selectedTask = data.find(task => task.id === id)
          if (!selectedTask) {
            projectGanttStore.setSelectedNodeInDataTree([])
            projectGanttStore.setSelectedSavedQuery([])
          } else {
            projectGanttStore.setSelectedNodeInDataTree(
              selectedTask?.dataTree ?? []
            )
            projectGanttStore.setSelectedSavedQuery(
              selectedTask?.savedQuery ?? []
            )
          }
        } else {
          projectGanttStore.setSelectedNodeInDataTree([])
          projectGanttStore.setSelectedSavedQuery([])
        }

        // fill data for case open lightbox from sketch drawer
        if(projectGanttStore.sketchDrawer.open && projectGanttStore.sketchDrawer.task) {
          cTask={
            ...cTask,
            ...projectGanttStore.sketchDrawer.task
          }
          projectGanttStore.setHighlightEnable(projectGanttStore.sketchDrawer.task.highlightEnable !== undefined ? projectGanttStore.sketchDrawer.task.highlightEnable : true)
          projectGanttStore.setColorTaskEnable(projectGanttStore.sketchDrawer.task.colorTaskEnable !== undefined ? projectGanttStore.sketchDrawer.task.colorTaskEnable : true)
          projectGanttStore.setCurrentColorPicker(projectGanttStore.sketchDrawer.task.highlightColor !== undefined ? projectGanttStore.sketchDrawer.task.highlightColor : '#ff0000')
          projectGanttStore.setCurrentAlpha(projectGanttStore.sketchDrawer.task.highlightAlpha !== undefined ? projectGanttStore.sketchDrawer.task.highlightAlpha : 0.5)         
          if(projectGanttStore.sketchDrawer.sketchId) projectGanttStore.selectedNodeInDataTree.push(projectGanttStore.sketchDrawer.sketchId)
        }
        
        return true
      },
      { id: 'beforeLightbox' }
    )

    gantt.attachEvent(
      'onLightbox',
      function (id) {
        ///const cTask = gantt.getTask(id)
        projectGanttStore.setIsLightboxOpen(true)

        const button = document.getElementById('tree_select_btn')
        if (!button?.getEventListeners()?.click) {
          button.addEventListener('click', function () {
            const { data } = handleGetNewTask()
            if (data?.length > 0) {
              const selectedTask = data.find(task => task.id === id)
              if (!selectedTask) {
                handleOpenModalLinkedToDatatree({ dataTree: [] })
                return true
              }
              handleOpenModalLinkedToDatatree(selectedTask)
            } else {
              handleOpenModalLinkedToDatatree({ dataTree: [] })
            }
          })
        }

        const buttonLinkToSaveQuery = document.getElementById(
          'saved_query_select_btn'
        )
        if (!buttonLinkToSaveQuery?.getEventListeners()?.click) {
          buttonLinkToSaveQuery.addEventListener('click', function () {
            projectGanttStore.setIsOpenModalLinkSavedQuery(true)
            const { data } = handleGetNewTask()
            if (data?.length > 0) {
              const selectedTask = data.find(task => task.id === id)
              if (!selectedTask) {
                handleOpenModalLinkedToSavedQuery({ savedQuery: [] })
                return true
              }
              handleOpenModalLinkedToSavedQuery(selectedTask)
            } else {
              handleOpenModalLinkedToSavedQuery({ savedQuery: [] })
            }
          })
        }

        // Action click button drawer sketch single
        const buttonSketchSingle = document.getElementById('btn_sketch_single')
        if (!buttonSketchSingle?.getEventListeners()?.click) {
          buttonSketchSingle.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: isNewTask, sketchId: '', open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();
            projectGanttStore.setIsShowGanttPanel(false)

            // show sketch point
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('point')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'point')
            })
            sketchingStore.setSketchProps({ extrudedHeight: 1 })
            sketchingStore.setSketchProps({ width: 1 })
            sketchingStore.setSketchProps({ rotation: 0 })
          })
        }

        // Action click button drawer sketch line
        const buttonSketchLine = document.getElementById('btn_sketch_line')
        if (!buttonSketchLine?.getEventListeners()?.click) {
          buttonSketchLine.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: isNewTask, sketchId: '', open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();
            projectGanttStore.setIsShowGanttPanel(false)

            // show sketch line
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
            sketchingStore.setSketchProps({ width: 0.5 })
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'line')
            })
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('line')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
          })
        }
        
        // Action click button drawer sketch area
        const buttonSketchArea = document.getElementById('btn_sketch_area')
        if (!buttonSketchArea?.getEventListeners()?.click) {
          buttonSketchArea.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: isNewTask, sketchId: '', open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();
            projectGanttStore.setIsShowGanttPanel(false)

            // show sketch area
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setSketchProps({ height: 0 })
            sketchingStore.setSketchProps({ extrudedHeight: 0 })
            sketchingStore.setSketchProps({ width: 0 })
            sketchingStore.setSketchProps({ readonlyHeight: true })
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area')
            })
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('area')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
          })
        }

        // Action click button drawer sketch volume
        const buttonSketchVolume = document.getElementById('btn_sketch_volume')
        if (!buttonSketchVolume?.getEventListeners()?.click) {
          buttonSketchVolume.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: isNewTask, sketchId: '', open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();
            projectGanttStore.setIsShowGanttPanel(false)

            // show sketch volume
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
            sketchingStore.setSketchProps({ width: 0 })
            sketchingStore.setSketchProps({ readonlyHeight: false })
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area', true)
            })
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('area')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
          })
        }

        const enableHighlightCheckbox = document.getElementById('highlight_enable')
        if (!enableHighlightCheckbox?.getEventListeners()?.change) {
          enableHighlightCheckbox.addEventListener('change', function (e) {
            projectGanttStore.setHighlightEnable(e.target.checked)
          })
        }

        const isColorTaskCheckbox = document.getElementById('color_task_enable')
        if (!isColorTaskCheckbox?.getEventListeners()?.change) {
          isColorTaskCheckbox.addEventListener('change', function (e) {
            projectGanttStore.setColorTaskEnable(e.target.checked)
          })
        }

        const colorInput = document.getElementById('highlight')
        if (!colorInput?.getEventListeners()?.change) {
          colorInput.addEventListener('change', function (e) {
            projectGanttStore.setCurrentColorPicker(e.target.value)
          })
        }

        const alphaSlider = document.getElementById('highlight_alpha')
        const alphaInput = document.getElementById('highlight_alpha_value')

        if (!alphaSlider?.getEventListeners()?.input) {
          alphaSlider.addEventListener('input', function (e) {
            alphaInput.value = e.target.value
            projectGanttStore.setCurrentAlpha(e.target.value)
          })
        }

        if (!alphaInput?.getEventListeners()?.change) {
          alphaInput.addEventListener('change', function (e) {
            alphaSlider.value = e.target.value
            projectGanttStore.setCurrentAlpha(e.target.value)
          })
        }
      },
      { id: 'openLightbox' }
    )

    gantt.attachEvent(
      'onLightboxCancel',
      function (id) {
        projectGanttStore.setSelectedNodeInDataTree([])
        projectGanttStore.setSelectedSavedQuery([])
        projectGanttStore.setCurrentColorPicker('')
        projectGanttStore.setCurrentAlpha('0.5')
        projectGanttStore.setColorTaskEnable()
      },
      { id: 'cancelLightbox' }
    )

    gantt.attachEvent(
      'onAfterLightbox',
      function () {
        isNewTask = false;
        isDeletedOCreatedNew = false;
        projectGanttStore.setIsLightboxOpen(false)
      },
      { id: 'afterLightbox' }
    )

    gantt.attachEvent(
      'onTaskDblClick',
      function (id, e) {
        let task = gantt.getTask(id);
        if (!task?.$group_id) {
          gantt.showLightbox(id)
        }
        return true
      },
      { id: 'taskDblClick' }
    )

    // event when after click on task drag and drop update start_date end_date range
    gantt.attachEvent("onAfterTaskDrag", function (id, mode, e) {
      const modes = gantt.config.drag_mode;
      if (mode === modes.move) {
        const soonest = new Date(getStartSoonest(tasks.data))
        const latest = new Date(getEndDateLatest(tasks.data))
        gantt.config.start_date = gantt.date.add(soonest, -365, 'day')
        gantt.config.end_date = gantt.date.add(latest, 365, 'day')
        gantt.render();
      }
    });

    gantt.attachEvent(
      'onLightboxSave',
      function (id, task, is_new) {
        if (gantt.config.readonly) {
          message.error('You cannot edit this task')
          return false
        }
        return true
      },
      { id: 'lightboxSave' }
    )

    gantt.attachEvent(
      'onLightboxDelete',
      function (id) {
        isDeletedOCreatedNew = true;
        if (gantt.config.readonly) {
          message.error('You cannot edit this task')
          return false
        }
        return true
      },
      { id: 'lightboxDelete' }
    )

    gantt.attachEvent("onTaskCreated", function (task) {
      isNewTask = true;
      isDeletedOCreatedNew = true;
      return true;
    }); 

    gantt.attachEvent("onParse", () => {
      if (projectGanttStore.sketchDrawer.drawSketchType) {
        if (projectGanttStore.sketchDrawer.sketchId) {
          let cTask = projectGanttStore.sketchDrawer.task
          if (!cTask?.dataTree) {
            cTask.dataTree = [];
          }
          cTask.dataTree.push(projectGanttStore.sketchDrawer.sketchId)
          gantt.updateTask(projectGanttStore.sketchDrawer.task.id, projectGanttStore.sketchDrawer.task);
        }
        gantt.selectTask(projectGanttStore.sketchDrawer.task.id);
        projectGanttStore.setSketchDrawer({ task: '', isNewTask: false, sketchId: '', open: false, drawSketchType: '' })
      } else {
        let defaulTask = projectStore.projectDetail?.metadata?.ganttDefault?.find(item => item.userId === projectStore.projectDetail.currentUser._id);
        if (defaulTask && defaulTask?.selectedTaskId) {
          const { data } = handleGetNewTask()
          if (data?.length > 0) {
            const selectedTask = data.find(task => task.id === defaulTask.selectedTaskId)
            if (selectedTask) {
              gantt.selectTask(defaulTask.selectedTaskId);
            }
          }
        }
      }
    });

    gantt.attachEvent("onTaskSelected", function(id) {
      const task = gantt.getTask(id)
      const middate = new Date((task.start_date.getTime() + task.end_date.getTime()) / 2);
      const midDatePos = gantt.posFromDate(middate);
      gantt.scrollTo(midDatePos-300, null);
      if (task?.$new !== true) { // set default selected task it saved in drawer gantt closed
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, userId: projectStore.projectDetail.currentUser._id, selectedTaskId: task.id })
      }
    });

    gantt.config.buttons_left = ["gantt_save_btn", "gantt_cancel_btn"];
  }



  /**
   * plugin configuration
   */
  const pluginsConfig = () => {
    gantt.plugins({
      marker: true,
    })
    gantt.plugins({
      drag_timeline: true,
    })
    gantt.plugins({
      tooltip: true,
    })
    gantt.plugins({
      critical_path: true,
    })
    gantt.plugins({
      auto_scheduling: true,
    })
    gantt.plugins({
      undo: true,
    })
    gantt.plugins({
      export_api: true,
    })
    gantt.plugins({
      grouping: true
    });
  }

  const dragExtendTimeline = () => {
    let previousX = 0
    gantt.attachEvent(
      'onMouseMove',
      function (id, e) {
        if (!gantt.getState().drag_id && e.buttons == 1 && e.ctrlKey) {
          const currentZoom =
            ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
          const scrollLeft = gantt.dateFromPos(gantt.$task?.scrollLeft)
          const left_date = gantt.dateFromPos(gantt.getScrollState().x)
          const right_date = gantt.dateFromPos(
            gantt.getScrollState().x + gantt.$task.offsetWidth - 1
          )

          const handDebounceCalculateExtendTimeline = debounce(() => {
            if (
              scrollLeft &&
              +gantt.config.start_date >
                +gantt.date.add(scrollLeft, -1, currentZoom) &&
              e.x > previousX
            ) {
              gantt.config.start_date = gantt.date.add(
                gantt.config.start_date,
                -1,
                currentZoom
              )
              gantt.render()
            }
            previousX = e.x
          }, 325)

          handDebounceCalculateExtendTimeline()
          if (
            right_date &&
            +gantt.config.end_date < +gantt.date.add(right_date, 1, currentZoom)
          ) {
            gantt.config.end_date = gantt.date.add(
              gantt.config.end_date,
              1,
              currentZoom
            )
            gantt.render()
          }
        }
      },
      { id: 'mouseMove' }
    )
  }

  const getStartSoonest = (taskList = []) => {
    return taskList.reduce(function (a, b) {
      return new Date(a.start_date) < new Date(b.start_date) ? a : b
    }).start_date
  }

  const getEndDateLatest = (taskList = []) => {
    return taskList.reduce(function (a, b) {
      return new Date(a.end_date) > new Date(b.end_date) ? a : b
    }).end_date
  }

  const markerConfig = (date, label) => {
    if (!date || !label) return false

    const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
    let currentMarkerList = projectGanttStore.ganttMarkerList

    let markerRemovalList = []
    if (currentMarkerList?.length) {
      for (let i = 0; i < currentMarkerList.length; i++) {
        if (currentMarkerList[i]?.label === label && currentMarkerList[i]?.id) {
          const markerId = currentMarkerList[i].id
          if (gantt.getMarker(currentMarkerList[i].id)) {
            gantt.getMarker(markerId).start_date = date
            gantt.getMarker(markerId).title = dateToStr(date)
            gantt.getMarker(markerId).css = `${currentMarkerList[i]?.label} ${
              (!projectGanttStore.isActive4dPlayer ||
                (projectGanttStore.isActive4dPlayer &&
                  projectGanttStore.playerMode === 'dragGantt')) &&
              'hidden'
            }`
            gantt.updateMarker(markerId)
          } else {
            markerRemovalList.push(currentMarkerList[i].id)
          }
        }
      }
      if (markerRemovalList?.length) {
        markerRemovalList.forEach(
          rMarker =>
            (currentMarkerList = currentMarkerList.filter(
              cMarker => cMarker.id !== rMarker
            ))
        )
      }
    }

    if (
      currentMarkerList.length < 3 ||
      !currentMarkerList?.some(marker => marker?.label === label)
    ) {
      const markerId = gantt.addMarker({
        start_date: date, //a Date object that sets the marker's date
        css: `${label} + ${
          (projectGanttStore.isActive4dPlayer ||
            (projectGanttStore.isActive4dPlayer &&
              projectGanttStore.playerMode === 'dragGantt')) &&
          'hidden'
        }`, //a CSS class applied to the marker
        text: label === 'now' ? '4D' : label, //the marker title
        title: dateToStr(date), // the marker's tooltip
      })
      currentMarkerList.push({ id: markerId, label })
    }

    projectGanttStore.setGanttMarkerList(currentMarkerList)
  }

  const resizeRowConfig = () => {
    gantt.attachEvent(
      'onRowResize',
      function (id, item, currentHeight) {
        isResizeRow.current = true
      },
      { id: 'rowResize' }
    )

    gantt.attachEvent(
      'onAfterRowResize',
      function (id, item, oldHeight, newHeight) {
        isResizeRow.current = false
      },
      { id: 'afterRowResize' }
    )
  }

  const weekendConfig = () => {
    const daysStyle = date => {
      const dateToStr = gantt.date.date_to_str('%D')
      if (dateToStr(date) == 'Sun' || dateToStr(date) == 'Sat') return 'weekend'
      return ''
    }
    gantt.templates.scale_cell_class = function (date) {
      return daysStyle(date)
    }
    gantt.templates.timeline_cell_class = function (item, date) {
      return daysStyle(date)
    }
  }
  function startReRender() {
    if (viewer?._cesiumWidget) {
      if (viewer.scene.requestRenderMode) {
        viewer.scene.requestRender()
      }
    }
  }

  //#region Group
  const showGroups = (listname) => {
		if (listname) {
			gantt.groupBy({
				groups: gantt.serverList(listname),
				relation_property: listname,
				group_id: "key",
				group_text: "label"
			});
		} else {
			gantt.groupBy(false);
		}
	}

  useEffect(() => {
    if (ganttReady && projectGanttStore.groupType) {
      let type = projectGanttStore.groupType
      if(type === 'task'){
        type =''
      }
      showGroups(type)
    }
  }, [ganttReady, projectGanttStore.groupType])
  //#endregion group

  // #region Filter Task
  const filterLogic = (task, match) => {
    match = match || false;
		// check children
		gantt.eachTask(function (child) {
			if (filterLogic(child)) {
				match = true;
			}
		}, task.id);

		// check task
		if (task.text.toLowerCase().indexOf(projectGanttStore.filterTask.toLowerCase()) > -1) {
			match = true;
		}
		return match;
  }

  gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
    if (!projectGanttStore.filterTask) {
      return true;
    }
    return filterLogic(task);
  });

  useEffect(() => {
    if (ganttReady) {
      gantt.render()
    }
  }, [ganttReady, projectGanttStore.filterTask]);
 // #endregion filter task

  useEffect(() => {
    if (
      projectGanttStore.isActive4dPlayer &&
      projectGanttStore.currentViewingTime &&
      projectGanttStore.currentViewingTime.toDate &&
      viewer &&
      projectGanttStore.projectGanttData
    ) {
      let listKeyModelShow = []
      let listAllModelLinkedToGantt = []
      let listKeysavedQueryShow = []
      let listAllsavedQueryLinkedToGantt = []
      const currentTime = moment(projectGanttStore.currentViewingTime)
      projectGanttStore.projectGanttData.map(ganttData => {
        if (ganttData?.data?.length > 0) {
          ganttData.data.forEach(task => {
            [listKeyModelShow, listAllModelLinkedToGantt] = preProcessingData(
              task,
              currentTime,
              listKeyModelShow,
              listAllModelLinkedToGantt
            )
          })
        }
      })
      const savedQueryData = simplifiedGanttDataToGetSavedQuery(
        projectGanttStore.projectGanttData[0]
      )
      if (savedQueryData?.length > 0) {
        savedQueryData.forEach(task => {
          ;[listKeysavedQueryShow, listAllsavedQueryLinkedToGantt] =
            preProcessingData(
              task,
              currentTime,
              listKeysavedQueryShow,
              listAllsavedQueryLinkedToGantt,
              'savedQuery'
            )
        })
      }

      let showedModels = listKeyModelShow.map(data => ({
        id: data.id,
        isHighlight: data.isHighlight,
        highlightColor: data?.highlightColor,
        highlightAlpha: data?.highlightAlpha,
      }))
      let highlightSketchs = []
      const _visibleSketch = sketchingStore.visibleSketches.map(item => {
        let isExist = showedModels.find(visible => item.sketchId === visible.id)
        if (isExist) {
          item.isVisible = true
          if (isExist.isHighlight) {
            highlightSketchs.push({
              id: item.sketchId,
              highlightColor: isExist.highlightColor,
              highlightAlpha: isExist.highlightAlpha,
            })
          }
        } else {
          if (listAllModelLinkedToGantt.some(m => m === item.sketchId)) {
            item.isVisible = false
          }
        }
        return item
      })
      sketchingStore.setVisibleSketches(_visibleSketch)
      projectGanttStore.setListHighlightSketch(highlightSketchs)

      const _visibleModel = projectStore.visibleTilesets.map(item => {
        let isExist = showedModels.find(visible => item?.modelId === visible.id)
        if (isExist) {
          item.isVisible = true
          if (isExist.isHighlight) {
            applyColorChanges(
              item?.modelId,
              projectStore.tileViews,
              isExist.highlightColor,
              isExist.highlightAlpha,
              viewer
            )
          } else {
            applyColorChanges(
              item?.modelId,
              projectStore.tileViews,
              '#ffffff',
              1,
              viewer
            )
          }
        } else {
          if (listAllModelLinkedToGantt.some(m => m === item?.modelId)) {
            item.isVisible = false
          }
          applyColorChanges(
            item?.modelId,
            projectStore.tileViews,
            '#ffffff',
            1,
            viewer
          )
        }
        return item
      })
      projectStore.setVisibleTilesets(_visibleModel)
      projectGanttStore.setShowedModels(showedModels)

      if (objectQueryStore?.listObjectsQuery?.length) {
        objectQueryStore?.listObjectsQuery.forEach(sq => {
          let isExist = listKeysavedQueryShow.find(
            visible => sq.id === visible.id
          )
          const payload = {
            id: sq.id,
            src: sq?.src,
            name: sq?.name,
            queryParameters: sq?.queryParameters,
          }
          if (isExist) {
            payload.isShow = true
            if (isExist.isHighlight) {
              payload.highlightColor = isExist.highlightColor
              payload.highlightAlpha = isExist.highlightAlpha
              payload.isHighlight = true
              applySavedQueryHighlight(payload,
                projectStore.tileViews,
                isExist.highlightColor,
                isExist.highlightAlpha,
                viewer)
            }else{
              payload.highlightColor = '#ffffff'
              payload.highlightAlpha = 1
              payload.isHighlight = false
              applySavedQueryHighlight(payload,
                projectStore.tileViews,
                '#ffffff',
                1,
                viewer)
            }
          }else{
            if (listAllsavedQueryLinkedToGantt.some(m => m === sq.id)) {
              payload.isShow = false
            }else{
              payload.isShow = true
            }
            
            payload.highlightColor = '#ffffff'
            payload.highlightAlpha = 1
            payload.isHighlight = false
            applySavedQueryHighlight(payload,
              projectStore.tileViews,
              '#ffffff',
              1,
              viewer)
          }
        })
      }

      startReRender()
    }
  }, [
    projectGanttStore.currentViewingTime,
    projectGanttStore.isActive4dPlayer,
    projectGanttStore.projectGanttData,
  ])  

  useEffect(() => {
    if (ganttReady) {
      const dragGantt = document.getElementById('drag-gantt-line')
      const markers = projectGanttStore.ganttMarkerList

      if (projectGanttStore.isActive4dPlayer) {
        isPaused = projectGanttStore.playerMode === 'dragDate' ? true : false
        if (dragGantt) {
          dragGantt.className =
            projectGanttStore.playerMode === 'dragDate'
              ? 'drag-gantt-line hidden'
              : 'drag-gantt-line'
        }

        markers.forEach(marker => {
          const ganttMarker = gantt.getMarker(marker?.id)
          if (ganttMarker) {
            ganttMarker.css =
              projectGanttStore.playerMode === 'dragGantt'
                ? 'hidden'
                : marker?.label
            gantt.updateMarker(marker.id)
          }
        })
      }

      // Enable Drag timeline if playmode is dragGantt
      if (projectGanttStore.playerMode === 'dragGantt') {
        gantt.config.drag_timeline = {
          ignore:".gantt_task_line, .gantt_task_link",
          useKey: false
        }
      } else {
        gantt.config.drag_timeline = null
      }
    }
  }, [
    projectGanttStore.isPlay4d,
    projectGanttStore.playerMode,
    projectGanttStore.ganttMarkerList,
    projectGanttStore.isActive4dPlayer,
    projectGanttStore.todayMarker,
    ganttReady,
  ])
  
  useEffect(() => {
    if (ganttReady && projectGanttStore.currentViewingTime) {
      const pickedDate = projectGanttStore.currentViewingTime.toDate()
      dateShow.setDateShow(pickedDate)
      if (projectGanttStore.playerMode === 'dragDate') {
        updateMarkerTime(false)
        if (!isDragging) {
          // const currentZoom =
          //   ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
          // const dateDiff = projectGanttStore.currentViewingTime.diff(
          //   moment(gantt.config.end_date),
          //   currentZoom + 's'
          // )

          // if (pickedDate > gantt.config.end_date || Math.abs(dateDiff) <= 2) {
          //   gantt.config.end_date = gantt.date.add(pickedDate, 1 , currentZoom)
          // }
          // if (gantt.config.start_date > pickedDate) {
          //   gantt.config.start_date = gantt.date.add(
          //     pickedDate,
          //     -1,
          //     currentZoom
          //   )
          // }
          // gantt.render()

          function showDate(date) {
            const additional_width =
              (gantt.$container.offsetWidth - gantt.$grid_data.offsetWidth) / 2
            const position = gantt.posFromDate(date) - additional_width
            gantt.scrollTo(position)
          }

          //usage
          showDate(pickedDate)
        }
      }
    }
  }, [
    projectGanttStore.isActive4dPlayer,
    projectGanttStore.currentViewingTime,
    isDragging,
    projectGanttStore.playerMode,
    ganttReady,
  ])

  useEffect(() => {
    if (
      ganttReady &&
      projectGanttStore.isPlay4d &&
      projectGanttStore.currentViewingTime &&
      projectGanttStore.playerMode === 'dragGantt'
    ) {
      isPaused = true
      const pickedDate = projectGanttStore.currentViewingTime.toDate()
      const currentZoom = ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
      const right_date = gantt.dateFromPos(
        gantt.getScrollState().x + gantt.$task.offsetWidth - 1
      )
      const updateDate = handleAddDateTime(right_date, 1, currentZoom)
      if (right_date && +gantt.config.end_date < +updateDate) {
        gantt.config.end_date = updateDate
        gantt.render()
      }

      function showDate(date) {
        const additional_width =
          (gantt.$container.offsetWidth - gantt.$grid_data.offsetWidth) / 2
        const position = gantt.posFromDate(date) - additional_width
        gantt.scrollTo(position)
      }
      showDate(pickedDate)

      calculateMiddleDate(false)
    }
  }, [
    projectGanttStore.isPlay4d,
    projectGanttStore.playerMode,
    ganttReady,
    projectGanttStore.currentViewingTime,
  ])

  useEffect(() => {
    if (projectGanttStore.backOrForwardCount !== 0) {
      if (
        ganttReady &&
        projectGanttStore.currentViewingTime &&
        projectGanttStore.playerMode === 'dragGantt'
      ) {
        isPaused = true
        const updateEndDate = async () => {
          return new Promise(resolve => {
            const pickedDate = projectGanttStore.currentViewingTime.toDate()
            const currentZoom =
              ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
            const right_date = gantt.dateFromPos(
              gantt.getScrollState().x + gantt.$task.offsetWidth - 1
            )
            const updateDate = handleAddDateTime(right_date, 1, currentZoom)
            if (right_date && +gantt.config.end_date < +updateDate) {
              gantt.config.end_date = updateDate
              gantt.render()
            }

            function showDate(date) {
              const additional_width =
                (gantt.$container.offsetWidth - gantt.$grid_data.offsetWidth) /
                2
              const position = gantt.posFromDate(date) - additional_width
              gantt.scrollTo(position)
            }
            showDate(pickedDate)

            calculateMiddleDate(false)

            resolve()
          })
        }

        const handleBackOrForward = async () => {
          await updateEndDate()
          isPaused = false
        }
        handleBackOrForward()
      }
    }
  }, [projectGanttStore.backOrForwardCount])

  // Function to calculate the middle date and time
  const calculateMiddleDate = useCallback((isUpdateTime = true) => {
    if (gantt.$task?.offsetWidth) {
      let xPos =
        document.getElementById('drag-gantt-line')?.offsetLeft -
        gantt.$task?.offsetLeft +
        gantt.$task?.scrollLeft

      if (!xPos) xPos = gantt.$task.offsetWidth / 2

      const dateFromPos = gantt.dateFromPos(xPos)
      if (document.getElementById('drag-gantt-line')) {
        isUpdateTime &&
          projectGanttStore.playerMode === 'dragGantt' &&
          handleSetTime(dateFromPos)
      } else {
        const line = document.createElement('div')
        line.className = `drag-gantt-line ${
          projectGanttStore.playerMode === 'dragDate' && 'hidden'
        }`
        line.id = 'drag-gantt-line'
        gantt.$task.insertAdjacentElement('afterend', line)
      }
    }
  }, [])

  const createTodayMarker = () => {
    const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
    let currentMarkerList = projectGanttStore.todayMarker
    let markerRemovalList = []
    if (currentMarkerList?.length) {
      // check if this marker is already existing -> remove it
      // and then create a new marker
      for (let i = 0; i < currentMarkerList.length; i++) {
        let markerId = gantt.getMarker(currentMarkerList[i])
        if (markerId) {
          gantt.deleteMarker(currentMarkerList[i])
          markerRemovalList.push(currentMarkerList[i])
        }
      }
      if (markerRemovalList?.length) {
        markerRemovalList.forEach(
          rMarker =>
            (currentMarkerList = currentMarkerList.filter(
              cMarker => cMarker !== rMarker
            ))
        )
      }
    }
    const markerId = gantt.addMarker({
      start_date: new Date(), //a Date object that sets the marker's date
      css: `today`, //a CSS class applied to the marker
      text: t('today'), //the marker title
      title: dateToStr(new Date()), // the marker's tooltip
    })
    currentMarkerList.push(markerId)
    projectGanttStore.setTodayMarker(currentMarkerList)
  }

  const undoRedoConfig = () => {
    gantt.attachEvent('onBeforeParse', function () {
        previousUndoStack = gantt.ext.undo.getUndoStack()
        previousRedoStack = gantt.ext.undo.getRedoStack()
      },
      { id: 'beforeParse' }
    )

    gantt.attachEvent('onParse', function () {
        const currentUndoStack = gantt.ext.undo.getUndoStack()
        previousUndoStack.forEach(function (el) {
          currentUndoStack.push(el)
        })
        const currentRedoStack = gantt.ext.undo.getRedoStack()
        previousRedoStack.forEach(function (el) {
          currentRedoStack.push(el)
        })
      },
      { id: 'onParse' }
    )

    // To load task types when loading tasks
    gantt.attachEvent(
      'onTaskLoading',
      function (task) {
        if (task.$custom_data) {
          if (task.$custom_data.Summary === '1') {
            task.type = 'project'
          }
          if (task.$custom_data.Milestone === '1') {
            task.type = 'milestone'
          }
          // delete task.$custom_data;
        }
        return true
      },
      { id: 'taskLoading' }
    )
  }

  const calculateMiddlePosition = useCallback(() => {
    if (gantt.$task?.offsetWidth) {
      let xPos =
        document.getElementById('drag-gantt-line')?.offsetLeft -
        gantt.$task?.offsetLeft +
        gantt.$task?.scrollLeft

      if (!xPos) xPos = gantt.$task.offsetWidth / 2

      return xPos
    }
    return undefined
  }, [])

  const handleGetColumn = () => {
    const cols = COLUMNS.map(c => gantt.getGridColumn(c))?.filter(c => c)
    if (cols?.length) {
      projectGanttStore.setGridColumnList(cols)
    }
  }

  useEffect(() => {
    if (projectGanttStore.gridColumnList?.length) {
      projectGanttStore.gridColumnList.forEach(col => {
        const column = gantt.getGridColumn(col?.name)
        if (column) {
          column.hide = col?.hide
          gantt.render()
        } else {
          if (!column) return
          const uniqColumns = uniqBy(
            [...projectGanttStore.gridColumnList, col],
            'name'
          )
          gantt.config.columns = uniqColumns
          ///gantt.init(ganttContainer)
          gantt.render()
        }
      })
    }
  }, [projectGanttStore.gridColumnList])

  const contextMenuConfig = () => {
    const cmenu = new dhx.ContextMenu(null, { css: 'dhx_widget--bg_gray' })
    cmenu.data.parse([])

    gantt.attachEvent(
      'onContextMenu',
      function (taskId, linkId, event) {
        event.preventDefault()

        var target = event.target || event.srcElement
        const column_id =
          target.getAttribute('column_id') || cmenu.data.removeAll()
        cmenu.data.parse([])

        addColumnsConfig()
        if (column_id) {
          addColumnToggle(column_id)
        }

        cmenu.showAt(event)

        return false
      },
      { id: 'contextMenu' }
    )

    cmenu.events.on('click', function (id, e) {
      var parts = (id + '').split('#')
      var is_toggle = parts[0] == 'toggle',
        column_id = parts[1] || id

      var column = gantt.getGridColumn(column_id)

      if (column) {
        const visible = !column.hide
        column.hide = visible
        gantt.render()
        projectGanttStore.setGridColumnList(
          projectGanttStore.gridColumnList.map(col =>
            col.name === column.name ? { ...col, hide: visible } : col
          )
        )
      }
      return true
    })

    function addColumnToggle(column_name) {
      var column = gantt.getGridColumn(column_name)
      var label = getColumnLabel(column)

      //add prefix to distinguish from the same item in 'show columns' menu
      var item_id = 'toggle#' + column_name
      cmenu.data.add({
        id: item_id,
        value: "Hide '" + label + "'",
      })
    }

    function addColumnsConfig() {
      let newMenuItems = {
        value: 'Show columns:',
        items: [],
      }
      const columns = gantt.config.columns

      for (let i = 0; i < columns.length; i++) {
        const checked = !columns[i].hide ?? false,
          itemLabel = getColumnLabel(columns[i]),
          itemId = columns[i].name
        newMenuItems.items.push({
          id: itemId,
          html: `<input type="checkbox" name="${itemLabel}" ${
            checked ? 'checked' : ''
          }><span class='checkbox-babel'>${
            itemLabel === 'buttons' ? t('action') : itemLabel
          }</span></input>`,
        })
      }
      cmenu.data.add([newMenuItems])
    }

    function getColumnLabel(column) {
      if (column == null) return ''

      if (column.name === 'buttons') return column.name

      var locale = gantt.locale.labels
      var text =
        column.label !== undefined
          ? column.label
          : locale['column_' + column.name]

      text = text || column.name
      return text
    }

    return cmenu
  }

  const baselineConfig = () => {
    // adding baseline display
    gantt.addTaskLayer({
      id: 'baseline',
      renderer: {
        render: function draw_planned(task) {
          if (task.planned_start && task.planned_end) {
            var sizes = gantt.getTaskPosition(
              task,
              task.planned_start,
              task.planned_end
            )
            var el = document.createElement('div')
            el.className = 'baseline'
            el.style.left = sizes.left + 'px'
            el.style.width = sizes.width + 'px'
            el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px'
            return el
          }
          return false
        },
        // define getRectangle in order to hook layer with the smart rendering
        getRectangle: function (task, view) {
          if (task.planned_start && task.planned_end) {
            return gantt.getTaskPosition(
              task,
              task.planned_start,
              task.planned_end
            )
          }
          return null
        },
      },
    })

    gantt.templates.task_class = function (start, end, task) {
      if (task.planned_end) {
        var classes = ['has-baseline']
        if (end.getTime() > task.planned_end.getTime()) {
          classes.push('overdue')
        }
        return classes.join(' ')
      }
    }

    gantt.templates.rightside_text = function (start, end, task) {
      if (task.planned_end && projectGanttStore.isShowBaseline) {
        if (end.getTime() > task.planned_end.getTime()) {
          var overdue = Math.ceil(
            Math.abs(
              (end.getTime() - task.planned_end.getTime()) /
                (24 * 60 * 60 * 1000)
            )
          )
          var text = '<b>Overdue: ' + overdue + ' days</b>'
          return text
        }
      } else if (task.type == gantt.config.types.milestone) {
        return task.text
      }
      return ''
    }

    gantt.attachEvent(
      'onTaskLoading',
      function (task) {
        task.planned_start = gantt.date.parseDate(
          task.planned_start,
          'xml_date'
        )
        task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')
        return true
      },
      { id: 'taskLoading' }
    )
  }

  useEffect(() => {
    if (ganttReady) {
      if (projectGanttStore.isShowBaseline) {
        // adjust bar and row
        gantt.config.bar_height = 20
        gantt.config.row_height = 44
        baselineConfig()
      } else {
        gantt.removeTaskLayer('baseline')
        gantt.config.row_height = 35
        gantt.config.bar_height = 'full'

        if (projectGanttStore.isCondensed) {
          gantt.config.row_height = 24;
          gantt.config.scale_height = 50;
        }
      }
      gantt.render()
    }
  }, [projectGanttStore.isShowBaseline, projectGanttStore.isCondensed, ganttReady])

  function upload(fileProps, callback) {
    const { file, type } = fileProps
    const importMethod = type === 'ms_project' ? 'importFromMSProject' : 'importFromPrimaveraP6'
    gantt[importMethod]({
      data: file,
      // To load task types from the file
      taskProperties: ['Summary', 'Milestone'],
      callback: function (project) {
        if (project) {
          // if (project.config.duration_unit) {
          //   gantt.config.duration_unit = project.config.duration_unit
          // }
          const { data, links } = project.data
          const { _id } = projectGanttStore.projectGanttData[0]
          if (data && _id) {
            if(importMethod === 'importFromPrimaveraP6'){
              data.sort(function (task1, task2) {
                if (+task1.id && +task2.id) {
                  return +task1.id - +task2.id
                } else {
                  return task1.id.localeCompare(task2.id)
                }
              }) 
            }
            projectGanttStore
              .importProject({
                projectId: projectStore.projectDetail._id,
                ganttId: _id,
                ganttData: project.data,
              })
              .then(() => {
                message.success(t('import-project-data-success'))
                gantt.clearAll()
                const soonest = new Date(getStartSoonest(data))
                gantt.config.start_date = gantt.date.add(soonest, -1, 'day')

                const latest = new Date(
                  data.reduce(function (a, b) {
                    return new Date(a.$raw.Finish) > new Date(b.$raw.Finish)
                      ? a
                      : b
                  }).$raw.Finish
                )
                gantt.config.end_date = gantt.date.add(latest, 1, 'day')
                createTodayMarker()
                updateMarkerTime()
                projectGanttStore
                  .getProject4dGantt(projectStore.projectDetail.id)
                  .catch(() => {})
              })
              .catch(() => {
                message.warn(t('failed-to-import-project-data'))
              })
          }
        } else {
          message.warn(t('failed-to-import-project-data'))
        }

        if (callback) callback(project)
      },
    })
  }

  useEffect(() => {
    if (projectGanttStore.importFile) {
      upload(projectGanttStore.importFile, () => {
        projectGanttStore.setIsUploading(false)
        projectGanttStore.setImportFile(undefined)
        projectGanttStore.setIsOpenModalExportnImportProject({
          type: 'import',
          open: false,
        })
      })
    }
  }, [projectGanttStore.importFile])

  window.addEventListener('click', function (e) {
    if (document.getElementById('gantt_here') && !document.getElementById('gantt_here').contains(e.target)) {
      // close the inline editor and save the changes:
      //gantt.ext.inlineEditors.save()
      // close the inline editor and don't save the changes
      gantt.ext.inlineEditors.hide()
    }
  })

  useEffect(() => {
    if(!gantt) return
    if(!gantt.i18n.getLocale('vi')){
      gantt.i18n.addLocale("vi", vi);
    }
    gantt.i18n.setLocale(commonStore.language)
    gantt.render()
  }, [commonStore.language])
  
  const tooltipConfig = () => {
    gantt.templates.tooltip_text = function(start,end,event){
      return `<b>${t('Task')}:</b> ` + event.text + `<br/><b>${t('start-date')}:</b> ` + gantt.templates.tooltip_date_format(start) + `<br/><b>${t('end-date')}:</b> ` + gantt.templates.tooltip_date_format(end);
  };
  }
  useEffect(() => {
    if (ganttContainer && !ganttReady) {
      gantt.i18n.addLocale("vi", vi);
      gantt.i18n.setLocale(commonStore.language); 
      // gantt.config.layout = gridAndChart;
      gridColumnConfig()
      lightboxConfig()
      pluginsConfig()
      resizeRowConfig()
      weekendConfig()
      undoRedoConfig()
      contextMenuConfig()
      tooltipConfig()
      // additional config
      gantt.config.date_format = '%Y-%m-%d %H:%i'
      gantt.config.duration_unit = "hour";
      gantt.config.grid_elastic_columns = true;
      gantt.config.drag_timeline = projectGanttStore.playerMode === 'dragGantt' ? {
        ignore: ".gantt_task_line, .gantt_task_link",
        useKey: false
      } : null
      if (tasks.data.length) {
        const soonest = new Date(getStartSoonest(tasks.data))
        const latest = new Date(getEndDateLatest(tasks.data))
        gantt.config.start_date = gantt.date.add(soonest, -365, 'day')
        gantt.config.end_date = gantt.date.add(latest, 365, 'day')
        gantt.config.show_tasks_outside_timescale = true;
      }

      // reordering tasks within the whole gantt
      gantt.config.order_branch = 'marker' // branch ordering
      gantt.config.order_branch_free = true

      gantt.config.reorder_grid_columns = true
      gantt.config.sort = true // grid sorting
      gantt.config.wide_form = false
      gantt.config.autofit = true
      gantt.config.grid_width = 500
      gantt.config.grid_resize = true
      gantt.config.fit_tasks = true
      gantt.config.resize_rows = true // resize rows
      gantt.config.work_time = true

      gantt.config.undo = true
      gantt.config.redo = true
      gantt.config.show_empty_state = true
      gantt.config.touch = true
      //gantt.config.static_background = true
      gantt.config.smart_rendering = true
      gantt.config.auto_types = true

      if (!checkingFeatureRole('feature_4d_gantt_edit')) {
        gantt.config.readonly = true
        projectGanttStore.setIsReadonlyMode(true)
      } else {
        gantt.config.readonly = false
        projectGanttStore.setIsReadonlyMode(false)
      }
      //
      // create instance of gantt

      gantt.init(ganttContainer)
      setGanttReady(true)

      initGanttDataProcessor()
      gantt.parse(tasks)

      //
      handleGetColumn()
      createTodayMarker()

      // add resize event
      getBorderOfGridToResize()
      dragExtendTimeline()
      intervalID = requestAnimationFrame(getMarkerToDragAndDrop)
      intervalID2 = requestAnimationFrame(handleCalculateDragGantt)
    }
  }, [ganttReady])

  useEffect(() => {
    return () => {
      if (dataProcessor) {
        dataProcessor.destructor()
        dataProcessor = null
        gantt.clearAll()
        //gantt.detachAllEvents()
        gantt.detachEvent('rowResize')
        gantt.detachEvent('afterRowResize')
        gantt.detachEvent('mouseMove')
        gantt.detachEvent('openLightbox')
        gantt.detachEvent('cancelLightbox')
        gantt.detachEvent('afterLightbox')
        gantt.detachEvent('beforeLightbox')
        gantt.detachEvent('afterZoom')
        gantt.detachEvent('ganttRender')
        gantt.detachEvent('beforeParse')
        gantt.detachEvent('onParse')
        gantt.detachEvent('taskDblClick')
        gantt.detachEvent('lightboxSave')
        gantt.detachEvent('lightboxDelete')
        gantt.detachEvent('contextMenu')
        gantt.detachEvent('taskLoading')
        gantt.detachEvent('onBeforeTaskDisplay')
        gantt.detachEvent('onTaskSelected')
        gantt.detachEvent('onBeforeParse')
      }
      previousUndoStack = []
      previousRedoStack = []
      cachedSettings = {}
      lastMiddlePosition = 0
      //projectGanttStore.setListHighlightSketch([])
      if (viewer) {
        onComponentDidMount(viewer)
        onClearAlignmentCachingObject(viewer)
      }
      cancelAnimationFrame(intervalID)
      cancelAnimationFrame(intervalID2)
    }
  }, [projectGanttStore.isShowGanttPanel])

  useEffect(() => {
    if (ganttContainer && ganttReady) {
      const undoStack = gantt.getUndoStack()
      if (undoStack?.length > 0) projectGanttStore.setIsActiveUndo(true)
      else projectGanttStore.setIsActiveUndo(false)

      const redoStack = gantt.getRedoStack()
      if (redoStack?.length > 0) projectGanttStore.setIsActiveRedo(true)
      else projectGanttStore.setIsActiveRedo(false)
      gantt.parse(tasks)

      const isNewTask = projectGanttStore.sketchDrawer.isNewTask;
      const taskId = projectGanttStore.sketchDrawer.task?.id;
      const sketchId = projectGanttStore.sketchDrawer.sketchId;

      if (projectGanttStore.sketchDrawer.open) {
        if (isNewTask) {
          if (sketchId) {
            projectGanttStore.selectedNodeInDataTree.push(sketchId)
          }
          gantt.createTask(projectGanttStore.sketchDrawer.task);
        } else {
          gantt.showLightbox(taskId);
        }
        // after open lighbox then reset sketch drawer
        projectGanttStore.setSketchDrawer({task: '', isNewTask: true, sketchId: '', open: false, selectedTask: false, drawSketchType: ''})
      }
    }
  }, [ganttReady, tasks])

  useEffect(() => {
    if (zoom.status && zoom.isUpdate) {
      setZoom(zoom.status)
    }
  }, [zoom])

  useEffect(() => {
    if (ganttContainer) {
      zoomToFit.isUpdate && toggleMode(zoomToFit.status)
    }
  }, [ganttContainer, zoomToFit])

  useEffect(() => {
    gantt.config.highlight_critical_path = projectGanttStore.isShowCriticalPath
    gantt.render()
  }, [projectGanttStore.isShowCriticalPath])

  useEffect(() => {
    gantt.config.auto_scheduling = projectGanttStore.isAutoScheduling
    gantt.config.auto_scheduling_strict = projectGanttStore.isAutoScheduling
    gantt.config.auto_scheduling_compatibility =
      projectGanttStore.isAutoScheduling
    gantt.render()
  }, [projectGanttStore.isAutoScheduling])

  useEffect(() => {
    if (projectGanttStore.undoCount !== 0) {
      gantt.ext.undo.undo()
    }
  }, [projectGanttStore.undoCount])

  useEffect(() => {
    if (projectGanttStore.redoCount !== 0) {
      gantt.ext.undo.redo()
    }
  }, [projectGanttStore.redoCount])

  // effect click draw sketch from toolbar gantt
  useEffect(() => {
    if (projectGanttStore.sketchDrawer.drawSketchType) {      
      const taskId = gantt.getSelectedId();
      if(!taskId) {
        Modal.warning({
          title: t('please-select-task-first'),
          content: '',
        });
        projectGanttStore.setSketchDrawer({task: '', isNewTask: false, sketchId: '', open: false, drawSketchType: ''})
      } else {
        // hide drawer gantt
        projectGanttStore.setIsShowGanttPanel(false)

        let cTask = gantt.getTask(taskId)        
        projectGanttStore.setSketchDrawer({...projectGanttStore.sketchDrawer, task: cTask})

        // show sketch point
        projectStore.setCurrentHelpfeature('add_sketch')
        sketchingStore.setCurrentSketchId(false)
        sketchingStore.setSketchToolVisible(false)
        sketchingStore.setDrawMode(true)
        if (projectGanttStore.sketchDrawer.drawSketchType === 'single') {
          sketchingStore.setSketchMode('point')
          sketchingStore.setSketchProps({ extrudedHeight: 1 })
          sketchingStore.setSketchProps({ width: 1 })
          sketchingStore.setSketchProps({ rotation: 0 })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'point')
          })
        }

        if (projectGanttStore.sketchDrawer.drawSketchType === 'line') {
          sketchingStore.setSketchMode('line')
          sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
          sketchingStore.setSketchProps({ width: 0.5 })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'line')
          })          
        }

        if (projectGanttStore.sketchDrawer.drawSketchType === 'area') {
          sketchingStore.setSketchMode('area')
          sketchingStore.setSketchProps({ height: 0 })
          sketchingStore.setSketchProps({ extrudedHeight: 0 })
          sketchingStore.setSketchProps({ width: 0 })
          sketchingStore.setSketchProps({ readonlyHeight: true })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area')
          })          
        }

        if (projectGanttStore.sketchDrawer.drawSketchType === 'volume') {
          sketchingStore.setSketchMode('area')
          sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
          sketchingStore.setSketchProps({ width: 0 })
          sketchingStore.setSketchProps({ readonlyHeight: false })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area', true)
          })          
        }       
      }
    }
  }, [projectGanttStore.sketchDrawer.drawSketchType])

  useEffect(() => {
    return () => {
      cancelAnimationFrame(intervalID)
      cancelAnimationFrame(intervalID2)
    }
  }, [])

  const handleMouseDown = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback(e => {
    let offsetRight = e.clientX - document.body.offsetLeft - 24
    let minDrawerWidth = 345
    if (offsetRight > minDrawerWidth) {
      gantt.config.grid_width = offsetRight
      gantt.render()
    }
    if (offsetRight > window.innerWidth) {
      gantt.config.grid_width = window.innerWidth
      gantt.render()
    }
  }, [])

  const getBorderOfGridToResize = () => {
    const gridWrapper = document.getElementsByClassName(
      'gantt_layout_cell_border_right'
    )
    if (!gridWrapper[1]) return

    let resizeComponent = document.createElement('div')
    resizeComponent.className =
      'gantt_layout_cell gantt_resizer gantt_resizer_x gantt_layout_cell_border_right'
    resizeComponent.style.height = '100%'
    let resizeChild = document.createElement('div')
    resizeChild.className =
      'gantt_layout_content gantt_resizer_x gantt_grid_resize_wrap'
    resizeComponent.appendChild(resizeChild)

    resizeComponent.addEventListener('mousedown', handleMouseDown)

    gridWrapper[1].insertAdjacentElement('afterend', resizeComponent)
  }

  const handleMouseDownToDragMarker = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUpToDragMarker, true)
    document.addEventListener('mousemove', handleMouseMoveToDragMarker, true)
    setIsDragging(true)
  }

  const handleMouseUpToDragMarker = () => {
    document.removeEventListener('mouseup', handleMouseUpToDragMarker, true)
    document.removeEventListener('mousemove', handleMouseMoveToDragMarker, true)
    setIsDragging(false)
  }

  function getDateShow() {
    return dateShow
  }

  const getDistanceBetweenElements = (xPos, next, previous) => {
    let distanceToPrevious, distanceToNext
    if (previous) {
      const previousRect = previous.getBoundingClientRect()
      const previousPosition = previousRect.left + previousRect.width / 2
      distanceToPrevious = xPos - previousPosition
    }
    if (next) {
      const nextRect = next.getBoundingClientRect()
      const nextPosition = nextRect.left + nextRect.width / 2
      distanceToNext = xPos - nextPosition
    }

    const keyFilter = getCurrentZoomLevel()

    if (distanceToNext > 0) {
      getDateShow().updateDateShow(1, keyFilter)
      updateMarkerTime(true)
      return
    }
    if (distanceToPrevious < 0) {
      getDateShow().updateDateShow(-1, keyFilter)
      updateMarkerTime(true)
    }
  }

  const handleMouseMoveToDragMarker = useCallback(
    e => {
      const nextMarkerDom = document.getElementsByClassName('next')?.[0]
      const previousMarkerDom = document.getElementsByClassName('previous')?.[0]
      const mouseX = e.clientX
      getDistanceBetweenElements(mouseX, nextMarkerDom, previousMarkerDom)
    },
    [getDateShow()]
  )

  const getMarkerToDragAndDrop = () => {
    const markers = document.getElementsByClassName('now')
    if (!markers[0]) {
      intervalID = requestAnimationFrame(getMarkerToDragAndDrop)
      return
    }
    if (!markers[0]?.getEventListeners()?.mousedown) {
      markers[0].addEventListener(
        'mousedown',
        handleMouseDownToDragMarker,
        true
      )
    }
    intervalID = requestAnimationFrame(getMarkerToDragAndDrop)
    // if(isMobile || isTablet){
    //   if (!markers[0]?.getEventListeners()?.touchstart) {
    //     markers[0].addEventListener(
    //       'touchstart',
    //       handleTouchStartToDragMarker
    //     )
    //   }
    // }
  }

  const handleCalculateDragGantt = () => {
    if (!isPaused) {
      const newMiddlePosition = calculateMiddlePosition()

      if (newMiddlePosition !== lastMiddlePosition) {
        const handleDebounceCalculateMiddleDate = debounce(() => {
          calculateMiddleDate()
        }, 300)
        handleDebounceCalculateMiddleDate()
        lastMiddlePosition = newMiddlePosition // Cập nhật vị trí cuối cùng
      }
    }
    intervalID2 = requestAnimationFrame(handleCalculateDragGantt)
  }

  const getCurrentZoomLevel = () => {
    let key = ''
    const listDomLabel = document.getElementsByClassName('lable-zoom-gantt')
    if (listDomLabel.length > 0) {
      const arrayDomLabel = Array.from(listDomLabel)
      arrayDomLabel.forEach(item => {
        const textContent = removeDuplicateText(item?.textContent)
        if (DATE_ZOOMINGS.includes(textContent)) {
          key = textContent
        }
      })
    }
    return key
  }

  const handleSetTime = value => {
    projectGanttStore.setCurrentViewingTime(value)
  }

  const debounceCalculate = debounce(function (value) {
    handleSetTime(value)
  }, 200)

  const updateMarkerTime = isDragging => {
    const keyFilter = getCurrentZoomLevel()
    const now = dateShow.getNowTime()
    const next = dateShow.getNextTime(now, keyFilter)
    const previous = dateShow.getPreviousTime(now, keyFilter)

    markerConfig(now, 'now')
    isDragging && debounceCalculate(now)
    markerConfig(previous, 'previous')
    markerConfig(next, 'next')
  }

  return (
    <GanttContainer>
      <div
        id="gantt_here"
        ref={input => {
          ganttContainer = input
        }}
        style={{ width: '100%', height: 'calc(100vh - 145px)' }}></div>
    </GanttContainer>
  )
}

export default inject(
  'projectGanttStore',
  'projectStore',
  'sketchingStore',
  'adminStore',
  'objectQueryStore',
  'commonStore',
)(observer(Gantt))
