import uuid from 'uuid'
import { cloneDeep } from 'lodash'
import { toJS } from 'mobx';
const META_DATA_TYPES = {
  IFC_PROPERTIES: 10,
  IFC_HEADER_INFO: 13,
  IFC_SHOW_TREE: 7,
  LANDXML_ATTRIBUTES: 5
}
const TreeUtils = {
  searchTreeNode(data, key, match) {
    if (!data?.length) return;
    const stack = [];
    for (let i = 0; i < data.length; i++) {
      stack.push(data[i]);
    }
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node;
      } else if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          stack.push(node.children[i]);
        }
      }
    }
    return null;
  },
  searchParentTreeNode(data, key, match) {
    const stack = [];
    data.map(item => {
      item.parent = data
      stack.push(item)
    });
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node.parent;
      } else if (node.children) {
        node.children.map(child => {
          child.parent = node
          stack.push(child)
        })
      }
    }
    return null;
  },
  searchTreeNodeForParents(data, key, match) {
    const nodes = []
    let node = TreeUtils.searchTreeNodeForParent(data, key, match)
    while (node) {
      nodes.push({
        title: node.title,
        GUID: node.GUID,
        key: node.key
      })
      node = node.parrent
    }
    return nodes
  },
  searchTreeNodeForParent(data, key, match) {
    const stack = [];

    function compareArr(n = [], k, d) {
      n.forEach((v, i) => {
        if (v[k] === d) {
          return n;
        }
      });
    }
    data && data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node.parrent;
      }
      if (node.children && node.children.length) {
        if (compareArr(node.children, key, match)) {
          return node;
        }
        else {
          node.children.forEach(child => {
            child.parrent = node
            stack.push(child)
          })
        }
      }
    }
    return null;
  },
  updateTreeData(data, key, children) {
    return data.map((item) => {
      if (item.key === key) {
        return { ...item, children };
      } else if (item.children) {
        return { ...item, children: TreeUtils.updateTreeData(item.children, key, children) };
      }
      return item;
    });
  },
  searchSketchFolder(data) {
    const stack = [];
    data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.pop();
      if (node.title === 'Sketch' && node.type === 'FOLDER') {
        return node;
      } else if (node.children) {
        node.children.map(child => stack.push(child))
      }
    }
    return null;
  },
  updateTreeDataByModelId(data, modelId, children) {
    return data.map((item) => {
      if (item.modelId === modelId) {
        return children;
      } else if (item.children) {
        const updatedChildren = TreeUtils.updateTreeDataByModelId(item.children, modelId, children);
        return { ...item, children: updatedChildren };
      }
      return item;
    });
  },
  fetchAllChildAttr(data) {
    data.map(item => {
      if (item.children) {
        const newChildren = [];
        item.children.map(child => {
          if (child.type === META_DATA_TYPES.LANDXML_ATTRIBUTES) {
            if (child.children) {
              child.children.map(gchild => {
                newChildren.push(gchild);
              })
            }
          } else {
            newChildren.push(child);
          }
        })
        item.children = newChildren;
        TreeUtils.fetchAllChildAttr(item.children);
      }

    })
    return data;
  },
  shortenTree(modelType, data, attrData) {
    if (modelType === 'landxml') {
      data.map(item => {
        if (item.children) {
          const attributes = item.children.find(child => child.type === META_DATA_TYPES.LANDXML_ATTRIBUTES);
          const newChildren = item.children.filter(child => child.type !== META_DATA_TYPES.LANDXML_ATTRIBUTES);
          const childAttr = TreeUtils.fetchAllChildAttr(cloneDeep(newChildren));
          if (attributes && attributes.children && childAttr?.length) {
            attributes.children = [...attributes.children, ...childAttr];
          } else if (attributes && childAttr?.length) {
            attributes.children = childAttr;
          }
          if (item.GUID) {
            attrData.push({
              ...attributes,
              pKey: item.key,
              pTitle: item.title,
              GUID: item.GUID
            });
          }
          item.children = newChildren;
          item.isLeaf = !newChildren.length
          TreeUtils.shortenTree(modelType, item.children, attrData)
        }
      })
    } else if (modelType === 'ifc' || modelType === 'cad') {
      data.map(item => {
        const properties = item.children.find(child => child.type === META_DATA_TYPES.IFC_PROPERTIES);
        if (properties && properties.children) {
          properties.children.unshift({
            key: properties.GUID,
            title: `GlobalID = ${properties.GUID || ''}`,
          })
        } else if (properties) {
          properties.children = [{
            key: properties.GUID,
            title: `GlobalID = ${properties.GUID || ''}`,
          }]
        }
        if (item?.children[0]?.type === META_DATA_TYPES.IFC_HEADER_INFO) {
          if (item.GUID) {
            attrData.push({
              ...item,
              pKey: item.key,
              pTitle: item.title
            });
          }
        } else {
          if (properties.GUID) {
            attrData.push({
              ...properties,
              pKey: item.key,
              pTitle: item.title
            });
          }
        }
        if (item.children) {
          const newChildren = [];
          item.children.map(child => {
            if (child.type === META_DATA_TYPES.IFC_SHOW_TREE) {
              newChildren.push(child);
            } else {
              if (child.children) {
                child.children.map(gchild => {
                  if (gchild.type === META_DATA_TYPES.IFC_SHOW_TREE) {
                    newChildren.push(gchild);
                  }
                })
              }
            }
          })
          item.children = newChildren;
          item.isLeaf = !newChildren.length
          TreeUtils.shortenTree(modelType, item.children, attrData)
        }
      })
    }
    return {
      data,
      attrData
    };
  },
  setTitleRoot(type) {
    switch (type) {
      case 5:
        return "Scenario 1"
      case 8:
        return "Not referenced"
      case 9:
        return "IfcProject"
      case 13:
        return "Header Info"
      case 7:
        return "Space Boundaries"
      default:
        return ""
    }
  },
  traversalOTree(data) {
    return data.map(item => {
      const key = uuid()
      if (item.children) {
        return {
          ...item,
          title: item.key ? item.key : TreeUtils.setTitleRoot(item.children[0].type),
          key: item.node ? item.node : key,
          children:
            TreeUtils.traversalOTree(item.children, key),
        };
      }
      return {
        ...item,
        title: item.key,
        key: item.node ? item.node : key,
      };
    });
  },
  /**
   * Get all node has isDeleted = false or undefined
   * @param {*} array root of tree
   * @returns Get all node has isDeleted = false or undefined
   */
  filterTreeDataActive(array) {
    if (!array) return []
    return array.reduce((r, { children = [], ...o }) => {
      if (o && !o.isDeleted) {
        children = children.filter(c => c)
        r.push(o)
        children = this.filterTreeDataActive(children)
        if (children && children.length) {
          Object.assign(o, { children })
        }
      }
      return r
    }, [])
  },
  filterTreeFolders(array) {
    if (!array) return [];
    return array.reduce((result, { children = [], ...node }) => {
      if (node.type === 'FOLDER') {
        const filteredChildren = this.filterTreeFolders(children);
        result.push({ ...node, children: filteredChildren });
      }
      return result;
    }, []);
  },
  findAllNode(child) {
    const result = []
    for (let i = 0; i < child.length; i++) {
      const node = child[i];
      if (node.sourceType === "WMS" || node.modelType === "geotiff")
        result.push(node)
      if (node.children) for (const unit of this.findAllNode(node.children)) {
        result.push(unit)
      }
    }
    return result
  },
  findAllProjectLinkNode(child) {
    const result = []
    for (let i = 0; i < child.length; i++) {
      const node = child[i];
      if (node.sourceType === "WMS" || node.type === "geotiff")
        result.push(toJS({
          ...node,
          modelId: node.id
        }))
    }
    return result
  }
}

export default TreeUtils
