import { Checkbox, Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import { HeaderMenu } from '../styled'
import { useTranslation } from 'react-i18next'

const GridColumnMenu = ({ projectGanttStore }) => {
  const { t } = useTranslation()
  const { gridColumnList, setGridColumnList } = projectGanttStore
  const onChange = (checked, name) => {
    if (!gridColumnList?.length) return
    const normalizedColumnArray = gridColumnList.filter(col => col)
    setGridColumnList(
      normalizedColumnArray.map(col =>
        col.name === name ? { ...col, hide: !checked } : col
      )
    )
  }

  return (
    <>
      <Menu style={{ width: 256 }} mode="vertical">
        {/* <Menu.ItemGroup> */}
          {gridColumnList?.length > 0 &&
            gridColumnList?.map(col => (
              <Menu.Item key={col.name}>
                <Checkbox
                  checked={!col?.hide}
                  onChange={e => onChange(e.target.checked, col?.name)}>
                  {col?.name === 'buttons' ? t('action') : col?.label}
                </Checkbox>
              </Menu.Item>
            ))}
        {/* </Menu.ItemGroup> */}
      </Menu>
    </>
  )
}

export default inject('projectGanttStore')(observer(GridColumnMenu))
