/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Tree, Empty, message, Popconfirm, Tooltip, Spin, Pagination, Avatar, notification, Button } from 'antd'
import {
  DownOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SyncOutlined
} from '@ant-design/icons'
import { LeftPanelActions, TreeNodeTitle, TreeDataFolder } from './CustomStyled'
import { toJS } from 'mobx'
import { cloneDeep, map } from 'lodash'
import ModalAddFolder from './ModalAddFolder'
import ModalEditFile from './ModalEditFile'
import ModalEditAccessControl from './ModalEditAccessControl'
import TreeUtils from '../../../../tree-utils'
import GroupUtils from '../../../../group-utils'
import { ModelAttributeRequest } from '../../../../requests'
import { addTreeNode, checkChangeWMSOrder, checkOldViewPoint, saveCurrentViewpoint, waitUntilIonReadyData } from './../../../../lib/projectLib'
import uuid from 'uuid'
import SVGIcon from '../../../elements/SVGIcon'
import { ReactComponent as DataAddIcon } from '../../../../assets/svgs/data-add.svg'
import { ReactComponent as DataUpdateIcon } from '../../../../assets/svgs/data-update.svg'
import { ReactComponent as EditIcon } from '../../../../assets/svgs/edit.svg'
import { ReactComponent as RenameIcon } from '../../../../assets/svgs/rename.svg'
import { ReactComponent as EyeIcon } from '../../../../assets/svgs/eye.svg'
import { ReactComponent as EyeInvisibleIcon } from '../../../../assets/svgs/eye-invisible.svg'
import { ReactComponent as ZoomIcon } from '../../../../assets/svgs/zoom.svg'
import { ReactComponent as InfoIcon } from '../../../../assets/svgs/info.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/svgs/delete.svg'
import { ReactComponent as DataAddFolderIcon } from '../../../../assets/svgs/data-add-folder.svg'
import { ReactComponent as ResourceIfcIcon } from '../../../../assets/svgs/resource-ifc-v2.svg'
import { ReactComponent as ResourceXmlIcon } from '../../../../assets/svgs/resource-xml-v2.svg'
import { ReactComponent as ResourceWmsIcon } from '../../../../assets/svgs/resource-wms.svg'
import { ReactComponent as Resource3DIcon } from '../../../../assets/svgs/resource-3d-v2.svg'
import { ReactComponent as Resource3DTilesIcon } from '../../../../assets/svgs/resource-3dtiles.svg'
import { ReactComponent as ResourceFolderFoldIcon } from '../../../../assets/svgs/resource-folder-fold.svg'
import { ReactComponent as ResourceFeedbackIcon } from '../../../../assets/svgs/resource-feedback-v2.svg'
import { ReactComponent as ResourceFeedbackFolderIcon } from '../../../../assets/svgs/resource-feedback-folder-v2.svg'
import { ReactComponent as ResourcePointCloudIcon } from '../../../../assets/svgs/resource-point-cloud.svg'
import { ReactComponent as ResourceObjectIcon } from '../../../../assets/svgs/resource-object.svg'
import { ReactComponent as VisibilityOnIcon } from '../../../../assets/svgs/visibility-on.svg'
import { ReactComponent as VisibilityOffIcon } from '../../../../assets/svgs/visibility-off.svg'
import { ReactComponent as VolumeIcon } from '../../../../assets/svgs/volume.svg'
import { ReactComponent as LineIcon } from '../../../../assets/svgs/line.svg'
import { ReactComponent as AreaIcon } from '../../../../assets/svgs/area.svg'
import { ReactComponent as SingleIcon } from '../../../../assets/svgs/single.svg'
import { ReactComponent as Mesh3DIcon } from '../../../../assets/svgs/3Dmesh.svg'
import { ReactComponent as ResourceNewFolderIcon } from '../../../../assets/svgs/resourcefolder-b0.svg'
import { ReactComponent as Icon4DOFF } from '../../../../assets/svgs/4D-OFF.svg'
import { ReactComponent as Icon4DON } from '../../../../assets/svgs/4D-ON.svg'
import { ReactComponent as ClipOn } from '../../../../assets/svgs/Clip-plane-ON.svg'
import { ReactComponent as ClipOff } from '../../../../assets/svgs/Clip-plane-OFF.svg'
import { ReactComponent as AccessControl } from '../../../../assets/svgs/Access-control.svg'
import { ReactComponent as RasterImageryIcon } from '../../../../assets/svgs/geotiff.svg'
import { ReactComponent as VectorGISIcon } from '../../../../assets/svgs/VectorGIS.svg'
import { ReactComponent as DownLoadModel } from '../../../../assets/svgs/datatree-download.svg'

import DocFile from '../../../../assets/form-elems/Resource DOC.png'
import JPGFile from '../../../../assets/form-elems/Resource JPG.png'
import PDFFile from '../../../../assets/form-elems/Resource PDF.png'
import PNGFile from '../../../../assets/form-elems/Resource PNG.png'
import PPTFile from '../../../../assets/form-elems/Resource PPT.png'
import TIFFile from '../../../../assets/form-elems/Resource TIFF.png'
import TXTFile from '../../../../assets/form-elems/Resource TXT.png'
import XLSFile from '../../../../assets/form-elems/Resource XLS.png'
import HTTPlinks from '../../../../assets/form-elems/Resource Link.png'
import GenericFile from '../../../../assets/form-elems/generic-doc.png'

import { ReactComponent as DWG } from '../../../../assets/svgs/dwg.svg'
import { ReactComponent as PDF } from '../../../../assets/svgs/pdf.svg'
import { ReactComponent as DXF } from '../../../../assets/svgs/dxf.svg'
import { ReactComponent as IMG } from '../../../../assets/svgs/img.svg'
import { ReactComponent as GENERIC } from '../../../../assets/svgs/generic.svg'
import { ReactComponent as DOWNLOAD } from '../../../../assets/svgs/download.svg'
import { isMobile, isTablet } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import { renderHTML } from '../../../helper/CesiumUtils'
import HelpButton from '../../../elements/HelpButton'
import Utils from '../../../../utils'

const DataTreePanel = ({ projectStore, uiStore, sketchingStore, feedbackStore, topicStore, usersStore, adminStore, fileStore, schedulingStore, modelStore, userGroupStore,projectTeamsStore ,commonStore, viewer ,getCurrent3DViewSetting }) => {
  const { t } = useTranslation();
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [treeLoading, setTreeLoading] = useState(false);
  const [loadedKeys, setLoadedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [treeDataFeedback, setTreeDataFeedback] = useState([]);
  const [treeDataTopic, setTreeDataTopic] = useState([])
  const [topicType, setTopicType] = useState()
  const [selectNodeData, setSelectNodeData] = useState({})
  const [exactKey, setExactKey] = useState()
  const [listModelRebuild, setListModelRebuild] = useState([])
  const [isNotModel, setIsNotModel] = useState([])
  const [isVisibleFeedback, setIsVisibleFeedback] = useState([])
  const [isVisibleTopics, setIsVisibleTopics] = useState([])
  const [idFeedback] = useState(uuid())
  const [idFeedbackForms] = useState(uuid())
  const [idFeedbackAnswer] = useState(uuid())
  const [idTopicTree] = useState(uuid())
  const [idIconFeedback] = useState(uuid())
  const [onCheckFeedback, setOnCheckFeedback] = useState(true)
  const [onLoadTopicTree, setonLoadTopicTree] = useState(true)
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [isVisibleAllTopic, setIsVisibleAllTopic] = useState(false)
  const [treeDataProjectLink, setTreeDataProjectLink] = useState([]);
  const { projectId } = useParams();
  const [isDownLoadingModel, setIsDownLoadingModel] = useState(false);

  const onSelectNodeToShowBVolume = (node, reset=false) =>{
    if(reset){
      projectStore.setCurrentModelId();
      return;
    }
    if(!node || !projectStore.showBoudingVolume) return;
    if(isModel(node) && !isTerrain(node)) {
      projectStore.setCurrentModelId(node?.modelId);
    }
  }

  useEffect(() => {
    if(projectStore.selectedNode){
      onSelectNodeToShowBVolume(projectStore.selectedNode)
    }else onSelectNodeToShowBVolume('', true);
  }, [projectStore.selectedNode])
  

  // Function Tree
  const onSelect = async (selectedKeys, info) => {
    const { node: { type } } = info;
    if (type !== 'loadmore') {
      setCheckedKeys([])
      setCheckedNodes([])
      setSelectedKeys(selectedKeys);
      if (selectedKeys.length) {
        projectStore.setSelectedNode(info.node);
        userGroupStore.setTreeNodeEdit(info.node)
        if (info.node.type === 'FOLDER') {
          projectStore.setIsSelectedNode(true)
        }
        if (isFeedback(projectStore.selectedNode)) {
          clickFeedbackNode(projectStore.selectedNode)
        }
      } else {
        if (info.node?.key === projectStore?.selectedNode?.key) {
          userGroupStore.setTreeNodeEdit(info.node)
        }
        projectStore.setSelectedNode();
        uiStore.setShowAttrPanel(false);
        // unselected node feedback reset selected to false
        feedbackStore.setVisiblFeedback(
          feedbackStore.visibleFeedback.map(item => {
            item.selected = false
            return item
          })
        )
      }
    }
  }
  useEffect(() => {
    if (!projectStore.showAddFolderModal && projectStore.selectedNode && projectStore.selectedNode.type === 'FOLDER') {
      setCheckedKeys([])
      setCheckedNodes([])
      setSelectedKeys([projectStore.selectedNode.key]);
      if (projectStore.selectedNode.type === 'FOLDER') {
        projectStore.setIsSelectedNode(true)
      }
      if (isFeedback(projectStore.selectedNode)) {
        projectStore.setFolderEdit(projectStore.selectedNode)
      }
    }
  }, [projectStore.showAddFolderModal])

  useEffect(() => {
    if (!projectStore.selectedNode) {
      setCheckedKeys([])
      setCheckedNodes([])
      setSelectedKeys([]);
    }
  }, [projectStore.selectedNode])

  useEffect(() => {
    let data
    if (projectStore?.typeViewPoint === 'viewpoint') {
      data = projectStore.currentViewpoint
    } else if (projectStore?.typeViewPoint === 'topic3DView') {
      data = topicStore.currentTopic3DView
    } else if (projectStore?.typeViewPoint === 'topic') {
      data = topicStore.viewingTopicForm
    }
    const isBefore = checkOldViewPoint(data?.updatedAt || data?.createdAt);

    if (data?.treeData) {
      let visibleTilesets = Array.isArray(data.treeData) ? data.treeData : data.treeData?.visibleTilesets
      let visible4DModels = Array.isArray(data.treeData) ? data.treeData : (data.treeData?.visible4DModels || [])
      let visibleClipModels = data.treeData && data.treeData?.visibleClipModels ? data.treeData?.visibleClipModels : []
      let tempModel3D = toJS(projectStore.modelList).map(item => {
        return { modelId: item._id, isVisible: isBefore ? true : false, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, data: item.data, isVisible4D: true, endDate: item.endDate, startDate: item.startDate, isVisibleClip: true, }
      })
      if ((visibleTilesets && visibleTilesets.length > 0) || (visible4DModels && visible4DModels.length > 0 || (visibleClipModels && visibleClipModels.length > 0))) {
        const _visibletileset = tempModel3D.map(function (newData) {
          let isExist = visibleTilesets.find(c => c.modelId === newData.modelId)
          let isExist4D = visible4DModels.find(c => c.type4D === 'model' && c.modelId === newData.modelId)
          let isExistClip = visibleClipModels.find(c => c.modelId === newData.modelId)
          if (isExist) {
            newData.isVisible = isExist.isVisible
            newData.isTempHidden = isExist.isTempHidden
          }
          if (isExist4D) {
            newData.isVisible4D = false
          }
          if (isExistClip) {
            newData.isVisibleClip = false
          }
          return newData
        });
        projectStore.setVisibleTilesets(_visibletileset)
      } else if ((visibleTilesets && visibleTilesets.length === 0) && (visible4DModels && visible4DModels.length === 0) && (visibleClipModels && visibleClipModels.length === 0)) {
        projectStore.setVisibleTilesets(tempModel3D)
      }

      //sketch
      let tempSketch = toJS(sketchingStore.arrSketches).map(item => {
        return { sketchId: item._id, isVisible: isBefore ? true : false, isVisible4D: true, endDate: item.endDate, startDate: item.startDate }
      })
      if ((data.treeData?.visibleSketches && data.treeData?.visibleSketches?.length > 0) || (visible4DModels && visible4DModels.length > 0)) {
        let _visibleSketches = data?.treeData?.visibleSketches || []
        const _visiblesketch = tempSketch.map(function (newData) {
          let isExist = _visibleSketches.find(c => c.sketchId === newData.sketchId)
          let isExist4D = visible4DModels.find(c => c.type4D === 'sketch' && c.sketchId === newData.sketchId)
          if (isExist) {
            newData.isVisible = isExist.isVisible
            newData.isTempHidden = isExist.isTempHidden
          }
          if (isExist4D) {
            newData.isVisible4D = false
          }
          return newData
        });
        sketchingStore.setVisibleSketches(_visiblesketch)
      } else if ((data.treeData?.visibleSketches && data.treeData?.visibleSketches?.length === 0) || (visible4DModels && visible4DModels.length === 0)) {
        sketchingStore.setVisibleSketches(tempSketch)
      }
      //topics
      let arrType = []
      let topicData = topicStore.topicList
      if (topicData.length) {
        topicData.map(topic => {
          let find = arrType.find(c => c.topictype === topic.topictype)
          if (!find) {
            arrType.push(toJS(topic))
          }
        })
        let topicNoType = topicData.find(c => !c.topictype)
        if (topicNoType) {
          arrType.push({ topictype: 'No type' })
        }
        let tempTopic = arrType.filter(topic => topic.topictype).map(item => {
          return { type: 'topic', isShow: isBefore ? true : false, isLeaf: true, title: item.topictype, controlName: item.topictype }
        })
        if (data.treeData.visibleTopic && data.treeData.visibleTopic.length > 0) {
          let hiddenData = data.treeData.visibleTopic
          const _visiblestopic = tempTopic.map(function (newData) {
            let isExist = hiddenData.find(c => c.controlName === newData.controlName)
            if (isExist) {
              newData.isShow = isExist.isShow
            }
            return newData
          });
          topicStore.setVisibleTopic(_visiblestopic)
        } else {
          topicStore.setVisibleTopic(tempTopic)
        }
      }
      //feedback
      let tempFeedback = []
      if (feedbackStore.feedbackFormDetail) {
        let layerGenericFeedback = feedbackStore.feedbackVisualization?.map(item => toJS(item.formControlData.elements))
        let arr2 = [...new Set(layerGenericFeedback.flat(1))];

        const feedbackForms = {}
        for (let i = 0; i < arr2.length; i++) {
          const item = arr2[i];
          if (!feedbackForms[item.name]) {
            feedbackForms[item.name] = []
          }
          feedbackForms[item.name].push(item)
        }

        for (const key in feedbackForms) {
          const GenericFeedbacks = feedbackForms[key];
          const el = GenericFeedbacks[0]
          let _subchild = {
            title: el.title,
            isShow: isBefore ? true : false,
            isLeaf: true,
            key: el.name,
            type: 'feedback',
            controlName: el.name,
          }
          tempFeedback.push(_subchild)
        }
        if (data.treeData.visibleFeedback && data.treeData.visibleFeedback.length > 0) {
          let hiddenData = data.treeData.visibleFeedback
          const _visiblestopic = tempFeedback.map(function (newData) {
            let isExist = hiddenData.find(c => c.controlName === newData.controlName)
            if (isExist) {
              newData.isShow = isExist.isShow
            }
            return newData
          });
          feedbackStore.setVisiblFeedback(_visiblestopic)
        } else {
          feedbackStore.setVisiblFeedback(tempFeedback)
        }
      }

      //fb forms
      let tempFeedbackForms = []
      if (feedbackStore.feedbackformNormalVisualization.length > 0) {
        let _fbArrs = feedbackStore.feedbackformNormalVisualization
        for (const element of _fbArrs) {
          let _subchild = {
            title: element.title,
            isLeaf: true,
            isShow: isBefore ? true : false,
            key: element.id,
            type: 'feedback',
            controlName: element.id,
            typeFolder: 'form'
          }
          tempFeedbackForms.push(_subchild)
        }
        if (data?.treeData?.visibleFeedbackForms && data?.treeData?.visibleFeedbackForms.length > 0) {
          let hiddenData = data?.treeData?.visibleFeedbackForms
          const _visiblestopic = tempFeedbackForms.map(function (newData) {
            let isExist = hiddenData.find(c => c.controlName === newData.controlName)
            if (isExist) {
              newData.isShow = isExist.isShow
            }
            return newData
          });
          feedbackStore.setVisiblFeedbackForm(_visiblestopic)
        } else {
          feedbackStore.setVisiblFeedbackForm(tempFeedbackForms)
        }
      }
    }

  }, [projectStore.targetViewPoint])

  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
}

  const onSelectFromAttributes = async (selectedKeys, info) => {
    if (info?.type !== 'loadmore') {
      setCheckedKeys([])
      setCheckedNodes([])
      setSelectedKeys(selectedKeys);
      if (selectedKeys.length) {
        projectStore.setSelectedNode(info);
        userGroupStore.setTreeNodeEdit(info)
        if (isFeedback(projectStore.selectedNode)) {
          clickFeedbackNode(projectStore.selectedNode)
        }
      } else {
        projectStore.setSelectedNode();
        uiStore.setShowAttrPanel(false);
        // unselected node feedback reset selected to false
        feedbackStore.setVisiblFeedback(
          feedbackStore.visibleFeedback.map(item => {
            item.selected = false
            return item
          })
        )
      }
    }
  }

  // get infomation for Select model and expend parent Folder
  useEffect(() => {
    if (uiStore.autoSelectedNode && treeData) {
      const { treeData: pTreeData } = projectStore.projectDetail
      if (pTreeData) {

        const pTree = traversalTree(pTreeData, null);
        let store = []
        let parentKeys = []
        let expendKey = []
        try {
          let getNodeData = (items, store, expendKey, parentKeys, currentKey = []) => { // recursive
            const isArray = Array.isArray(items)

            if (isArray) {
              items.forEach((item) => {
                if (!item.children) { // return soon   
                  if (currentKey) {
                    parentKeys.push(currentKey)
                  }

                  if (item.modelId === uiStore.modelIdFromAttribute) {
                    expendKey.push(item.parentKey)
                    store.push(item)
                  }
                  else {
                    if (item.refId === uiStore.refIdFromAttribute) {

                      expendKey.push(item.parentKey)
                      store.push(item)
                    }
                  }

                  if (item.sketchId === uiStore.sketchIdFromAttribute) {
                    expendKey.push(item.parentKey)
                    store.push(item)
                  }
                  return
                }
                let key = currentKey
                key += item.key + " "
                getNodeData(item.children, store, expendKey, parentKeys, key)
              })
            }
          }

          getNodeData(pTree, store, expendKey, parentKeys, '')
          let arrParentsKey = []
          parentKeys.map(item => arrParentsKey.push(item.split(" ")))
          arrParentsKey.map(item => {
            item.filter(key => key === expendKey[0]).map(key => {
              return setExactKey(item)
            })
          })

          if (store) {
            setSelectNodeData(store[0])
          }

        } catch (error) {
          console.log('error', error)
        }

        uiStore.setAutoSelectedNode(false)
      }
    }
  }, [uiStore.autoSelectedNode, treeData])

  // Select model and expend parent Folder
  useEffect(() => {
    if (selectNodeData) {
      try {
        setExpandedKeys(exactKey);
        setAutoExpandParent(false);
        onSelectFromAttributes([selectNodeData?.key], selectNodeData)
        uiStore.setSketchIdFromAttribute('')
        uiStore.setModelIdFromAttribute('')
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [selectNodeData, exactKey])

  useEffect(() => {
    if (treeDataFeedback.length > 0 && onCheckFeedback) {
      clickFeedbackNode(treeDataFeedback[0])
      setOnCheckFeedback(false)
    }

  }, [treeDataFeedback])

  const clickFeedbackNode = (node) => {
    if (projectStore.showEditLocation) return
    let arrFeedbacks = []
    if (node.children) {
      arrFeedbacks = filterChildren(node.children, true)
    } else {
      arrFeedbacks.push({ controlName: node.controlName, isShow: true, selected: true, type: node?.typeFolder ? node?.typeFolder : "root" })
    }
    //reset all node selected feedback to false
    feedbackStore.setVisiblFeedback(
      feedbackStore.visibleFeedback.map(item => {
        item.selected = false
        return item
      })
    )

    //reset all node selected feedbackform to false
    feedbackStore.setVisiblFeedbackForm(
      feedbackStore.visibleFeedbackForm.map(item => {
        item.selected = false
        return item
      })
    )
    let arrFeedbackForms = arrFeedbacks.filter(c => c.type === "form")
    let arrFeedbackAnswers = arrFeedbacks.filter(c => c.type === "answer")

    // check node selected if not exist in visibleFeedback then add else exist then update nodes selected to true
    arrFeedbackAnswers.forEach(el => {
      let _item = feedbackStore.visibleFeedback.find(x => x.controlName === el.controlName)
      if (!_item) {
        feedbackStore.setVisiblFeedback([...feedbackStore.visibleFeedback, el]);
      } else {
        feedbackStore.setVisiblFeedback(
          feedbackStore.visibleFeedback.map(item => item.controlName === el.controlName ? { ...item, selected: true } : item)
        )
      }
    });

    arrFeedbackForms.forEach(el => {
      let _item = feedbackStore.visibleFeedbackForm.find(x => x.controlName === el.controlName)
      if (!_item) {
        feedbackStore.setVisiblFeedbackForm([...feedbackStore.visibleFeedbackForm, el]);
      } else {
        feedbackStore.setVisiblFeedbackForm(
          feedbackStore.visibleFeedbackForm.map(item => item.controlName === el.controlName ? { ...item, selected: true } : item)
        )
      }
    });
  }

  const clickTopicNode = (node) => {
    if (projectStore.showEditLocation) return
    let arrTopicAn = []
    if (node.children) {
      arrTopicAn = filterChildren(node.children, true)
    } else {
      arrTopicAn.push({ ...node, selected: true })
    }
    //reset all node selected Topic to false
    topicStore.setVisibleTopic(
      topicStore.visibleTopic.map(item => {
        item.selected = false
        return item
      })
    )

    // check node selected if not exist in visibleTopic then add else exist then update nodes selected to true
    arrTopicAn.forEach(el => {
      let _item = topicStore.visibleTopic.find(x => x.controlName === el.controlName)
      if (!_item) {
        topicStore.setVisibleTopic([...topicStore.visibleTopic, el]);
      } else {
        topicStore.setVisibleTopic(
          topicStore.visibleTopic.map(item => item.controlName === el.controlName ? { ...item, selected: true } : item)
        )
      }
    });
  }

  const filterChildren = (array, search) => {
    var result = [];
    array.forEach(function (a) {
      var temp = [],
        o = {},
        found = false;
      if (a.isLeaf === search) {
        o = { controlName: a.controlName, isShow: true, selected: true, type: a?.typeFolder ? a?.typeFolder : "root" }
        result.push(o)
        found = true;
      }
      if (Array.isArray(a.children)) {
        temp = filterChildren(a.children, search);
        if (temp.length) {
          temp.forEach(element => result.push(element));
          found = true;
        }
      }
    });
    return result;
  }

  const findModel3DByKey = (key) => {
    let model3D = null;
    map(projectStore.loadedModelTreeData, (treeData, modelId) => {
      if (!model3D) {
        const node = TreeUtils.searchTreeNode(treeData, 'key', key);
        if (node) {
          model3D = modelId
        }
      }
    })
    return model3D;
  }

  const onCheck = (checkedKeys, { checkedNodes }) => {
    setSelectedKeys([])
    projectStore.setSelectedNode()
    setCheckedKeys(checkedKeys)
    setCheckedNodes(checkedNodes)

    // Set selected node feedback    
    var _fbcheck = checkedNodes.reduce(function (filtered, option) {
      if (option.type === 'feedback' && option.isLeaf) {
        var someNewValue = { controlName: option.controlName, isShow: true, selected: true, type: option?.typeFolder }
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);
    //reset all node selected feedback to false
    feedbackStore.setVisiblFeedback(
      feedbackStore.visibleFeedback.map(item => {
        item.selected = false
        return item
      })
    )

    //reset all node selected feedbackform to false
    feedbackStore.setVisiblFeedbackForm(
      feedbackStore.visibleFeedbackForm.map(item => {
        item.selected = false
        return item
      })
    )

    let arrFeedbackForms = _fbcheck.filter(c => c.type === "form")
    let arrFeedbackAnswers = _fbcheck.filter(c => c.type === "answer")
    // check node selected if not exist in visibleFeedback then add else exist then update nodes selected to true
    arrFeedbackAnswers.forEach(el => {
      let _item = feedbackStore.visibleFeedback.find(x => x.controlName === el.controlName)
      if (!_item) {
        feedbackStore.setVisiblFeedback([...feedbackStore.visibleFeedback, el]);
      } else {
        feedbackStore.setVisiblFeedback(
          feedbackStore.visibleFeedback.map(item => item.controlName === el.controlName ? { ...item, selected: true } : item)
        )
      }
    });

    arrFeedbackForms.forEach(el => {
      let _item = feedbackStore.visibleFeedbackForm.find(x => x.controlName === el.controlName)
      if (!_item) {
        feedbackStore.setVisiblFeedbackForm([...feedbackStore.visibleFeedbackForm, el]);
      } else {
        feedbackStore.setVisiblFeedbackForm(
          feedbackStore.visibleFeedbackForm.map(item => item.controlName === el.controlName ? { ...item, selected: true } : item)
        )
      }
    });
  };

  const onCheckTopic = (checkedKeys, { checkedNodes }) => {
    setSelectedKeys([])
    projectStore.setSelectedNode()
    setCheckedKeys(checkedKeys)
    setCheckedNodes(checkedNodes)

    // Set selected node Topic    
    var _topickCheck = checkedNodes.reduce(function (filtered, option) {
      if (option.type === 'topic' && option.isLeaf) {
        var someNewValue = { controlName: option.controlName, isShow: true, selected: true }
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);
    //reset all node selected Topic to false
    topicStore.setVisibleTopic(
      topicStore.visibleTopic.map(item => {
        item.selected = false
        return item
      })
    )
    // check node selected if not exist in visibleTopic then add else exist then update nodes selected to true
    _topickCheck.forEach(el => {
      let _item = topicStore.visibleTopic.find(x => x.controlName === el.controlName)
      if (!_item) {
        topicStore.setVisibleTopic([...topicStore.visibleTopic, el]);
      } else {
        topicStore.setVisibleTopic(
          topicStore.visibleTopic.map(item => item.controlName === el.controlName ? { ...item, selected: true } : item)
        )
      }
    });
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    //save expand key to project
    if (usersStore.currentUser && usersStore.currentUser._id) {
      let _projectMetadata = projectStore.projectDetail.metadata
      if (_projectMetadata && _projectMetadata.dataTreeStatus && _projectMetadata.dataTreeStatus.length > 0) {
        let indexdataTreeStatus = projectStore.projectDetail.metadata.dataTreeStatus.findIndex(item => item.userId === usersStore.currentUser._id)
        if (indexdataTreeStatus > -1) {
          const temp = [..._projectMetadata.dataTreeStatus]
          temp[indexdataTreeStatus].treeStatus = expandedKeys
          delete temp[indexdataTreeStatus].visibleTilesets 
          _projectMetadata.dataTreeStatus = temp
        } else {
          let _dataTreeStatus = { userId: usersStore.currentUser._id, treeStatus: expandedKeys }
          _projectMetadata.dataTreeStatus.push(_dataTreeStatus)
        }
      } else {
        _projectMetadata.dataTreeStatus = [{ userId: usersStore.currentUser._id, treeStatus: expandedKeys }]
      }
      delete _projectMetadata.timeSlider
      delete _projectMetadata.modelHiden
      delete _projectMetadata.visibleClipModels
      var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
      if (retrievedObject && retrievedObject.projectId === projectStore.projectDetail._id && usersStore.currentUser && retrievedObject.userId === usersStore.currentUser._id && projectStore.projectDetail.currentUser) {
        retrievedObject.timeSlider = schedulingStore.currentViewingTime
        retrievedObject.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
        if (usersStore.currentUser && usersStore.currentUser._id) {
          if (!projectStore.isEditSessionVisibleData)
            localStorage.setItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`, JSON.stringify(retrievedObject));
        }
      }

      let newData = {
        metadata: _projectMetadata
      }
      projectStore.updateProjectData(newData).then((res) => {
        projectStore.projectDetail.metadata = res.metadata
      }).catch(err => {
        console.log(err)
      })
    }
    //=============
    setAutoExpandParent(false);
  }

  const clickEditFile = node => {
    if (isFolder(node)) {
      projectStore.setFolderEdit(node);
      projectStore.setShowAddFolderModal(true);
    } else if (isModel(node)) {
      projectStore.setFileEdit(node);
      projectStore.setShowEditFileModal(true);
    }
  }

  const traversalTree = (data, parentKey) => {
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          icon: ({ expanded }) => setNodeIcon(data, item, expanded),
          children: traversalTree(item.children, item.key),
          parentKey,
          isLeaf: isLeaf(item)
        };
      }
      return {
        ...item,
        icon: ({ expanded }) => setNodeIcon(data, item, expanded),
        parentKey,
        isLeaf: isLeaf(item)
      };
    });
  }

  const traversalDropTree = (data, parentKey) => {
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          children: traversalTree(item.children, item.key),
          parentKey,
          isLeaf: isLeaf(item)
        };
      }
      return {
        ...item,
        parentKey,
        isLeaf: isLeaf(item)
      };
    });
  }

  const isLeaf = (item) => {
    return !((isFolder(item) && item.children && item.children.length) || (isModel(item) && (item.modelType === 'ifc' ||item.modelType === 'cad' || (item.modelType === 'landxml' || item.modelType === 'landxmlBackground' || item.modelType === 'external-plugin')))) || isWMS(item) || is3DCityDB(item)
  }

  const clickEditUserGroups = (node) => {
    userGroupStore.setEditAccessControl(true)
    // userGroupStore.setTreeNodeEdit(projectStore.setSelectedNode)
  }

  const setNodeIcon = (data, item, expanded) => {
    const isViewNodeIcon = checkVisibleIconUserGroups(item, 'view');
    const isModifyNodeIcon = checkVisibleIconUserGroups(item, 'modify');
    if (isFolder(item)) {
      return (
        <>
          <SVGIcon
            className={isVisibleFolder(data, item.key) ? '' : 'hide'}
            content={expanded ? <ResourceFolderFoldIcon /> : <ResourceNewFolderIcon />}
            width={20}
            height={20}
          />
          {
            projectStore.currentUserRoleInProject === "project_owner" && (
              <Avatar
                style={{ marginLeft: '3px' }}
                title={t('user-groups')}
                shape="square"
                icon={<SVGIcon
                  className={isVisibleFolder(data, item.key) ? '' : 'hide'}
                  content={<AccessControl />}
                  width={18}
                  height={18}
                />}
                size={20}
                onClick={() => clickEditUserGroups(item)}
              />
            )
          }
          {
            (isViewNodeIcon || isModifyNodeIcon) && (
              <Avatar
                style={{ marginLeft: '3px' }}
                title={isVisibleFolder(data, item.key) ? t('hide') : t('show')}
                shape="square"
                icon={<SVGIcon
                  className={isVisibleFolder(data, item.key) ? '' : 'hide'}
                  content={isVisibleFolder(data, item.key) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  width={20}
                  height={20}
                />}
                size={20}
                onClick={() => toggleVisibleFolder(data, item.key)}
              />
            )
          }
          {item.title !== "Sketch" && (isModifyNodeIcon) ? (
            <Avatar
              title={isVisibleClipFolder(data, item.key) ? t('Clip OFF') : t('Clip ON')}
              shape="square"
              style={{ fontSize: '100%' }}
              icon={<SVGIcon
                className={isVisibleFolder(data, item.key) ? '' : 'hide'}
                content={isVisibleClipFolder(data, item.key) ? <ClipOn /> : <ClipOff />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleClipFolder(data, item.key)}
            />
          ) : null}
          {(isModifyNodeIcon) && (item.startDate || item.endDate) ? (
            <Avatar
              shape="square"
              icon={<SVGIcon
                className={isVisibleFolder(data, item.key) ? '' : 'hide'}
                content={isVisible4DFolders(item.key) ? <Icon4DON /> : <Icon4DOFF />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisible4DFolder(data, item.key)}
            />
          ) : null}
        </>
      )
    }
    if (isModel(item)) {
      return (
        <>
          <SVGIcon
            title={setModelTitle(item)}
            className={isVisibleModel(item.modelId) ? '' : 'hide'}
            content={setModelIcon(item)}
            width={20}
            height={20}
          />{' '}
          {
            projectStore.currentUserRoleInProject === "project_owner" && (
              <Avatar
                style={{ marginLeft: '3px' }}
                title={t('user-groups')}
                shape="square"
                icon={<SVGIcon
                  className={isVisibleModel(item.modelId) ? '' : 'hide'}
                  content={<AccessControl />}
                  width={18}
                  height={18}
                />}
                size={20}
                onClick={() => clickEditUserGroups(item)}
              />
            )
          }
          {
            is3DModel(item) ?
              <>
                {
                  (isViewNodeIcon || isModifyNodeIcon) &&
                  <Avatar
                    shape="square"
                    icon={<SVGIcon
                      className={isVisibleModel(item.modelId) ? '' : 'hide'}
                      content={isVisibleModel(item.modelId) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                      width={20}
                      height={20}
                    />}
                    size={20}
                    onClick={() => toggleVisibleTileset(item.modelId, isVisibleModel(item.modelId))}
                    style={{ marginLeft: '3px' }}
                  />
                }
                {
                  isModifyNodeIcon &&
                  <Avatar
                    shape="square"
                    style={{ fontSize: '100%', marginTop: '-2px' }}
                    icon={<SVGIcon
                      className={isVisibleModel(item.modelId) ? '' : 'hide'}
                      content={isVisibleClipIcon(item.modelId) ? <ClipOn /> : <ClipOff />}
                      width={20}
                      height={20}
                    />}
                    size={20}
                    onClick={() => toggleVisibleClipIcon(item.modelId)}
                  />
                }
                {
                  checkHas4DTimeStamp(item) && isModifyNodeIcon &&
                  <Avatar
                    shape="square"
                    icon={<SVGIcon
                      className={isVisibleModel(item.modelId) ? '' : 'hide'}
                      content={isVisibleModel4DIcon(item.modelId) ? <Icon4DON /> : <Icon4DOFF />}
                      width={20}
                      height={20}
                    />}
                    size={20}
                    onClick={() => toggleVisibleSketch4DIcon(item.modelId)}
                  />
                }
              </>
              : (
                isFileIcon(item) ?
                  <>
                    {
                      (isViewNodeIcon || isModifyNodeIcon) &&
                      <Avatar
                        shape="square"
                        icon={<SVGIcon
                          className={isVisibleModel(item.modelId) ? '' : 'hide'}
                          content={isVisibleModel(item.modelId) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                          width={20}
                          height={20}
                        />}
                        size={20}
                        onClick={() => toggleVisibleTileset(item.modelId)}
                        style={{ marginLeft: '3px' }}
                      />
                    }
                    {
                      isModifyNodeIcon &&
                      <Avatar
                        shape="square"
                        icon={<SVGIcon
                          className={isVisibleModel(item.modelId) ? '' : 'hide'}
                          content={<DOWNLOAD />}
                          width={20}
                          height={20}
                        />}
                        size={20}
                        onClick={() => downloadUserFile(item.modelId)}
                      />
                    }
                    {
                      checkHas4DTimeStamp(item) && isModifyNodeIcon &&
                      <Avatar
                        shape="square"
                        icon={<SVGIcon
                          className={isVisibleModel(item.modelId) ? '' : 'hide'}
                          content={isVisibleModel4DIcon(item.modelId) ? <Icon4DON /> : <Icon4DOFF />}
                          width={20}
                          height={20}
                        />}
                        size={20}
                        onClick={() => toggleVisibleSketch4DIcon(item.modelId)}
                      />
                    }
                  </> : ''
              )
          }
          {' '}
        </>
      )
    }
    if (isSketch(item)) {
      return (
        <>
          {
            projectStore.currentUserRoleInProject === "project_owner" && (
              <Avatar
                title={t('user-groups')}
                shape="square"
                icon={<SVGIcon
                  className={isVisibleSketch(item.sketchId) ? '' : 'hide'}
                  content={<AccessControl />}
                  width={18}
                  height={18}
                />}
                size={20}
                onClick={() => clickEditUserGroups(item)}
              />
            )
          }
          {
            (isViewNodeIcon || isModifyNodeIcon) &&
            <Avatar
              shape="square"
              icon={<SVGIcon
                className={isVisibleSketch(item.sketchId) ? '' : 'hide'}
                content={isVisibleSketch(item.sketchId) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleSketch(item.sketchId)}
              style={{ marginLeft: '3px' }}
            />
          }
          {' '}
          <Avatar
            shape="square"
            icon={<SVGIcon
              className={isVisibleSketch(item.sketchId) ? '' : 'hide'}
              content={setSketchIcon(item)}
              width={20}
              height={20}
            />}
            size={20}
          />{' '}
          {
            checkHas4DTimeStamp(item) && isModifyNodeIcon &&
            <Avatar
              shape="square"
              icon={<SVGIcon
                className={isVisibleSketch(item.sketchId) ? '' : 'hide'}
                content={isVisibleSketch4DIcon(item.sketchId) ? <Icon4DON /> : <Icon4DOFF />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleSketch4DIcon(item.sketchId)}
            />
          }
        </>
      )
    }
  }

  const setSketchIcon = (item) => {
    let check3dMesh = Utils.getSketchLibraryModel(sketchingStore,item.sketchId)
    if (item.sketchType == 'area' && item.readonlyHeight && !check3dMesh) {
      return <AreaIcon />
    }
    else if (item.sketchType == 'area' && !item.readonlyHeight && !check3dMesh) {
      return <VolumeIcon />
    }
    else if (item.sketchType == 'line' && !item.readonlyHeight && !check3dMesh) {
      return <LineIcon />
    }
    else if (item.sketchType == 'point' && !item.readonlyHeight && !check3dMesh) {
      return <SingleIcon />
    }
    else {
      return <Mesh3DIcon />
    }

  }
  const setModelTitle = (item) => {
    switch (item.modelType) {
      case 'landxmlBackground':
      case 'landxml':
        return "LandXML"
      case 'ifc':
        return "IFC"
      case 'cad':
        return "CAD"
      case 'cloudpoint':
        return 'Cloud Point'
      case 'e57':
        return 'e57'
      case 'geotiff':
        return "Raster"
    }
    switch (item.sourceType) {
      case 'WMS':
      case 'WFS':
        return "Web service"
    }
  }
  const setModelIcon = (item) => {
    switch (item.modelType) {
      case 'landxmlBackground':
      case 'landxml':
        return <ResourceXmlIcon />
      case 'ifc':
        return <ResourceIfcIcon />
      case 'cad':
        return <Resource3DTilesIcon />
      case 'model3d':
      case 'kmz':
        return <Resource3DIcon />
      case 'cloudpoint':
        let model = projectStore.modelList.find(c => (c.id === item.modelId || c._id === item.modelId))
        return (model?.status === "process_success" || ( !model?.status && model?.data?.srcTileset)) ? <ResourcePointCloudIcon /> : <SyncOutlined style={{ minWidth: '20px', fontSize: '18px', color: 'black' }} spin />
      case 'e57':
        return <ResourcePointCloudIcon />
      case 'external-plugin':
        return <Resource3DIcon />
      case 'geotiff':
        return <RasterImageryIcon />
      case 'geojson':
        return <VectorGISIcon />
    }
    switch (item.sourceType) {
      case 'WMS':
      case 'WFS':
        return <ResourceWmsIcon />
      case 'City3DDB':
        return <ResourceWmsIcon />
      case 'external':
        return <Resource3DTilesIcon />
    }
    const ext = item && item.modelId && item.ext && item.ext.toLowerCase()

    switch (ext) {
      case '.doc':
      case '.docx':
        return <img src={DocFile} />;
      case '.jpg':
      case '.jpeg':
        return <img src={JPGFile} />;
      case '.pdf':
        return <img src={PDFFile} />
      case '.png':
        return <img src={PNGFile} />
      case '.ppt':
      case '.pptx':
        return <img src={PPTFile} />
      case '.tiff':
        return <img src={TIFFile} />
      case '.txt':
        return <img src={TXTFile} />
      case '.xls':
      case '.xlsx':
        return <img src={XLSFile} />
      case 'httplinks':
        return <img src={HTTPlinks} />
      default:
        return <img src={GenericFile} />
    }
    let isImage = false
    if (ext && ext.match(/.(jpg|jpeg|png|gif|svg)$/i))
      isImage = true
    if (isImage) {
      return <IMG />
    }
    switch (ext) {
      case '.DWG':
        return <DWG />
      case '.PDF':
        return <PDF />
      case '.DXF':
        return <DXF />
      default:
        return <GENERIC />
    }
  }

  const onLoadData = (treeNode) => {
    setLoadedKeys([...loadedKeys, treeNode.key])
    return new Promise(async resolve => {
      if (treeNode.children) {
        resolve();
        return;
      }
      let modelTreeData
      let model = projectStore.modelList.find(x => x._id === treeNode.modelId)
      const filterNodeTypeAttribute = (dataTreeNode) => {
        return dataTreeNode.map(item => {
          item.children = item.children?.filter(x => x.type !== 5);
          if (item.children?.length > 0) {
            filterNodeTypeAttribute(item.children)
          }
          return item
        })
      }
      if (model?.crs?.Version || model.crs?.tilesetVersion) { //model.crs?.tilesetVersion for oldmodel, new model change to Version
        let fileid = model.hash ? (model.hash?.split("/").length > 1 ? model.hash.split("/")[1].trim() : model.hash.split("/")[0].trim()) : undefined;
        if (fileid) {
          modelTreeData = await modelStore.getTreeObjectInfo({ modelType: treeNode.modelType, fileid: fileid });
          modelTreeData.treeData = filterNodeTypeAttribute(modelTreeData.data)
        }
      } else {
        modelTreeData = await projectStore.waitUntilDataTreeReady(treeNode.modelId);
      }
      if (modelTreeData?.treeData) {
        projectStore.setLoadedModelTreeData({
          ...projectStore.loadedModelTreeData,
          [treeNode.modelId]: modelTreeData.treeData
        })
        const loadMoreTree = buildLoadMoreTree(treeNode.modelId, cloneDeep(modelTreeData.treeData));
        setTreeData(origin => TreeUtils.updateTreeData(origin, treeNode.key, loadMoreTree));
      }
      resolve();
    });
  }

  const buildLoadMoreTree = (modelId, data) => {
    data.map(item => {
      if (!item.title) {
        item.title = item.inf
      }
      item.modelId = modelId;
      if (isAttr(item)) {
        item.icon = () => (
          <>
            <SVGIcon
              className={!checkVisibleFeature(item).allHide ? '' : 'hide'}
              content={<ResourceObjectIcon />}
              width={20}
              height={20}
            />{' '}
            <Avatar
              shape="square"
              icon={<SVGIcon
                className={!checkVisibleFeature(item).allHide ? '' : 'hide'}
                content={!checkVisibleFeature(item).allHide ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleFeature(item)}
            />{' '}
          </>
        )
      } else if (item.type !== 'loadmore') {
        item.icon = <SVGIcon content={<ResourceObjectIcon />} width={20} height={20} />;
      }
      if (item.children) {
        const pageSize = 10;
        if (item.children.length > pageSize) {
          const newChildren = item.children.slice(0, pageSize);
          const nodeData = {
            key: uuid(),
            pageSize,
            pKey: item.key,
            type: 'loadmore',
            modelId,
            isLeaf: true,
            checkable: false
          }
          newChildren.push({
            ...nodeData,
            title: <Pagination size="small" total={item.children.length} onChange={(page, pageSize) => onChangePage(page, pageSize, nodeData)} pageSize={pageSize} showSizeChanger showQuickJumper />,
          })
          item.children = newChildren;
        }
        buildLoadMoreTree(modelId, item.children);
      }
    })
    return data;
  }

  const onChangePage = (page, pageSize, { pKey, pageSize: oldPageSize, modelId }) => {
    const newPage = pageSize !== oldPageSize ? 1 : page;
    const treeData = projectStore.loadedModelTreeData[modelId];
    const node = TreeUtils.searchTreeNode(treeData, 'key', pKey);
    if (node?.children) {
      const newChildren = node.children.slice((newPage - 1) * pageSize, newPage * pageSize);
      const nodeData = {
        key: uuid(),
        pageSize,
        pKey: node.key,
        type: 'loadmore',
        modelId,
        isLeaf: true,
        checkable: false
      }
      newChildren.push({
        ...nodeData,
        title: <Pagination size="small" current={newPage} total={node.children.length} onChange={(page, pageSize) => onChangePage(page, pageSize, nodeData)} pageSize={pageSize} showSizeChanger showQuickJumper />,
      })
      const loadMoreTree = buildLoadMoreTree(modelId, cloneDeep(newChildren));
      setTreeData(origin => TreeUtils.updateTreeData(origin, pKey, loadMoreTree))
    }
  }

  const mergeAccessControlTreeNode = (dragData, dropData) => {
    let result = dragData || [];
    if (!dropData && dragData) {
      result = dragData || []
    }
    if (!dragData && dropData) {
      // result = dropData || []
      result = []
    }
    if (dropData && dragData && dragData.length > 0) {
      result = mergeArrays(dragData, dropData)
    }
    return result;
  }

  function mergeArrays(array1, array2) {
    const merged = {};

    array1.forEach(obj => {
      merged[obj.id] = obj;
    });

    array2.forEach(obj => {
      if (!merged[obj.id]) {
        merged[obj.id] = obj;
      }
    });

    return Object.values(merged);
  }

  const onDrop = info => {
    const startTime = Date.now();
    const canDrag = (isModel(info.dragNode) || isSketch(info.dragNode) || isFolder(info.dragNode));
    const canDrop = (isModel(info.node) || isSketch(info.node) || isFolder(info.node));
    if ((canDrag && isFolder(info.node)) || (canDrag && info.dropToGap && canDrop)) {
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const dropNode = info.node

      const loop = (data, key, callback) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data);
          }
          if (data[i].children) {
            loop(data[i].children, key, callback);
          }
        }
      };
      const data = [...projectStore.projectDetail.treeData];
      // const data = [...treeData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      // Inherited settings from parent + current setting
      // if (dragObj && dropNode) {
      //   if (dropNode.type === "FOLDER" && !info.dropToGap) {
      //     // dragObj.accesscontrols = mergeAccessControlTreeNode(dragObj?.accesscontrols, dropNode?.accesscontrols)
      //   }
      //   if (dropNode.type === "FILE" || (dropNode.type === "FOLDER" && info.dropToGap)) {
      //     const dragNode = TreeUtils.searchTreeNode(traversalTree(treeData), 'key', dragObj.key);
      //     if (dragNode && dropNode && dropNode.parentKey && (dragNode.parentKey !== dropNode.parentKey)) {
      //       const dropParentNode = TreeUtils.searchTreeNode(traversalTree(treeData), 'key', dropNode.parentKey);
      //       if (dropParentNode) {
      //         // dragObj.accesscontrols = mergeAccessControlTreeNode(dragObj?.accesscontrols, dropParentNode?.accesscontrols)
      //       }
      //     }
      //   }
      // }
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || [];
          item.children.unshift(dragObj);
        });
      } else if (
        (dropNode.props.children || []).length > 0 && // Has children
        dropNode.props.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.children = item.children || [];
          item.children.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      projectStore.setLoadingProgress(true);
      // projectStore.updateProjectData({ treeData: traversalDropTree(data,null), store:'treedata' }).then(res => {
        projectStore.updateProjectTreeData({ treeData: traversalDropTree(data,null), store:'treedata' }).then(res => {
        projectStore.projectDetail.treeData = res.treeData;
        checkChangeWMSOrder(res.treeData)
        projectStore.setSelectedNode({
          ...projectStore.selectedNode,
          parentKey: dropNode.type === "FOLDER" ? dropNode.key : dropNode.parentKey
        })
        // setSelectedKeys();
        commonStore.loggingFunction('Drop treenode','success',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
      }).catch(err => {
        message.error(t('update-treeData-failed'))
        commonStore.loggingFunction('Drop treenode','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
      }).finally(() => {
        projectStore.setLoadingProgress(false);
      })
    }
  }

  // useEffect check data type
  useEffect(() => {
    const checkDataType = async () => {
      try {
        projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
        let topicLists = await topicStore.getTopicList(projectStore.projectDetail._id)
        setTreeLoading(false)
        let topicData = topicLists.data
        if (topicData) {
          topicData.map(topic => {
            if (topic.topictype || topic.location3D) {
              setTopicType(true)
            }
          })
        }
      } catch (error) {
        setTreeLoading(false)
      }

    }
    if (projectStore.displayPanel ) {
      checkDataType()
    }
  }, [topicStore.reloadDataTopic, projectStore.displayPanel])
  // }, [topicStore.reloadDataTopic])

  useEffect(() => {
    if ((projectStore.modelList?.length || sketchingStore.arrSketches?.length)) {
      const initData = async () => {
        // setTreeLoading(true)
        await initTreeData()
        try {
          let topicLists = await topicStore.getTopicList(projectStore.projectDetail._id);
          let topicData = topicLists.data
          if (topicData) {
            topicData.map(topic => {
              if (topic.topictype || topic.location3D) {
                setTopicType(true)
              }
            })
          }
        } catch (error) {
          setTreeLoading(false)
        }
        //load open/close Data tree status
        const currentUser = usersStore.currentUser;
        if (currentUser && currentUser?._id) {
          const userTreeStatus = projectStore.projectDetail?.metadata?.dataTreeStatus?.find(item => item.userId === currentUser._id);
          if (userTreeStatus) {
            setExpandedKeys(userTreeStatus?.treeStatus || []);
            if (userTreeStatus.defaultSelectedTree?.selectedNode && userTreeStatus.defaultSelectedTree?.selectedNode !== '{}') {
              const defaultSelectedNode = JSON.parse(userTreeStatus.defaultSelectedTree?.selectedNode);
              onSelect(userTreeStatus.defaultSelectedTree?.selectedKeys || [], { node: defaultSelectedNode });
            }            
          }
        }          

        setTreeLoading(false)
      }
      initData()
    }
  }, [])

  useEffect(() => {
    if (projectStore.listProjectLink?.length > 0) {
      const loadData = async () => {
        await getTreeDataProjectLink()
      }
      loadData()
    } else {
      setTreeDataProjectLink([])
    }
  }, [projectStore.listProjectLink])

  useEffect(() => {
    if (feedbackStore.feedbackformNormalVisualization?.length > 0) {
      feedbackStore.feedbackformNormalVisualization.forEach(el => {
        let _item = feedbackStore.visibleFeedbackForm.find(x => x.controlName === el.id)
        let _subchild = {
          title: el.title,
          isLeaf: true,
          isShow: true,
          key: el.id,
          type: 'feedback',
          controlName: el.id,
          typeFolder: 'form'
        }
        if (!_item) {
          feedbackStore.setVisiblFeedbackForm([...feedbackStore.visibleFeedbackForm, _subchild]);
        }
      });
    } else {
      feedbackStore.setVisiblFeedbackForm([])
    }
  }, [feedbackStore.feedbackformNormalVisualization])

  useEffect(() => {
    if (feedbackStore.feedbackVisualization?.length > 0) {
      let _fbAnswers = []
      let layerGenericFeedback = feedbackStore.feedbackVisualization?.map(item => toJS(item.formControlData.elements))
      let arr2 = [...new Set(layerGenericFeedback.flat(1))];

      const feedbackForm = {}
      for (let i = 0; i < arr2.length; i++) {
        const item = arr2[i];
        if (!feedbackForm[item.name]) {
          feedbackForm[item.name] = []
        }
        feedbackForm[item.name].push(item)
      }
      for (const key in feedbackForm) {
        const GenericFeedback = feedbackForm[key];
        const el = GenericFeedback[0]
        let _subchild = {
          title: el.title,
          isLeaf: true,
          isShow: true,
          key: el.name,
          type: 'feedback',
          controlName: el.name,
          typeFolder: 'answer'
        }
        _fbAnswers.push(_subchild)
      }
      if (_fbAnswers.length > 0) {
        _fbAnswers.forEach(el => {
          let _item = feedbackStore.visibleFeedback.find(x => x.controlName === el.controlName)
          if (!_item) {
            feedbackStore.setVisiblFeedback([...feedbackStore.visibleFeedback, el]);
          }
        });
      } else {
        feedbackStore.setVisiblFeedback([])
      }

    } else {
      feedbackStore.setVisiblFeedback([])
    }
  }, [feedbackStore.feedbackVisualization])

  useEffect(() => {
    let _fbAnswer = []
    let layerGenericFeedback = feedbackStore.feedbackVisualization?.map(item => toJS(item.formControlData.elements))
    let _fbLocations = feedbackStore.feedbackformNormalVisualization.filter(c => c.viewLocation && c.viewLocation?.location) || []
    let arr2 = [...new Set(layerGenericFeedback.flat(1))];

    const feedbackForm = {}
    for (let i = 0; i < arr2.length; i++) {
      const item = arr2[i];
      if (!feedbackForm[item.name]) {
        feedbackForm[item.name] = []
      }
      feedbackForm[item.name].push(item)
    }
    for (const key in feedbackForm) {
      const GenericFeedback = feedbackForm[key];
      const el = GenericFeedback[0]
      let _subchild = {
        title: el.title,
        isLeaf: true,
        key: el.name,
        type: 'feedback',
        icon: ({ expanded }) => iconFeedback(el, expanded),
        controlName: el.name,
        typeFolder: 'answer'
      }
      _fbAnswer.push(_subchild)
    }

    if (_fbAnswer.length > 0 || _fbLocations?.length > 0) {
      const loadData = async () => {
        setTreeLoading(true)
        await feedbackStore.getFeedbackformsList(projectStore.projectDetail._id, 'normal').then(res => {
          let _fbLocation = res.data.filter(c => c.viewLocation && c.viewLocation?.location)
          feedbackStore.setFeedbackformNormalVisualization(_fbLocation)
        })
        await feedbackStore.getGenericFeedback(projectStore.projectDetail._id)
        await getGenericFeedback()
        setTreeLoading(false)
      }
      if (projectStore.displayPanel) {
        loadData()
      }
    } else {
      setTreeDataFeedback([])
    }
  }, [feedbackStore.feedbackVisualization, projectStore.displayPanel, feedbackStore.feedbackformNormalVisualization?.length])

  useEffect(() => {
    if (feedbackStore.visibleFeedback.length > 0) {
      let _visibleFeedback = feedbackStore.visibleFeedback.filter(item => item.isShow).map(item => toJS(item))
      setIsVisibleFeedback(_visibleFeedback)
      setOnCheckFeedback(false)
      feedbackStore.setHiddenTFeedback(feedbackStore.visibleFeedback.filter(item => !item.isShow))
    }
  }, [feedbackStore.visibleFeedback])

  useEffect(() => {
    if (topicStore.topicList.length > 0) {
      const loadData = async () => {
        await getTreeDataTopic()
      }
      loadData()
    } else {
      setTreeDataTopic([])
    }
  }, [topicStore.topicList, topicStore.reloadDataTopic])

  useEffect(() => {

  }, [isVisibleTopics, onLoadTopicTree])


  useEffect(() => {
    if (isVisibleFeedback) {
      getGenericFeedback()
    }
  }, [isVisibleFeedback])

  const toggleVisibleTopicType = controlName => {
    const _visibleTopic = topicStore.visibleTopic.map(item => {
      if (item.controlName === controlName) {
        item.isShow = !item.isShow
      }
      return item
    })
    topicStore.setVisibleTopic(_visibleTopic)
  }

  const toggleVisibleTopic = () => {
    let isAnyNodeShow = topicStore.visibleTopic.some(el => el.isShow === true && el.selected === true) //check if has any child node feedback selected and show then hide all
    const _visibleTopic = topicStore.visibleTopic.map(item => {
      if (item.selected) { //only change state with node selected
        item.isShow = isAnyNodeShow ? false : !item.isShow
      }
      return item
    })
    topicStore.setVisibleTopic(_visibleTopic)
  }

  const toggleVisibleParentNodeTopic = () => {
    let show = false
    let isAnyNodeShow = topicStore.visibleTopic.filter(el => el.isShow)
    let isAnyNodeOff = topicStore.visibleTopic.filter(el => !el.isShow)
    if (isAnyNodeShow.length === topicStore.visibleTopic.length) {
      show = false
    }
    if (isAnyNodeOff.length === topicStore.visibleTopic.length) {
      show = true
    }
    const _visibleTopic = topicStore.visibleTopic.map(item => {
      item.isShow = show
      return item
    })
    topicStore.setVisibleTopic(_visibleTopic)
  }
  const onSelectTopic = async (selectedKeys, info) => {
    const { node: { type } } = info;
    if (type !== 'loadmore') {
      setCheckedKeys([])
      setCheckedNodes([])
      setSelectedKeys(selectedKeys);
      if (selectedKeys.length) {
        projectStore.setSelectedNode(info.node);
        if (isTopic(projectStore.selectedNode)) {
          clickTopicNode(projectStore.selectedNode)
        }
      } else {
        projectStore.setSelectedNode();
        uiStore.setShowAttrPanel(false);
        // unselected node feedback reset selected to false
        topicStore.setVisibleTopic(
          topicStore.visibleTopic.map(item => {
            item.selected = false
            return item
          })
        )
      }
    }
  }
  const getTreeDataTopicRootVisibleState = (on, off) => {
    let visible = topicStore.visibleTopic.filter(item => item.isShow).map(item => toJS(item))
    return topicStore.visibleTopic.length === 0 || visible.length > 0 ? on : off
  }

  useEffect(() => {
    if (topicStore.hiddenTopicType) {
      let visible = false
      if (topicStore.hiddenTopicType.length === topicStore.visibleTopic.length) {
        visible = true
      }
      setIsVisibleAllTopic(visible)
    }
  }, [topicStore.hiddenTopicType])

  const getTreeDataTopic = async () => {
    let _treeDataTopic = [
      {
        title: 'Topic',
        key: idTopicTree,
        type: 'topic',
        icon: () => {
          return (
            <>
              <SVGIcon
                color={(topicStore.hiddenTopicType.length === topicStore.visibleTopic.length) ? 'rgb(151,160,175)' : ''}
                content={<ResourceFeedbackFolderIcon />} width={20} height={20} />
              {' '}
              <Avatar
                shape="square"
                icon={<SVGIcon
                  className={(topicStore.hiddenTopicType.length === topicStore.visibleTopic.length) ? 'hide' : ''}
                  content={(topicStore.hiddenTopicType.length === topicStore.visibleTopic.length) ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  color={(topicStore.hiddenTopicType.length === topicStore.visibleTopic.length) ? 'rgb(151,160,175)' : ''}
                  width={20}
                  height={20}
                />}
                size={20}
                onClick={() => toggleVisibleParentNodeTopic()}
              />{' '}
            </>
          )
        }
        ,
        children: []
      }
    ]
    let arrType = []
    let _visibleTopics = []

    let topicData = topicStore.topicList
    if (topicStore.topicList.length) {
      let topicNoType = topicData.find(c => c.location3D && !c.topictype)
      if (topicNoType) {
        arrType.push({ topictype: 'No type' })
      }
      topicData.map(topic => {
        let find = arrType.find(c => c.topictype === topic.topictype)
        if (!find) {
          arrType.push(toJS(topic))
        }
      })

      if (arrType) {
        arrType.filter(topic => topic.topictype).map((topic) => {
          let _tp = topicStore.visibleTopic.find(c => c.controlName === topic.topictype)
          let _subchild = {
            title: topic.topictype,
            isLeaf: true,
            key: uuid(),
            type: 'topic',
            isShow: _tp ? _tp.isShow : true,
            controlName: topic.topictype,
            icon: () => {
              return (<>
                <SVGIcon
                  color={isVisibleTopic(topic.topictype) ? '' : 'rgb(151,160,175)'}
                  content={<ResourceFeedbackIcon />} width={20} height={20} />
                {' '}
                <Avatar
                  shape="square"
                  icon={<SVGIcon
                    className={isVisibleTopic(topic.topictype) ? '' : 'hide'}
                    content={isVisibleTopic(topic.topictype) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                    color={isVisibleTopic(topic.topictype) ? '' : 'rgb(151,160,175)'}
                    width={20}
                    height={20}
                  />}
                  onClick={() => toggleVisibleTopicType(topic.topictype)}
                  size={20}
                />{' '}
              </>)
            }
          }
          _treeDataTopic[0].children.push(_subchild)
          _visibleTopics.push(_subchild)
        })
        setTreeDataTopic(_treeDataTopic)
        topicStore.setVisibleTopic(_visibleTopics)
      }
    }
  }

  const toggleVisibleIcon = (projectLink, type) => {
    let params = {
      userId: usersStore.currentUser?._id,
      isVisibleClip: projectLink.isVisibleClip || false,
      isVisible: projectLink.isVisible || false,
      isVisible4D: projectLink.isVisible4D || false
    }
    if (type === 'clip') params.isVisibleClip = !projectLink.isVisibleClip;
    if (type === '4D') params.isVisible4D = !projectLink.isVisible4D;
    if (type === 'show') {
      params.isVisible = !projectLink.isVisible;
    }
    let _models 
    let listProjectLink = projectStore.listProjectLink.map(item => {
      if (item.id === projectLink.id) {
        if (type === 'clip') item.isVisibleClip = !projectLink.isVisibleClip;
        if (type === '4D') item.isVisible4D = !projectLink.isVisible4D;
        if (type === 'show') item.isVisible = !projectLink.isVisible;
        _models = projectStore.listAllModel3DSLink.map(c => {
          if(c?.link?.id === projectLink?.link?.id ){
            return toJS({
              ...c,
              isVisibleClip :  params.isVisibleClip,
              isVisible4D :  params.isVisible4D,
              isVisible :  params.isVisible
            })
          }
          return toJS(c)
        })
      }
      return item
    })
    if(_models) {
      projectStore.setListAllModel3DSLink(_models) ;
    }
    const foundIndex = projectLink.visibleData?.findIndex(x => x.userId === usersStore.currentUser?._id)
    const temp = projectLink.visibleData?.length > 0 ? [...projectLink.visibleData] : [];
    if (foundIndex > -1) {
      temp[foundIndex] = params
    } else {
      temp.push(params)
    }
    projectStore.setSelectedNode({
      ...projectLink,
       isVisibleClip: params.isVisibleClip ,
       isVisible: params.isVisible,
       isVisible4D: params.isVisible4D,
       visibleData: temp
    })
    projectStore.setListProjectLink(listProjectLink)
    projectStore.updateProjectLinkVisible(projectLink.id, { visibleData: temp })
  }


  const getTreeDataProjectLink = async () => {
    const traversalTreeProjectLink = (data) => {
      return data.map(item => {
        return {
          ...item,
          key: item.id,
          title: item?.link?.name,
          icon: <>
            <SVGIcon
              content={<ResourceFolderFoldIcon />}
              width={20}
              height={20}
              color={(!item.isVisible) ? 'rgb(151,160,175)' : ''}
            />
            <Avatar
              style={{ marginLeft: '3px' }}
              shape="square"
              icon={<SVGIcon
                className={item.isVisible ? '' : 'hide'}
                content={item.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleIcon(item, 'show')}
            />
            <Avatar
              shape="square"
              style={{ fontSize: '100%' }}
              icon={<SVGIcon
                className={item.isVisible ? '' : 'hide'}
                content={item.isVisibleClip ? <ClipOn /> : <ClipOff />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleIcon(item, 'clip')}
            />
            <Avatar
              shape="square"
              icon={<SVGIcon
                className={item.isVisible ? '' : 'hide'}
                content={item.isVisible4D ? <Icon4DON /> : <Icon4DOFF />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleIcon(item, '4D')}
            />
          </>
        }
      });
    }
    const pTree = traversalTreeProjectLink(projectStore.listProjectLink);
    setTreeDataProjectLink(pTree)
  }

  const initTreeData = async () => {
    let treeData = projectStore.projectDetail.treeData || [];
    const newNodes = [];
    projectStore.modelList.filter(model => (model && !model.isDeleted)).map(model => {

      let node = TreeUtils.searchTreeNode(treeData, 'modelId', model._id);
      let refId = model._id
      if (model.sourceType === 'WMS') {
        const { hostname } = new URL(model.src);
        refId = `${hostname}-${model.data?.layers}`
      }

      if (!node) {
        const newNode = {
          title: model.name,
          key: uuid(),
          type: 'FILE',
          modelId: model._id,
          sourceType: model.sourceType,
          modelType: model.type,
          useService: model.useService,
          refId: refId,
          hash: model.hash,
          src: model.src,
          isVisible4D: model.isVisible4D,
          endDate: model.endDate,
          startDate: model.startDate,
          isVisibleClip: model.isVisibleClip,
          isDeleted: false,
        }
        treeData = addTreeNode(newNode, treeData, model.selectedKey);
        newNodes.push(newNode);
        node = TreeUtils.searchTreeNode(treeData, 'modelId', model._id);
      }
      if (node) {
        node.sourceType = model.sourceType
        node.refId = refId
        node.modelType = model.type
        node.ext = model.data.ext
        node.isVisible4D = model.isVisible4D
        node.endDate = model.endDate
        node.startDate = model.startDate
        node.isVisibleClip = model.isVisibleClip
      }
    })

    sketchingStore.arrSketches.map(sketch => {
      const node = TreeUtils.searchTreeNode(treeData, 'sketchId', sketch.id);
      if (!node) {
        const newNode = {
          title: sketch.name,
          key: uuid(),
          type: 'FILE',
          refId: sketch._id,
          sketchId: sketch._id,
          sketchType: sketch.type,
          readonlyHeight: sketch.readonlyHeight,
          isVisible4D: sketch.isVisible4D,
          endDate: sketch.endDate,
          startDate: sketch.startDate
        }
        treeData.push(newNode);
        newNodes.push(newNode)
      }
    })

    if (newNodes.length) {
      // await projectStore.updateProjectData({ treeData, store : 'treedata' }).then(async (res) => {
        await projectStore.updateProjectTreeData({ treeData, store : 'treedata' }).then(async (res) => {
        projectStore.projectDetail.treeData = res.treeData;
      })
    }
  }

  const mergeAccessTreeNode = (currentNode, parentNode) => {
    let result = currentNode || [];
    if (!parentNode && currentNode) {
      result = currentNode || []
    }
    if (!currentNode && parentNode) {
      result = parentNode || []
    }
    if (parentNode && currentNode) {
      result = mergeArrays(currentNode, parentNode)
    }
    return result;
  }

  const recursiveAccess = (node,groups,rights = []) => {
    let avaiableGroups = GroupUtils.checkAvaiableGroups(node, groups)
    let settings = mergeAccessTreeNode(rights,avaiableGroups)
    if(node.parentKey){
      let tTree = projectStore.projectDetail?.treeData || []
      let parrentNode = TreeUtils.searchTreeNode(tTree, 'key', node.parentKey);
      if(parrentNode){
        settings = recursiveAccess(parrentNode,groups,settings)
      }
    }
    return settings;
  }

  var currentGroup = userGroupStore.checkCurrentUserGroup(usersStore.currentUser, userGroupStore.groups);
  // compare current access rights to display icon at each treenode
  const checkVisibleIconUserGroups = (node, rights) => {
    if (!node) return false;
    let isShow = true;
    if (projectStore.currentUserRoleInProject === "project_owner") {
      return isShow;
    } else {
      if (node.type === "FOLDER" || node.type === "FILE") {
        isShow = true;
        let tTree = projectStore.projectDetail?.treeData || [];
        let userRights = GroupUtils.checkingUserRightsAtTreeNode(node,usersStore.currentUser,tTree) // user rights at treenode
        if (currentGroup && currentGroup?.length > 0) {
          let avaiableGroups = recursiveAccess(node, userGroupStore.groups) || [];
          let isExist = userGroupStore.findMostPowerfulGroup(currentGroup, avaiableGroups);
          if(isExist){
            let combineRights = userRights ? userRights : isExist;
            if (rights === 'both') {
              isShow =  (combineRights.rights === 'modify' || combineRights.rights === 'view') ? true : false;
            } else {
              isShow = combineRights.rights === rights ? true : false;
            }
          }else{
            if(userRights){
              if (rights === 'both') {
                isShow =  (userRights.rights === 'modify' || userRights.rights === 'view') ? true : false;
              } else {
                isShow =  userRights.rights === rights ? true : false;
              }
            }else{
              if (node?.type === "FILE" && !node.parentKey) {
                // isShow = false;
              }
            }
          }
        }else{
          if(userRights){
            if (rights === 'both') {
              isShow =  (userRights.rights === 'modify' || userRights.rights === 'view') ? true : false;
            } else {
              isShow =  userRights.rights === rights ? true : false;
            }
          }else{
            if (node?.type === "FILE" && !node.parentKey) {
              // isShow = false;
            }
          }
        }
        return isShow;
      }
    }
  };

  const checkAvailableDropNode = (node, dropPosition) => {
    if (!node) return false;
    if (projectStore.currentUserRoleInProject === "project_owner") {
      return true;
    } else {
      /**
       * if case is move to bottom of file or folder, check parent...
       */
      if ((node.type === "FILE" || node.type === "FOLDER") && dropPosition === 1) {
        // check parent of this node -> check parent node
        if (node?.parentKey) {
          const parentNode = TreeUtils.searchTreeNode(treeData, 'key', node?.parentKey);
          return checkVisibleIconUserGroups(parentNode, "modify")
        } else {
          return checkVisibleIconUserGroups(node, "modify") || dropPosition === 1;
        }
      }
      // case if node is folder and move into this
      if (node.type === "FOLDER" && dropPosition === 0 && checkVisibleIconUserGroups(node, "modify")) {
        return true
      }

    }
  }

  const findTreeNodes = (ids) => {
    let result = []
    for (let index = 0; index < ids.length; index++) {
      const element = ids[index];
      const node = TreeUtils.searchTreeNode(treeData, 'modelId', element);
      if (node) {
        result.push(node)
      }
    }
    return result
  }
  
  const isAllChildrenNoRights = node => {
    let isShow = true;
    if (node.type === 'FOLDER') {
      let allElements = GroupUtils.findAllTreeNode(node.children || [node])
      let _accessAllChildren = allElements.every(c => checkVisibleIconUserGroups(c, "modify"))
      isShow = _accessAllChildren ? true : false;
    }
    return isShow;
  }

  useEffect(() => {
    if (treeData) {
      treeData._v = (new Date()).getTime()
    }
    setTreeData(treeData)
  }, [projectStore.visibleTilesets, projectStore.showEditLocation])

  useEffect(() => {
    if (treeData) {
      treeData._v = (new Date()).getTime()
    }
    setTreeData(treeData)
  }, [sketchingStore.visibleSketches, projectStore.showEditLocation])


  useEffect(() => {
    if ((!projectStore.selectedNode || (projectStore.selectedNode && !projectStore.selectedNode.modelId)) && !projectStore.isSelectedNode) {
      projectStore.setSelectedNode()
    }
    const pTreeData = TreeUtils.filterTreeDataActive(projectStore.projectDetail.treeData)
    if (pTreeData) {

      const pTree = traversalTree(pTreeData, null);
      loadedKeys.map(loadedKey => {
        const pTreeNode = TreeUtils.searchTreeNode(pTree, 'key', loadedKey);

        if (pTreeNode && isModel(pTreeNode)) {
          const loadedNode = TreeUtils.searchTreeNode(treeData, 'key', loadedKey);
          if (loadedNode)
            pTreeNode.children = loadedNode.children;
        }
      })
      // Filter treenode by user group
      const filterInitialTrees = (nodes) => {
        if (nodes) {
          return nodes.filter((node) => {
            let isShow = true;
            if ((node.type === 'FILE' || node.type === 'FOLDER') && userGroupStore.nodeNoAccessRights.some(c => c.key === node.key)) {
              isShow = false;
            }
            if (node.children) {
              node.children = filterInitialTrees(node.children);
            }
            return isShow;
          });
        }
        return []
      }

      if (projectStore.currentUserRoleInProject !== "project_owner") {
        let _pTree = filterInitialTrees(pTree)
        setTreeData(traversalTree(_pTree, null))
      } else {
        setTreeData(pTree)
      }
    }
  }, [projectStore.projectDetail.treeData, deleteStatus])


  const showModalAddFolder = () => {
    if (!projectStore.selectedNode || isFolder(projectStore.selectedNode)) {
      projectStore.setShowAddFolderModal(true);
      projectStore.setFolderEdit()
    }
  }

  const handleDetete = async () => {
    const startTime = Date.now();
    const arrDeleteModel = [];
    const arrDeleteSketch = [];
    const preTreeData =projectStore.projectDetail?.treeData.map(c => toJS(c))
    const { treeData } = projectStore.projectDetail;
    if (projectStore.selectedNode) { //case selected node
      const { parentKey, key } = projectStore.selectedNode;
      await removeTreeNode(treeData, parentKey, key, arrDeleteModel, arrDeleteSketch)
    } else if (checkedNodes) { //case checked node
      checkedNodes.map(async node => {
        if (node.type && node.type !== 'EMPTY') {
          const { parentKey, key } = node;
          await removeTreeNode(treeData, parentKey, key, arrDeleteModel, arrDeleteSketch)
        }
      })
    } else {
      return message.error(t('please-select-one-node'))
    }

    projectStore.setLoadingProgress(true);
    try {
      if (arrDeleteModel && arrDeleteModel.length > 0) {
        let _deletetree = {
          projectId: projectStore.projectDetail._id,
          deleteData: arrDeleteModel
        }
        let resp = await projectStore.deleteMultiModels(_deletetree);
        if (resp) {
          resp.model3DS = projectStore.modelList.filter(x => !arrDeleteModel.find(y => y.modelId === x._id))
          await projectStore.updateProjectRefPoint(resp)
          const modelIds = arrDeleteModel.map((item) => {
            return item.modelId;
          }).filter(function (element) {
            return element !== undefined;
          });
          let filteredModels = projectStore.modelList.filter(model => !modelIds.includes(model._id));
          projectStore.setModelList(filteredModels);
          projectStore.setDeleteIds(modelIds)
          projectStore.setRebuildModel(true) // for effect load updatePlaneMatix        
        }
      }

      if (arrDeleteSketch && arrDeleteSketch.length > 0) {
        let _deletetree = {
          projectId: projectStore.projectDetail._id,
          deleteData: arrDeleteSketch
        }
        let resp = await sketchingStore.deleteSketchObject(_deletetree);
        if (resp) {
          const sketchIds = arrDeleteSketch.map((item) => {
            return item.sketchId;
          }).filter(function (element) {
            return element !== undefined;
          });

          sketchingStore.setSketches(sketchingStore.arrSketches.filter((sketch) => !sketchIds.includes(sketch._id.toString())))
        }
      }
      projectStore.setLoadingProgress(false);
      message.success(t('deleted-successfully'))
      setSelectedKeys([])
      setCheckedKeys([])
      setCheckedNodes([])
      projectStore.setSelectedNode()
      commonStore.loggingFunction('Delete treenode','success',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
    } catch (error) {
      projectStore.setLoadingProgress(false);
      message.error(error && error.statusText ? error.statusText : 'Error')
      commonStore.loggingFunction('Delete treenode','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
      // recover treedata when delete fail
      projectStore.setProjectDetail({
        ...projectStore.projectDetail,
        treeData : preTreeData
      })
    }
  }

  const removeTreeNode = async (data, parentKey, key, arrDeleteModel, arrDeleteSketch) => {
    let deleteNode;
    setDeleteStatus(!deleteStatus)
    if (parentKey) {
      const node = TreeUtils.searchTreeNode(data, 'key', parentKey);
      if (node) {
        const index = node.children.findIndex(child => child.key === key);
        if (index >= 0) {
          deleteNode = node.children[index];
          node.children.splice(index, 1);
        }
      }
    } else {
      if (data.length > 0) {
        const index = data.findIndex(item => item.key === key);
        if (index >= 0) {
          deleteNode = data[index];
          data.splice(index, 1);
        }
      }
    }
    if (deleteNode) {
      deleteTilesetsAndSketches([deleteNode], arrDeleteModel, arrDeleteSketch);
    }
  }

  const deleteTilesetsAndSketches = (data, arrDeleteModel = [], arrDeleteSketch = []) => {
    data.map(item => {
      if (item) {
        if (item.modelId) {
          arrDeleteModel.push({ modelId: item.modelId, treeNodeKey: item.key })
        }
        if (item.sketchId) {
          arrDeleteSketch.push({ sketchId: item.sketchId, treeNodeKey: item.key })
        }
        if (item.children) {
          item.children.push({ // push Folder to children to update Folder isDeleted 
            isDeleted: item.isDeleted,
            key: item.key,
            title: item.title,
            type: item.type
          })
          deleteTilesetsAndSketches(item.children, arrDeleteModel, arrDeleteSketch)
        }
        if (!item.children && item.type === 'FOLDER') {//case empty folder   
          arrDeleteModel.push({ modelId: undefined, treeNodeKey: item.key })
        }
      }
    })
  }

  const addResourceClick = () => {
    if (!fileStore.modalDrop3DView) {
      projectStore.setShowAddResourceModel(true)
      projectStore.setCurrentAddTab('uploadTab')
      projectStore.setDisplayPanel(false)
    } else {
      notification.open({
        message: t('please-wait-or-close-the-file-drag-and-drop'),
        icon: <InfoCircleOutlined style={{ color: '#faad14' }} />,
        duration: 5,
      })
    }
  }

  const updateResourceClick = (type) => {
    projectStore.setShowUpdateResourceModel(true)
    projectStore.setDisplayPanel(false)
    projectStore.setIsEditModel(type)
    if (isFolder(projectStore.selectedNode)) {
      projectStore.setCurrentAddTab('folder')
      return
    }
    const { sourceType, modelId } = projectStore.selectedNode;
    const model = projectStore.modelList.find(model => model._id === modelId)
    if (sourceType === 'local') {
      projectStore.setDeleteIds([])
      projectStore.setCurrentAddTab('uploadTab')
    } else if (sourceType === 'external' && !model.data.cesiumToken) {
      projectStore.setCurrentAddTab('externalLink')
    } else if (sourceType === 'external' && model.data.cesiumToken) {
      projectStore.setCurrentAddTab('cesiumIon')
    } else if (sourceType === 'WMS') {
      projectStore.setFileEdit(projectStore.selectedNode);
      projectStore.setCurrentAddTab('WMS')
    } else if (sourceType === 'WFS') {
      projectStore.setFileEdit(projectStore.selectedNode);
      projectStore.setCurrentAddTab('WFS')
    } else if (sourceType === 'City3DDB') {
      projectStore.setFileEdit(projectStore.selectedNode);
      projectStore.setCurrentAddTab('City3DDB')
    }
  }

  const clickObjectInfo = async () => {
    const model3D = findModel3DByKey(projectStore.selectedNode.key);
    uiStore.setShowAttrPanel(true);
    projectStore.setLoadingAttrData(true);
    let model = projectStore.modelList.find(x => x._id === model3D)
    if (model?.crs?.Version || model.crs?.tilesetVersion) { //model.crs?.tilesetVersion for oldmodel, new model change to Version
      let fileid = model.hash ? (model.hash?.split("/").length > 1 ? model.hash.split("/")[1].trim() : model.hash.split("/")[0].trim()) : undefined;
      if (fileid) {
        const { data: attrData } = await modelStore.getTreeGuid({ guid: projectStore.selectedNode.GUID, fileid, level: 10, modelType: model.type });
        modelStore.setModelAttribute({ id: model3D, version: 1, GUID: projectStore.selectedNode.GUID, modelType: model.type })
        projectStore.setSelectedAttrData(attrData || []);
      } else {
        projectStore.setLoadingAttrData(false);
      }
    } else {
      const { data: attrData } = await ModelAttributeRequest.getAttributByGUID(projectStore.selectedNode.key, model3D);
      modelStore.setModelAttribute({ id: model3D, version: 0, GUID: projectStore.selectedNode.key })
      projectStore.setSelectedAttrData(attrData);
    }
  }

  const handleRebuildModel = async () => {
    const startTime = Date.now();
    setTreeLoading(true);
    await projectStore.rebuildProjectModel({ type: "model", list: listModelRebuild }).then(async res => {
      if (res) {
        commonStore.loggingFunction('Rebuild model','success',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
        const listModelIon = res?.model?.filter(x => x?.data?.ionAssetId) || [];
        if (listModelIon.length > 0) {
          await Promise.all(listModelIon.map(async item => {
            if (item.data.ionAssetId) {
              await waitUntilIonReadyData(item.data.ionAssetId, item?.id)
              return true
            }
          }))
        }
        const fkeys = listModelRebuild.map(item => item + '-tile')
        const fkeys_aligment = listModelRebuild.map(item => item + '-alignment')
        listModelRebuild.map(item => {
          if (projectStore.modelFeatures?.[item])
            delete projectStore.modelFeatures[item]
        })
        const oldVisibleTilesets = toJS(projectStore.visibleTilesets).find(t => fkeys.includes(t.modelId + '-tile')) // track old state before rebuild
        projectStore.setStateBeforeRebuild(oldVisibleTilesets) // save them to store and call in attributePanel

        projectStore.setTileViews(projectStore.tileViews.filter(t => !fkeys.includes(t.key))) // remove models from viewer when rebuild
        projectStore.setAlignment(projectStore.Alignment.filter(t => !fkeys_aligment.includes(t.key))) // remove aligment from viewer when rebuild

        const temp = [...projectStore.modelList.filter(t => !listModelRebuild.includes(t._id))]
        projectStore.setModelList(temp) // call useEffect projectStore.modelList in projectDetailPage reload TilesetByModel

        let _message = { DisplayText: res.model.length > 0 ? res.model.map(function (elm) { return `- ${t('model')}: ${elm.model} ${t('rebuild')}: ${elm.status === 'Success' ? '<span style="color:#07a907">' + t('success' || elm.status) + '</span>' : '<span style="color:#ff0000">' + t('error' || elm.status) + '</span>'}` }).join("<br />") : null }
        notification.open({
          message: t('rebuild-model'),
          description: (
            <>
              {renderHTML(_message.DisplayText)}
            </>
          ),
          icon: <InfoCircleOutlined style={{ color: '#369d04' }} />,
          duration: 0,
          placement: 'top',
          className: 'custom-notivication'
        })

        /**Update FE projectStore.ProjectDetail */
        await projectStore.updateProjectRefPoint(res.project)
        projectStore.setRebuildModel(true) //effect in project detail reload tileset
        projectStore.setReloadAlignment(true); // effect in project detail reload Alignment
      } else {
        message.error(t('rebuild-failed-please-try-again'))
        commonStore.loggingFunction('Rebuild model','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
      }
      setTreeLoading(false);
    }).catch(error => {
      console.log(error)
      message.error(t('rebuild-failed-please-try-again'))
      setTreeLoading(false);
      commonStore.loggingFunction('Rebuild model','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
    })
  }


  useEffect(() => {
    if (checkedNodes && checkedNodes.length > 0) {
      let listModels = checkedNodes.filter(node => node.modelType && node.sourceType !== 'WMS' && node.sourceType !== 'WFS' && node.sourceType !== "external" && node.sourceType !== "City3DDB").map(node => node.modelId)
      let notModelIdRebuild = checkedNodes.filter(node => node.sketchId || node.sourceType === 'WMS' || node.sourceType === 'WFS' || node.sourceType === "external" || node.sourceType === "City3DDB").map(node => node.modelId || node.sketchId)
      setListModelRebuild([...new Set(listModels)])
      setIsNotModel(notModelIdRebuild)
    } else {
      setListModelRebuild([])
    }
    if (projectStore.selectedNode) {
      let listModels = []
      let modelIds = []
      let notModelIdRebuild = []
      listModels.push(projectStore.selectedNode)
      let getNestedPath = (items, modelIds) => {
        items.forEach((item) => {
          if (!item.children) { // return soon
            if (item.modelId && item.sourceType !== 'WMS' && item.sourceType !== 'WFS' && item.sourceType !== "external" && item.sourceType !== "City3DDB") {
              modelIds.push(item.modelId)
            }
            if (item.sourceType === 'WMS' || item.sourceType === 'WFS' || item.sourceType === "external" || item.sourceType === "City3DDB") {
              notModelIdRebuild.push(item.modelId)
            }
            if (item.sketchId) {
              notModelIdRebuild.push(item.sketchId)
            }
            return
          }
          getNestedPath(item.children, modelIds)
        });
      }
      getNestedPath(listModels, modelIds)
      setListModelRebuild([...new Set(modelIds)])
      setIsNotModel(notModelIdRebuild)
    }
  }, [checkedNodes, projectStore.selectedNode])

  const downloadUserFile = id => {
    const _model = projectStore.modelList.find(item => {
      return item._id === id
    })
    if (_model)
      window.open(_model.src, '_blank')
  }
  const toggleVisibleTileset = (tilesetId, visible) => {
    if (visible) {
      projectStore.setIsRerenderFeatureTile(true)
    }
    const _visiblemodel = projectStore.visibleTilesets.map(item => {
      if (item.modelId === tilesetId) {
        item.isVisible = !item.isVisible
        item.isTempHidden = undefined
      }
      return item
    })
    projectStore.setVisibleTilesets(_visiblemodel)

    //save visibleTilesets to project
    if (usersStore.currentUser && usersStore.currentUser._id) {
      let _projectMetadata = projectStore.projectDetail.metadata
      if (_projectMetadata && _projectMetadata.dataTreeStatus && _projectMetadata.dataTreeStatus.length > 0) {
        let indexdataTreeStatus = projectStore.projectDetail.metadata.dataTreeStatus.findIndex(item => item.userId === usersStore.currentUser._id)
        if (indexdataTreeStatus > -1) {
          const temp = [..._projectMetadata.dataTreeStatus]
          delete temp[indexdataTreeStatus].visibleTilesets
          _projectMetadata.dataTreeStatus = temp
        } else {
          let _dataTreeStatus = { userId: usersStore.currentUser._id, treeStatus: [] }
          _projectMetadata.dataTreeStatus.push(_dataTreeStatus)
        }
      } else {
        _projectMetadata.dataTreeStatus = [{ userId: usersStore.currentUser._id, treeStatus: [] }]
      }
      delete _projectMetadata.timeSlider
      // _projectMetadata.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
      delete _projectMetadata.modelHiden
      delete _projectMetadata.visibleClipModels
      let newData = {
        metadata: _projectMetadata
      }
      projectStore.updateProjectData(newData).then((res) => {
        projectStore.projectDetail.metadata = res.metadata
      }).catch(err => {
        console.log(err)
      })
    }
  }

  const toggleVisibleFolder = async (treeData, key) => {
    const node = TreeUtils.searchTreeNode(treeData, 'key', key);
    if (node?.children) {
      const { nodeVisibles } = checkVisibleFolder(node.children);
      await handleVisibleFolder(node.children, !!nodeVisibles.length);
      //save visibleTilesets to project
      if (usersStore.currentUser && usersStore.currentUser._id) {
        let _projectMetadata = projectStore.projectDetail.metadata
        if (_projectMetadata && _projectMetadata.dataTreeStatus && _projectMetadata.dataTreeStatus.length > 0) {
          let indexdataTreeStatus = projectStore.projectDetail.metadata.dataTreeStatus.findIndex(item => item.userId === usersStore.currentUser._id)
          if (indexdataTreeStatus > -1) {
            const temp = [..._projectMetadata.dataTreeStatus]
            delete temp[indexdataTreeStatus].visibleTilesets
            _projectMetadata.dataTreeStatus = temp
          } else {
            let _dataTreeStatus = { userId: usersStore.currentUser._id, treeStatus: [] }
            _projectMetadata.dataTreeStatus.push(_dataTreeStatus)
          }
        } else {
          _projectMetadata.dataTreeStatus = [{ userId: usersStore.currentUser._id, treeStatus: [] }]
        }
        delete _projectMetadata.timeSlider
        delete _projectMetadata.modelHiden
        delete _projectMetadata.visibleClipModels         
        // _projectMetadata.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
        let newData = {
          metadata: _projectMetadata
        }
        projectStore.updateProjectData(newData).then((res) => {
          projectStore.projectDetail.metadata = res.metadata
        }).catch(err => {
          console.log(err)
        })
      }
      //=============
    }
  }
  const handleVisibleFolder = (data, visible) => {
    let _visiblemodel = projectStore.visibleTilesets;
    let _visibleSketch = sketchingStore.visibleSketches;
    if (visible) {
      projectStore.setIsRerenderFeatureTile(true)
    }
    data.map(node => {
      if (node.children) {
        handleVisibleFolder(node.children, visible)
      }
      if (isModel(node)) {
        _visiblemodel = _visiblemodel.map(item => {
          if (item.modelId === node.modelId) {
            item.isVisible = !visible
          }
          return item
        })
        projectStore.setVisibleTilesets(_visiblemodel)
      }
      if (isSketch(node)) {
        _visibleSketch = _visibleSketch.map(item => {
          if (item.sketchId === node.sketchId) {
            item.isVisible = !visible
          }
          return item
        })
        sketchingStore.setVisibleSketches(_visibleSketch)
      }
    })
  }

  //#region Visiable 4D Folder
  const toggleVisible4DFolder = async (treeData, key) => {
    const node = TreeUtils.searchTreeNode(treeData, 'key', key);
    if (node?.type === 'FOLDER') {
      if (usersStore.currentUser && usersStore.currentUser._id) {
        let allFolders = projectStore.getFoldersWithChildren()
        const isNodeExist = projectStore.visible4DFolders.find( c => c.key ===node.key);
        if (allFolders?.length > 0) {
          let _visiblesFolder = allFolders.map(function (item) {
            const isExist = projectStore.visible4DFolders.find( c => c.key ===item.key);
            if(isExist){
              if(isNodeExist?.key ===isExist.key ){
                return  {
                  ...isExist,
                  startDate : item.startDate,
                  endDate : item.endDate,
                  isVisible4D : !isExist.isVisible4D
                }
              }
              return isExist;
            }else{
              return {
                title : item.title,
                parentKey : item.parentKey,
                key : item.key,
                isVisible4D : false,
                startDate : item.startDate,
                endDate : item.endDate
              }
            }
           });
           projectStore.setVisible4DFolders(_visiblesFolder)
         }else{
          projectStore.setVisible4DFolders([])
         }
      }
    }
  }

  //#region Visiable clip Folder
  const isVisibleClipFolder = (treeData, key) => {
    const node = TreeUtils.searchTreeNode(treeData, 'key', key);
    if (!node || !node.children) return true;
    const { nodeVisibles, nodeCanVisibles } = checkVisibleClipFolder(node.children);
    return nodeVisibles?.length || !nodeCanVisibles.length;
  }

  const checkVisibleClipFolder = (data, nodeVisibles = [], nodeCanVisibles = []) => {
    data.map(item => {
      if (item.children) {
        checkVisibleClipFolder(item.children, nodeVisibles, nodeCanVisibles)
      }
      if (isModel(item)) {
        nodeCanVisibles.push(item);
      }
      if ((isModel(item) && isVisibleClipIcon(item.modelId))) {
        nodeVisibles.push(item)
      }
    })
    return {
      nodeVisibles,
      nodeCanVisibles
    };
  }

  const toggleVisibleClipFolder = async (treeData, key) => {
    const node = TreeUtils.searchTreeNode(treeData, 'key', key);
    if (node?.children) {
      const { nodeVisibles } = checkVisibleClipFolder(node.children);
      await handleVisibleClipFolder(node.children, !!nodeVisibles.length);

      // save expand key to project
      if (usersStore.currentUser && usersStore.currentUser._id) {
        let _projectMetadata = projectStore.projectDetail.metadata
        if (_projectMetadata && _projectMetadata.dataTreeStatus && _projectMetadata.dataTreeStatus.length > 0) {
          let indexdataTreeStatus = projectStore.projectDetail.metadata.dataTreeStatus.findIndex(item => item.userId === usersStore.currentUser._id)
          if (indexdataTreeStatus > -1) {
            const temp = [..._projectMetadata.dataTreeStatus]
            delete temp[indexdataTreeStatus].visibleTilesets
            _projectMetadata.dataTreeStatus = temp
          } else {
            let _dataTreeStatus = { userId: usersStore.currentUser._id, treeStatus: [] }
            _projectMetadata.dataTreeStatus.push(_dataTreeStatus)
          }
        } else {
          _projectMetadata.dataTreeStatus = [{ userId: usersStore.currentUser._id, treeStatus: [] }]
        }
        delete _projectMetadata.timeSlider
        delete _projectMetadata.modelHiden
        delete _projectMetadata.visibleClipModels
        // _projectMetadata.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
        var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
        if (retrievedObject && retrievedObject.projectId === projectStore.projectDetail._id && usersStore.currentUser && retrievedObject.userId === usersStore.currentUser._id && projectStore.projectDetail.currentUser) {
          retrievedObject.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
          if (usersStore.currentUser && usersStore.currentUser._id) {
            if (!projectStore.isEditSessionVisibleData)
              localStorage.setItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`, JSON.stringify(retrievedObject));
          }
        }
        let newData = {
          metadata: _projectMetadata
        }
        projectStore.updateProjectData(newData).then((res) => {
          projectStore.projectDetail.metadata = res.metadata
        }).catch(err => {
          console.log(err)
        })
      }
      //=============
    }
  }

  const handleVisibleClipFolder = (data, visible) => {
    data.map(node => {
      if (node.children) {
        handleVisibleClipFolder(node.children, visible)
      }
      if (isModel(node)) {
        const _visiblemodel = projectStore.visibleTilesets.map(item => {
          if (item.modelId === node.modelId) {
            item.isVisibleClip = !item.isVisibleClip
          }
          return item
        })
        projectStore.setVisibleTilesets(_visiblemodel)
      }
    })
  }
  //#endregion

  const onSavePriviousCameraData = () =>{
    if (usersStore.currentUser && usersStore.currentUser._id) {
      saveCurrentViewpoint(usersStore.currentUser._id, viewer, projectStore);
    }
  }

  const clickTileset = modelId => {
    if (projectStore.showEditLocation) return
    projectStore.setCurrentModelId(modelId)
    projectStore.setZoomToModel(modelId)
    // projectStore.setClippingMode(false)
  }

  const clickEditLocation = modelId => {
    onSavePriviousCameraData();
    let temp = {}
    temp[modelId] = false
    // setTilesetSettingVisible(temp)
    projectStore.setCurrentModelId(modelId);
    projectStore.setShowEditLocation(projectStore.modelList.find(model => model._id === modelId), true)
    projectStore.setDisplayPanel(false)

    //Show again model if it hide for case click edit model
    //const v = projectStore.visibleTilesets ? projectStore.visibleTilesets : []
    // const _visiblemodel = v.map(item => {
    //   if (item.modelId === modelId && item.isVisible === false) {
    //     item.isVisible = true
    //   }
    //   return item
    // })
    //projectStore.setVisibleTilesets(_visiblemodel)
  }

  // Sketch
  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
    let visible4DModels = retrievedObject && retrievedObject.visible4DModels ? retrievedObject.visible4DModels : []
    let temp = toJS(sketchingStore.arrSketches).map(item => {
      let isExist4D = visible4DModels.find(c => c.type4D === 'sketch' && c.sketchId === item._id)
      return { sketchId: item._id, isVisible: true, isVisible4D: (isExist4D && isExist4D.isVisible4D ? isExist4D.isVisible4D : true), endDate: item.endDate, startDate: item.startDate }
    })
    if (sketchingStore.visibleSketches.length > 0) {
      let _visibleSketch = temp.filter(function (newData) {
        return sketchingStore.visibleSketches.filter(function (oldData) {
          if (
            newData.sketchId === oldData.sketchId &&
            newData.isVisible !== oldData.isVisible
          ) {
            newData.isVisible = oldData.isVisible
          }
        })
      })
      sketchingStore.setVisibleSketches(_visibleSketch)
    } else {
      sketchingStore.setVisibleSketches(temp)
    }
  }, [sketchingStore.arrSketches])

  const toggleVisibleSketch = sketchId => {
    const _visibleSketch = sketchingStore.visibleSketches.map(item => {
      if (item.sketchId === sketchId) {
        item.isVisible = !item.isVisible
      }
      return item
    })
    sketchingStore.setVisibleSketches(_visibleSketch)
  }

  const toggleVisibleSketch4DIcon = sketchId => {

    //sketch
    const _visibleSketch = sketchingStore.visibleSketches.map(item => {
      if (item.sketchId === sketchId) {
        item.isVisible4D = !item.isVisible4D
      }
      return item
    })
    sketchingStore.setVisibleSketches(_visibleSketch)

    //model 3d 
    const _visiblemodel = projectStore.visibleTilesets.map(item => {
      if (item.modelId === sketchId) {
        item.isVisible4D = !item.isVisible4D
      }
      return item
    })
    projectStore.setVisibleTilesets(_visiblemodel)

    if (schedulingStore.timeSliderVisible && schedulingStore.currentViewingTime && schedulingStore.currentViewingTime.toDate) {
      schedulingStore.setTarget4DIcon(!schedulingStore.target4DIcon)
    }
  }
  const toggleVisibleClipIcon = modelid => {
    const _visiblemodel = projectStore.visibleTilesets.map(item => {
      if (item.modelId === modelid) {
        item.isVisibleClip = !item.isVisibleClip
      }
      return item
    })
    projectStore.setVisibleTilesets(_visiblemodel)

    //save expand key to project
    if (usersStore.currentUser && usersStore.currentUser._id) {
      let _projectMetadata = projectStore.projectDetail.metadata
      if (_projectMetadata && _projectMetadata.dataTreeStatus && _projectMetadata.dataTreeStatus.length > 0) {
        let indexdataTreeStatus = projectStore.projectDetail.metadata.dataTreeStatus.findIndex(item => item.userId === usersStore.currentUser._id)
        if (indexdataTreeStatus > -1) {
          const temp = [..._projectMetadata.dataTreeStatus]
          delete temp[indexdataTreeStatus].visibleTilesets
          _projectMetadata.dataTreeStatus = temp
        } else {
          let _dataTreeStatus = { userId: usersStore.currentUser._id, treeStatus: [] }
          _projectMetadata.dataTreeStatus.push(_dataTreeStatus)
        }
      } else {
        _projectMetadata.dataTreeStatus = [{ userId: usersStore.currentUser._id, treeStatus: [] }]
      }
      delete _projectMetadata.timeSlider
      delete _projectMetadata.modelHiden
      delete _projectMetadata.visibleClipModels
      // _projectMetadata.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
      var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
      if (retrievedObject && retrievedObject.projectId === projectStore.projectDetail._id && usersStore.currentUser && retrievedObject.userId === usersStore.currentUser._id && projectStore.projectDetail.currentUser) {
        retrievedObject.visibleClipModels = projectStore.visibleTilesets.filter(c => !c.isVisibleClip)
        if (usersStore.currentUser && usersStore.currentUser._id) {
          if (!projectStore.isEditSessionVisibleData)
            localStorage.setItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`, JSON.stringify(retrievedObject));
        }
      }
      let newData = {
        metadata: _projectMetadata
      }
      projectStore.updateProjectData(newData).then((res) => {
        projectStore.projectDetail.metadata = res.metadata
      }).catch(err => {
        console.log(err)
      })
    }
  }

  const toggleVisible = () => {
    let _visiblemodel = projectStore.visibleTilesets;
    let _visibleSketch = sketchingStore.visibleSketches;
    const visible = checkVisibleCheckedNodes();
    if (visible) {
      projectStore.setIsRerenderFeatureTile(true)
    }
    checkedNodes.map(node => {
      if (isModel(node)) {
        _visiblemodel = _visiblemodel.map(item => {
          if (item.modelId === node.modelId) {
            item.isVisible = !visible
          }
          return item
        })
      } else if (isSketch(node)) {
        _visibleSketch = _visibleSketch.map(item => {
          if (item.sketchId === node.sketchId) {
            item.isVisible = !visible
          }
          return item
        })
      }
    })
    projectStore.setVisibleTilesets(_visiblemodel)
    sketchingStore.setVisibleSketches(_visibleSketch)
  }

  const checkVisibleCheckedNodes = () => {
    const check = checkedNodes.map(node => {
      if (isModel(node)) {
        return isVisibleModel(node.modelId)
      } else if (isSketch(node)) {
        return isVisibleSketch(node.sketchId)
      }
      return null
    })
    const acCheck = check.filter(c => c != null)
    const hasHide = acCheck.some(x => !x)
    const hasVisible = acCheck.some(x => x)
    const allVisible = acCheck.every(x => x)
    return (hasHide && hasVisible) || allVisible
  }

  const isVisibleSketch = (sketchId) => {
    return sketchingStore.visibleSketches.length > 0 && sketchingStore.visibleSketches.some(el => el.sketchId === sketchId && el.isVisible === true)
  }

  const isVisibleSketch4DIcon = (sketchId) => {
    return sketchingStore.visibleSketches.length > 0 && sketchingStore.visibleSketches.some(el => el.sketchId === sketchId && el.isVisible4D === true)
  }

  const isVisibleModel4DIcon = (modelId) => {
    return projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0 && projectStore.visibleTilesets.some(el => el.modelId === modelId && el.isVisible4D === true)
  }

  const isVisibleClipIcon = (modelId) => {
    return projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0 && projectStore.visibleTilesets.some(el => el.modelId === modelId && el.isVisibleClip === true)
  }

  const isVisibleModel = (modelId) => {
    return projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0 && projectStore.visibleTilesets.some(el => el.modelId === modelId && el.isVisible === true)
  }

  const isVisible4DFolders = (key) => {
    let isVisible = projectStore.visible4DFolders && projectStore.visible4DFolders.length > 0 && projectStore.visible4DFolders.some(el => el.key === key && el.isVisible4D === true)
    return isVisible;
  }

  const isVisibleProjectLink = (projectLinkId) => {
    return projectStore.listProjectLink && projectStore.listProjectLink.length > 0 && projectStore.listProjectLink.some(el => el.id === projectLinkId && el.isVisible === true)
  }

  const isVisibleFeedbackIcon = (fbId) => {
    return feedbackStore.visibleFeedback?.length > 0 && feedbackStore.visibleFeedback.some(el => el.controlName === fbId && el.isShow === true)
  }

  const isVisibleFeedbackFormIcon = (fbId) => {
    return feedbackStore.visibleFeedbackForm && feedbackStore.visibleFeedbackForm.some(el => el.controlName === fbId && el.isShow === true)
  }

  const isVisibleTopic = (topic) => {
    return topicStore.topicList.length === 0 || (topicStore.visibleTopic?.length > 0 && topicStore.visibleTopic.some(el => el.controlName === topic && el.isShow === true))
  }

  const checkHas4DTimeStamp = (data) => {
    let isShowIcon = true
    // const checkTimeValid = (a, b) => {
    //   let isBefore
    //   if (a && b) {
    //     if (moment(a).isBefore(b)) {
    //       return isBefore = true
    //     } else {
    //       return isBefore = false
    //     }
    //   } else {
    //     isBefore = false
    //   }
    //   return isBefore
    // }
    if (!data.startDate && !data.endDate) {
      isShowIcon = false
    }
    // console.log('data', toJS(data));
    // console.log('isShowIcon', toJS(isShowIcon));
    return isShowIcon
  }

  const isVisibleFolder = (treeData, key) => {
    const node = TreeUtils.searchTreeNode(treeData, 'key', key);
    if (!node || !node.children) return true;
    const { nodeVisibles, nodeCanVisibles } = checkVisibleFolder(node.children);
    return nodeVisibles?.length || !nodeCanVisibles.length;
  }

  const checkVisibleFolder = (data, nodeVisibles = [], nodeCanVisibles = []) => {
    data.map(item => {
      if (item.children) {
        checkVisibleFolder(item.children, nodeVisibles, nodeCanVisibles)
      }
      if (isModel(item) || isSketch(item)) {
        nodeCanVisibles.push(item);
      }
      if ((isModel(item) && isVisibleModel(item.modelId)) || (isSketch(item) && isVisibleSketch(item.sketchId))) {
        nodeVisibles.push(item)
      }
    })
    return {
      nodeVisibles,
      nodeCanVisibles
    };
  }

  const clickSketch = sketchId => {
    if (projectStore.showEditLocation) return
    sketchingStore.setCurrentSketchId(sketchId)
    sketchingStore.setZoomToSketch(sketchId)
    // projectStore.setDisplayPanel(false)
  }

  const clickEditSketch = sketchId => {
    //reset point dragger and editor
    if (sketchingStore.currentSketchId !== sketchId) {
      if (sketchingStore.currentSketchId) {
        sketchingStore.setResetSketch(sketchingStore.currentSketchId)
      }
      sketchingStore.setSketchProps({ pointDragger: [] })
      if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
        sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
      }
      if (sketchingStore.currentEditEntity?.currentEditEntity?.position && sketchingStore.currentEditEntity?.currentEditEntity?.drawData === "point") {
        sketchingStore.currentEditEntity.currentEditEntity.position.setCallback(sketchingStore.currentEditEntity.currentEditEntity.position._callback, true);
      }

      if (sketchingStore.currentEditEntity?.currentEditEntity?.polygon?.hierarchy && sketchingStore.currentEditEntity?.currentEditEntity?.drawData === "area") {
        sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy.setCallback(sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy._callback, true);
      }

      if (sketchingStore.currentEditEntity?.currentEditEntity && sketchingStore.currentEditSketch?.drawData === "line") {
        let entity = sketchingStore.currentEditEntity.currentEditEntity
        if (sketchingStore.currentEditEntity.currentEditEntity?.polyline) {
          entity.polyline.positions.setCallback(entity.polyline.positions._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.polylineVolume) {
          entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, true);
          entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.wall) {
          entity.wall.positions.setCallback(entity.wall.positions._callback, true);
          entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, true);
          entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.corridor) {
          entity.corridor.positions.setCallback(entity.corridor.positions._callback, true);
        }
      }
      sketchingStore.setCurrentEditEntity(false)
    }
    //reset olg sketchgeometry
    if (sketchingStore.currentEditSketch && sketchId != sketchingStore.currentEditSketch.id) {
      sketchingStore.setResetGeometrySketch(sketchingStore.currentEditSketch)
    }
    //close attribute,open edit dialog
    projectStore.setGlobeBehind(false)
    sketchingStore.setGeometrySketch(false)
    sketchingStore.setSketchFormVisible(true)
    projectStore.setDisplayPanel(false)
    uiStore.setShowAttrPanel(false)
    //zoom and save current edit sketch information
    sketchingStore.setZoomToSketch(sketchId)
    sketchingStore.setCurrentEditSketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
    sketchingStore.setCurrentSketchId(sketchId)
    const selectedNode = TreeUtils.searchTreeNode(traversalTree(projectStore.projectDetail.treeData), 'sketchId', sketchId);
    projectStore.setSketchEdit(selectedNode);
    sketchingStore.setGeometrySketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
    onSavePriviousCameraData();
  }

  // const clickEditSketch = sketchId => {
  //   projectStore.setGlobeBehind(false)
  //   // clear  edit sketch geometry
  //   if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
  //     sketchingStore.setSketchProps({ pointDragger: [] })
  //     sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
  //     sketchingStore.setCurrentEditEntity(false)
  //   }
  //   sketchingStore.setGeometrySketch(false)


  //   projectStore.setLoadingProgress(true)
  //   sketchingStore.setZoomToSketch(sketchId)
  //   let temp = {}
  //   temp[sketchId] = false
  //   // setTilesetSettingVisible(temp)
  //   sketchingStore.setSketchFormVisible(true)
  //   sketchingStore.setCurrentEditSketch(sketchingStore.arrSketches.find(sketch => sketch.id === sketchId))
  //   sketchingStore.setCurrentSketchId(sketchId)

  //   projectStore.setDisplayPanel(false)
  //   projectStore.setSketchEdit(projectStore.selectedNode);
  //   // projectStore.setVisitedMode(true)
  // }

  const checkVisibleFeature = (selectedNode) => {
    const model3D = findModel3DByKey(selectedNode.key);
    const treeData = projectStore.loadedModelTreeData[model3D];
    const node = TreeUtils.searchTreeNode(treeData, 'key', selectedNode.key);
    const features = findAllGUID([node]);
    const hideFeatures = projectStore.hideFeatures[model3D] || [];
    const check = features.map(feature => !hideFeatures.includes(feature))
    const allHide = check.every(x => !x)
    return {
      allHide: !isVisibleModel(model3D) ? true : allHide,
      hideFeatures: features,
      model3D
    }
  }

  const toggleVisibleFeature = (selectedNode) => {
    const { hideFeatures, allHide, model3D } = checkVisibleFeature(selectedNode);
    let newHideFeatures = projectStore.hideFeatures[model3D] || [];
    if (!allHide) {
      hideFeatures.map(feature => {
        if (!newHideFeatures.includes(feature)) {
          newHideFeatures = [...newHideFeatures, feature];
        }
      })
    } else {
      if (!isVisibleModel(model3D)) {
        toggleVisibleTileset(model3D, !isVisibleModel(model3D))
      }
      hideFeatures.map(feature => {
        newHideFeatures = newHideFeatures.filter(f => f !== feature)
      })
    }
    projectStore.setSelectedAttrData({
      ...projectStore.selectedAttrData,
      model3D,
      GUID: selectedNode.GUID
    });
    projectStore.setHideFeatures({
      ...projectStore.hideFeatures,
      [model3D]: newHideFeatures
    })
  }

  const findAllGUID = (data, GUID = []) => {
    data.map(item => {
      if (item.children) {
        findAllGUID(item.children, GUID)
      }
      if (item.GUID) {
        GUID.push(item.GUID)
      }
    })
    return GUID;
  }

  const isModel = (node) => {
    return !node ? false : (node && node.modelId) ? true : false;
  }
  const isFileIcon = (node) => {
    if (node?.sourceType === 'local' && node?.modelType === 'unknown') {
      return true
    }
  }
  const is3DModel = (node) => {
    if (!node) return false
    const sourceType = node && node.sourceType ? node.sourceType.toLowerCase() : ''
    const type = node && node.modelType ? node.modelType.toLowerCase() : ''
    const modelType3D = ['landxml', 'landxmlbackground', 'ifc', 'model3d', 'cloudpoint', 'e57', 'kmz', 'external-plugin', 'geotiff', 'geojson', 'cad']
    const sourceType3D = ['external', 'wms', 'wfs', 'city3ddb']
    if (modelType3D.indexOf(type) > -1 || sourceType3D.indexOf(sourceType) > -1) {      
      return true
    }
    return false
  }

  const isGeoTIFF = (node) => {
    if (!node) return false
    return isModel(node) && (node.sourceType === 'local' && node.modelType === 'geotiff');
  }

  const isGeoJSON = (node) => {
    if (!node) return false
    return isModel(node) && (node.sourceType === 'local' && node.modelType === 'geojson');
  }

  const is3DCityDB = (node) => {
    if (!node) return false
    return isModel(node) && node.sourceType === 'City3DDB';
  }

  const isWMS = (node) => {
    if (!node) return false
    return isModel(node) && (node.sourceType === 'WMS' || node.sourceType === 'WFS');
  }

  const isTerrain = (node) => {
    if (!node) return false
    return isModel(node) && node.modelType === 'terrain';
  }

  const is3DTiles = (node) => {
    if (!node) return false
    return node && node.sourceType === 'external' && !node?.data?.cesiumToken
  }

  const isFolder = (node) => {
    return !node ? false : (node && node.type === 'FOLDER') ? true : false;
  }

  const isSketch = (node) => {
    return !node ? false : (node && node.sketchId) ? true : false;
  }

  const isProjectLink = (node) => {
    return !node ? false : (node && node.link?.name) ? true : false;
  }

  const isAttr = (node) => {
    const attrTitle = ['Scenario 1', 'Not referenced', 'IfcProject', 'Header Info', 'Space Boundaries']
    return !node ? false : (node && (node.node || attrTitle.includes(node.title))) ? true : false
  }

  /**
   * Check node type == feedback?
   * @param {*} node 
   */
  const isFeedback = (node) => {
    return !node ? false : (node && node.type === 'feedback') ? true : false;
  }

  const isTopic = (node) => {
    return !node ? false : (node && node.type === 'topic') ? true : false;
  }

  const toggleVisibleFeedbackFolder = (type) => {
    if (type === 'Answers') {
      let isAnyNodeShow = feedbackStore.visibleFeedback.some(el => el.isShow === true) //check if has any child node feedback selected and show then hide all
      const _visibleFeedback = feedbackStore.visibleFeedback.map(item => {
        item.isShow = isAnyNodeShow ? false : true
        // }
        return item
      })
      feedbackStore.setVisiblFeedback(_visibleFeedback)
    }
    if (type === 'Forms') {
      let isAnyNodeShow = feedbackStore.visibleFeedbackForm.some(el => el.isShow === true) //check if has any child node feedback selected and show then hide all
      const _visibleFeedbackForm = feedbackStore.visibleFeedbackForm.map(item => {
        item.isShow = isAnyNodeShow ? false : true
        // }
        return item
      })
      feedbackStore.setVisiblFeedbackForm(_visibleFeedbackForm)
    }
    if (type === 'Root') {

      //feedback answers
      let isAnyNodeShow = feedbackStore.visibleFeedback.some(el => el.isShow === true) || feedbackStore.visibleFeedbackForm.some(el => el.isShow === true) //check if has any child node feedback selected and show then hide all
      const _visibleFeedback = feedbackStore.visibleFeedback.map(item => {
        item.isShow = isAnyNodeShow ? false : true
        // }
        return item
      })
      feedbackStore.setVisiblFeedback(_visibleFeedback)

      //feedback forms
      const _visibleFeedbackForm = feedbackStore.visibleFeedbackForm.map(item => {
        item.isShow = isAnyNodeShow ? false : true
        // }
        return item
      })
      feedbackStore.setVisiblFeedbackForm(_visibleFeedbackForm)
    }
  }

  const checkboxVisibleFeedback = () => {

    if (projectStore.selectedNode?.typeFolder === "answer") {
      let isAnyNodeShow = feedbackStore.visibleFeedback.some(el => el.isShow === true && el.selected === true)
      const _visibleFeedback = feedbackStore.visibleFeedback.map(item => {
        if (item.selected) { //only change state with node selected
          item.isShow = isAnyNodeShow ? false : !item.isShow
        }
        return item
      })
      feedbackStore.setVisiblFeedback(_visibleFeedback)
    }

    if (projectStore.selectedNode?.typeFolder === "form") {
      let isAnyNodeShow = feedbackStore.visibleFeedbackForm.some(el => el.isShow === true)
      const _visibleFeedbackForm = feedbackStore.visibleFeedbackForm.map(item => {
        if (item.selected) { //only change state with node selected
          item.isShow = isAnyNodeShow ? false : !item.isShow
        }
        return item
      })
      feedbackStore.setVisiblFeedbackForm(_visibleFeedbackForm)
    }

    if (projectStore.selectedNode?.title === "Feedback") {
      let isAnyNodeShow = feedbackStore.visibleFeedback.some(el => el.isShow === true) || feedbackStore.visibleFeedbackForm.some(el => el.isShow === true)
      const _visibleFeedback = feedbackStore.visibleFeedback.map(item => {
        if (item.selected) { //only change state with node selected
          item.isShow = isAnyNodeShow ? false : !item.isShow
        }
        return item
      })
      feedbackStore.setVisiblFeedback(_visibleFeedback)
      const _visibleFeedbackForm = feedbackStore.visibleFeedbackForm.map(item => {
        if (item.selected) { //only change state with node selected
          item.isShow = isAnyNodeShow ? false : !item.isShow
        }
        return item
      })
      feedbackStore.setVisiblFeedbackForm(_visibleFeedbackForm)
    }
  }

  const toggleVisibleFeedbackType = controlName => {
    const _visibleFeedback = feedbackStore.visibleFeedback.map(item => {
      if (item.controlName === controlName) {
        item.isShow = !item.isShow
      }
      return item
    })
    feedbackStore.setVisiblFeedback(_visibleFeedback)
  }

  const toggleVisibleFeedbackForm = controlName => {
    const _visibleFeedbackForm = feedbackStore.visibleFeedbackForm.map(item => {
      if (item.controlName === controlName) {
        item.isShow = !item.isShow
      }
      return item
    })
    feedbackStore.setVisiblFeedbackForm(_visibleFeedbackForm)
  }


  const checkVisibleFeedbackNodes = () => {
    if (projectStore.selectedNode?.typeFolder === "answer") {
      return feedbackStore.visibleFeedback.length > 0 && feedbackStore.visibleFeedback.some(el => el.isShow === true && el.selected === true)
    }

    if (projectStore.selectedNode?.typeFolder === "form") {
      return feedbackStore.visibleFeedbackForm.length > 0 && feedbackStore.visibleFeedbackForm.some(el => el.isShow === true && el.selected === true)
    }

    if (projectStore.selectedNode?.title === "Feedback") {
      return (feedbackStore.visibleFeedbackForm.length > 0 && feedbackStore.visibleFeedbackForm.some(el => el.isShow === true && el.selected === true) || feedbackStore.visibleFeedback.length > 0 && feedbackStore.visibleFeedback.some(el => el.isShow === true && el.selected === true))
    }

  }

  const checkVisibleTopicNodes = () => {
    return topicStore.visibleTopic.length > 0 && topicStore.visibleTopic.some(el => el.isShow === true && el.selected === true)
  }

  const checkONOFFFeedbackForm = () => {
    let data = feedbackStore.visibleFeedbackForm.filter(elm => !elm.isShow)
    let isShow = data && data.length === feedbackStore.feedbackformNormalVisualization?.length ? true : false
    return isShow
  }


  const getGenericFeedback = async () => {
    let _feedbacktree = [
      {
        title: 'Feedback',
        key: idFeedback,
        type: 'feedback',
        icon: () => {
          return (
            <>
              <SVGIcon
                color={isHiddenFeedbackFolder({ title: 'Feedback' }) ? 'rgb(151,160,175)' : ''}
                content={<ResourceFeedbackFolderIcon />} width={20} height={20} />
              {' '}
              <Avatar
                shape="square"
                icon={<SVGIcon
                  className={isHiddenFeedbackFolder({ title: 'Feedback' }) ? 'hide' : ''}
                  content={isHiddenFeedbackFolder({ title: 'Feedback' }) ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  color={isHiddenFeedbackFolder({ title: 'Feedback' }) ? 'rgb(151,160,175)' : ''}
                  width={20}
                  height={20}
                />}
                size={20}
                onClick={() => toggleVisibleFeedbackFolder('Root')}
              />{' '}
            </>
          )
        }
        ,
        children: [
          {
            title: 'Forms',
            key: idFeedbackForms,
            type: 'feedback',
            typeFolder: 'form',
            icon: () => {
              return (
                <>
                  <SVGIcon
                    color={checkONOFFFeedbackForm() ? 'rgb(151,160,175)' : ''}
                    content={<ResourceFeedbackFolderIcon />} width={20} height={20} />
                  {' '}
                  <Avatar
                    shape="square"
                    icon={<SVGIcon
                      className={checkONOFFFeedbackForm() ? 'hide' : ''}
                      content={checkONOFFFeedbackForm() ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                      color={checkONOFFFeedbackForm() ? 'rgb(151,160,175)' : ''}
                      width={20}
                      height={20}
                    />}
                    size={20}
                    onClick={() => toggleVisibleFeedbackFolder('Forms')}
                  />{' '}
                </>
              )
            }
            ,
            children: []
          },
          {
            title: 'Answers',
            key: idFeedbackAnswer,
            type: 'feedback',
            typeFolder: 'answer',
            icon: () => {
              return (
                <>
                  <SVGIcon
                    color={(feedbackStore.hiddenTFeedback.length === feedbackStore.visibleFeedback.length) ? 'rgb(151,160,175)' : ''}
                    content={<ResourceFeedbackFolderIcon />} width={20} height={20} />
                  {' '}
                  <Avatar
                    shape="square"
                    icon={<SVGIcon
                      className={(feedbackStore.hiddenTFeedback.length === feedbackStore.visibleFeedback.length) ? 'hide' : ''}
                      content={(feedbackStore.hiddenTFeedback.length === feedbackStore.visibleFeedback.length) ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                      color={(feedbackStore.hiddenTFeedback.length === feedbackStore.visibleFeedback.length) ? 'rgb(151,160,175)' : ''}
                      width={20}
                      height={20}
                    />}
                    size={20}
                    onClick={() => toggleVisibleFeedbackFolder('Answers')}
                  />{' '}
                </>
              )
            }
            ,
            children: []
          }
        ]
      }
    ]
    if (feedbackStore.feedbackformNormalVisualization.length > 0) {
      let _fbArrs = feedbackStore.feedbackformNormalVisualization
      for (const element of _fbArrs) {
        let _subchild = {
          title: element.title,
          isLeaf: true,
          key: element.id,
          type: 'feedback',
          icon: ({ expanded }) => iconFeedbackForm(element, expanded),
          controlName: element.id,
          typeFolder: 'form'
        }
        _feedbacktree[0].children[0].children.push(_subchild)
      }
    }
    if (feedbackStore.feedbackFormDetail) {
      let layerGenericFeedback = feedbackStore.feedbackVisualization?.map(item => toJS(item.formControlData.elements))
      let arr2 = [...new Set(layerGenericFeedback.flat(1))];

      const feedbackForm = {}
      for (let i = 0; i < arr2.length; i++) {
        const item = arr2[i];
        if (!feedbackForm[item.name]) {
          feedbackForm[item.name] = []
        }
        feedbackForm[item.name].push(item)
      }
      for (const key in feedbackForm) {
        const GenericFeedback = feedbackForm[key];
        const el = GenericFeedback[0]
        let _subchild = {
          title: el.title,
          isLeaf: true,
          key: el.name,
          type: 'feedback',
          icon: ({ expanded }) => iconFeedback(el, expanded),
          controlName: el.name,
          typeFolder: 'answer'
        }
        _feedbacktree[0].children[1].children.push(_subchild)
      }
      // setTreeDataFeedback(_feedbacktree)
    }

    if (_feedbacktree[0].children[0] && _feedbacktree[0].children[0].children && _feedbacktree[0].children[0].children?.length === 0) {
      _feedbacktree[0].children.splice(0, 1)
    }
    if (_feedbacktree[0].children[1] && _feedbacktree[0].children[1].children && _feedbacktree[0].children[1].children?.length === 0) {
      _feedbacktree[0].children.splice(1, 1)
    }
    setTreeDataFeedback(_feedbacktree);
  }


  const isHiddenFeedbackFolder = (nodeData) => {
    let _hiddenForms = feedbackStore.visibleFeedbackForm.filter(c => !c.isShow)

    if (nodeData.title === "Answers") {
      return feedbackStore.hiddenTFeedback.length === feedbackStore.visibleFeedback.length
    }
    if (nodeData.title === "Forms") {
      return feedbackStore.visibleFeedbackForm && feedbackStore.visibleFeedbackForm.length === _hiddenForms.length
    }
    if (nodeData.title === "Feedback") {
      return feedbackStore.hiddenTFeedback.length === feedbackStore.visibleFeedback.length && feedbackStore.visibleFeedbackForm.length === _hiddenForms.length
    }
  }

  const titleFeedBackRender = (nodeData) => {
    let hide = false;
    if (
      nodeData.title === "Answers" && isHiddenFeedbackFolder(nodeData) ||
      nodeData.title === "Forms" && isHiddenFeedbackFolder(nodeData) ||
      nodeData.title !== 'Feedback' && nodeData.title !== 'Answers' && nodeData.title !== 'Forms' && nodeData.type === 'feedback' && nodeData.typeFolder === 'answer' && !isVisibleFeedbackIcon(nodeData.controlName) ||
      nodeData.title !== 'Feedback' && nodeData.title !== 'Answers' && nodeData.title !== 'Forms' && nodeData.type === 'feedback' && nodeData.typeFolder === 'form' && !isVisibleFeedbackFormIcon(nodeData.controlName) ||
      nodeData.title === 'Feedback' && isHiddenFeedbackFolder({ title: 'Feedback' })
    ) {
      hide = true;
    }
    return <TreeNodeTitle hide={hide}>{nodeData.title}</TreeNodeTitle>
  }

  const titleModelLinkRender = (nodeData) => {
    let hide = !nodeData.isVisible;
    return <TreeNodeTitle hide={hide}>{nodeData.title}</TreeNodeTitle>
  }

  const iconFeedbackForm = (el) => {
    return (<>
      <SVGIcon
        color={isVisibleFeedbackFormIcon(el.id) ? '' : 'rgb(151,160,175)'}
        content={<ResourceFeedbackIcon />} width={20} height={20} />
      {' '}
      <Avatar
        shape="square"
        icon={<SVGIcon
          className={isVisibleFeedbackFormIcon(el.id) ? '' : 'hide'}
          content={isVisibleFeedbackFormIcon(el.id) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          color={isVisibleFeedbackFormIcon(el.id) ? '' : 'rgb(151,160,175)'}
          width={20}
          height={20}
        />}
        onClick={() => toggleVisibleFeedbackForm(el.id)}
        size={20}
      />{' '}
    </>)
  }

  const iconFeedback = (el) => {
    return (<>
      <SVGIcon
        color={isVisibleFeedbackIcon(el.name) ? '' : 'rgb(151,160,175)'}
        content={<ResourceFeedbackIcon />} width={20} height={20} />
      {' '}
      <Avatar
        shape="square"
        icon={<SVGIcon
          className={isVisibleFeedbackIcon(el.name) ? '' : 'hide'}
          content={isVisibleFeedbackIcon(el.name) ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          color={isVisibleFeedbackIcon(el.name) ? '' : 'rgb(151,160,175)'}
          width={20}
          height={20}
        />}
        onClick={() => toggleVisibleFeedbackType(el.name)}
        size={20}
      />{' '}
    </>)
  }

  const titleRender = (nodeData) => {
    let hide = false;
    if (
      isModel(nodeData) && !isVisibleModel(nodeData.modelId) ||
      isSketch(nodeData) && !isVisibleSketch(nodeData.sketchId) ||
      isFolder(nodeData) && !isVisibleFolder(treeData, nodeData.key) ||
      nodeData.title !== 'Feedback' && nodeData.type === 'feedback' && !isVisibleFeedbackIcon(nodeData.controlName) ||
      nodeData.title === 'Feedback' && isVisibleFeedback.length === 0 ||
      nodeData.title !== 'Topic' && nodeData.type === 'topic' && !isVisibleTopic(nodeData.controlName) ||
      nodeData.title === 'Topic' && topicStore.hiddenTopicType.length === topicStore.visibleTopic.length ||
      isAttr(nodeData) && nodeData.GUID && checkVisibleFeature(nodeData).allHide
    ) {
      hide = true;
    }

    if (nodeData.title === 'Feedback' && (isVisibleFeedback.length > 0 || onCheckFeedback)) {
      hide = false;
    }
    return <TreeNodeTitle
      hide={hide}
    >{nodeData.title}
    </TreeNodeTitle>
  }
  const projectLinkDrawer = () => {
    projectStore.setShowProjectLinkDrawer(true);
    projectStore.setDisplayPanel(false);
    setCheckedKeys([])
    setSelectedKeys([])
  }
  const handleDeteteProjectLink = async () => {
    projectStore.setLoadingProgress(true)
    if (projectStore?.selectedNode?.id) {
      await projectStore.deleteProjectLink(projectStore.selectedNode.id)
      projectStore.setListProjectLink(projectStore.listProjectLink.filter(x => x.id !== projectStore.selectedNode.id))
    }
    else if (checkedKeys.length > 0) {
      await Promise.all(checkedKeys.map(async item => {
        await projectStore.deleteProjectLink(item)
      }))
      projectStore.setListProjectLink(projectStore.listProjectLink.filter(x => !checkedKeys.includes(x.id)))
    };
    projectStore.updateProjectStorage(projectId)
    await projectStore.getListProjectLink(projectId).then(() => {
      projectStore.setLoadingProgress(false)
      setCheckedKeys([])
      setSelectedKeys([])
      projectStore.setSelectedNode(null);
    })
      .catch(() => projectStore.setLoadingProgress(false))
  }

  function getFileExtension(url) {
    // Extract the file extension using a regular expression
    const match = url.match(/\.([0-9a-z]+)(?:[?#]|$)/i);

    // Check if a match is found
    if (match && match[1]) {
        return '.' + match[1];
    } else {
        // Return null if no file extension is found
        return null;
    }
  }

  useEffect(() => {
    if(projectStore.selectedNode && projectStore.isDownloadingModel3d?.length > 0){
      const { modelId } = projectStore.selectedNode;
      const downloadingModel = projectStore.isDownloadingModel3d.some(el => el === modelId);
      setIsDownLoadingModel(downloadingModel);
    }else setIsDownLoadingModel(false);
  }, [projectStore.selectedNode, projectStore.isDownloadingModel3d])

  const handleAdjustDownLoadModelQueue = (modelId, action = 'add') =>{
    if(action === 'add'){
      if(projectStore.isDownloadingModel3d?.length > 0 && !projectStore.isDownloadingModel3d?.includes(modelId)){
          projectStore.setIsDownloadingModel3d([...projectStore.isDownloadingModel3d, modelId]);
      }else projectStore.setIsDownloadingModel3d([modelId]);
      return;
    }
    if(projectStore.isDownloadingModel3d?.length > 0){
       projectStore.setIsDownloadingModel3d(projectStore.isDownloadingModel3d.filter(el => el !== modelId));
     }
  }

  const handleDownloadModel = () =>{
    const { modelId } = projectStore.selectedNode;
    const model = projectStore.modelList.find(model => model._id === modelId);
    if(!model || !model?.src) {
      message.error(t('model-not-available'));
      return;
    }
    const fileExtenstion = getFileExtension(model.src);
    if(fileExtenstion && fileExtenstion !== '.json' && model?.name){
      handleAdjustDownLoadModelQueue(modelId);
      fetch(model.src)
      .then(response => response.blob())
      .then(blob => {
          // Create a link element
          const link = document.createElement('a');
          
          // Create a Blob URL for the blob
          const blobUrl = window.URL.createObjectURL(blob);

          // Set the download attribute and filename
          link.setAttribute('href', blobUrl);
          link.setAttribute('download', `${model.name}`);

          // Append the link to the body and trigger the click event
          document.body.appendChild(link);
          link.click();

          // Remove the link after the download
          document.body.removeChild(link);

          // Revoke the Blob URL
          window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => console.error('Error downloading file:', error)).finally(() => handleAdjustDownLoadModelQueue(modelId, 'remove'));
    }else{
      message.error(t('model-not-available'));
      // case for link model
      // Object.assign(document.createElement('a'), {
      //   target: '_blank',
      //   href: model.src,
      // }).click();
    }
  }
  return (
    <>
      <LeftPanelActions>
        {!projectStore.visitedMode && !isFeedback(projectStore.selectedNode) && (!projectStore.selectedNode || (projectStore.selectedNode && isFolder(projectStore.selectedNode) && checkVisibleIconUserGroups(projectStore.selectedNode, 'modify'))) && projectStore.isExistLicenses && checkingFeatureRole("feature_data_tree_edit") ? (
          <Tooltip title={t('add-data')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            <Button
              icon={<SVGIcon content={<DataAddIcon />} width={32} height={32} />}
              onClick={addResourceClick}
            />
          </Tooltip>
        ) : null
        }
        {
          !projectStore.visitedMode && (!projectStore.selectedNode || (projectStore.selectedNode && isFolder(projectStore.selectedNode) && checkVisibleIconUserGroups(projectStore.selectedNode, "modify"))) && checkingFeatureRole("feature_data_tree_edit") ? (
            <Tooltip
              title={t('add-folder')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<DataAddFolderIcon />} width={32} height={32} />}
                onClick={() => showModalAddFolder()}
              />
            </Tooltip>) : null
        }
        {
          !projectStore.selectedNode?.fileid && !projectStore.visitedMode && isModel(projectStore.selectedNode) && !isWMS(projectStore.selectedNode) && !is3DCityDB(projectStore.selectedNode) && !is3DTiles(projectStore.selectedNode) && projectStore.isExistLicenses && checkingFeatureRole("feature_data_tree_edit") && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "modify")) ? (
            <Tooltip
              title={t('update-data')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<DataUpdateIcon />} width={32} height={32} />}
                onClick={() => updateResourceClick(false)}
              />
            </Tooltip>
          ) : null
        }
        {
          is3DModel(projectStore.selectedNode) && !isWMS(projectStore.selectedNode) && !is3DCityDB(projectStore.selectedNode) && checkingFeatureRole("feature_data_tree_download") && (<Tooltip
            title={t('download')}
            overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            {!isDownLoadingModel ? <Button
              icon={<SVGIcon content={<DownLoadModel />} width={32} height={32} /> }
              onClick={handleDownloadModel}
              //loading={isDownLoadingModel}
            /> :
            <Button className='ant-btn-icon-only' style={{top: -8}}>
              <LoadingOutlined />
            </Button>}
          </Tooltip>)
        }
        {
          is3DModel(projectStore.selectedNode) && checkingFeatureRole("feature_data_tree_edit") && !projectStore.visitedMode && !isWMS(projectStore.selectedNode) && !is3DCityDB(projectStore.selectedNode) && !isTerrain(projectStore.selectedNode) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "modify")) && (
            <Tooltip
              title={t('data-settings')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<EditIcon />} width={32} height={32} />}
                onClick={() => {
                  getCurrent3DViewSetting(true)
                  clickEditLocation(projectStore.selectedNode.modelId)
                }}
              />
            </Tooltip>
          )
        }
        {
          !projectStore.visitedMode && checkingFeatureRole("feature_data_tree_edit") && (isWMS(projectStore.selectedNode) || is3DCityDB(projectStore.selectedNode) || isFileIcon(projectStore.selectedNode) || isFolder(projectStore.selectedNode)) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "modify")) && (
            <Tooltip
              title={t('data-settings')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<EditIcon />} width={32} height={32} />}
                onClick={() => {
                  getCurrent3DViewSetting(true)
                  updateResourceClick(true)
                }}
              />
            </Tooltip>
          )
        }
        {isSketch(projectStore.selectedNode) && !projectStore.visitedMode && checkingFeatureRole("feature_data_tree_edit") && checkingFeatureRole("feature_sketch") && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "modify")) &&
          (
            <Tooltip
              title={t('data-settings')}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<EditIcon />} width={32} height={32} />}
                onClick={() => {
                  getCurrent3DViewSetting(true)
                  clickEditSketch(projectStore.selectedNode.sketchId)
                }}
              />
            </Tooltip>
          )
        }
        {
          isProjectLink(projectStore.selectedNode) && (
            <Tooltip
              title={t('data-settings')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<EditIcon />} width={32} height={32} />}
                onClick={() => projectLinkDrawer()}
              />
            </Tooltip>
          )
        }
        {
          !projectStore.selectedNode?.fileid && (isModel(projectStore.selectedNode) || isFolder(projectStore.selectedNode)) && checkingFeatureRole("feature_data_tree_edit") && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "modify")) ? (
            <Tooltip title={t('rename')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<RenameIcon />} width={32} height={32} />}
                onClick={() => clickEditFile(projectStore.selectedNode)}
              />
            </Tooltip>
          ) : null
        }
        {
          isAttr(projectStore.selectedNode) && projectStore.selectedNode.GUID && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "both")) && (
            <Tooltip
              title={!checkVisibleFeature(projectStore.selectedNode).allHide ? t('hide') : t('show')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              {!checkVisibleFeature(projectStore.selectedNode).allHide ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleFeature(projectStore.selectedNode)}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleFeature(projectStore.selectedNode)}
                />
              )}
            </Tooltip>)
        }
        {
          (is3DModel(projectStore.selectedNode) || isFileIcon(projectStore.selectedNode)) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "both")) && (
            <Tooltip
              title={isVisibleModel(projectStore.selectedNode.modelId) ? t('hide') : t('show')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}
            >
              {isVisibleModel(projectStore.selectedNode.modelId) ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleTileset(projectStore.selectedNode.modelId, false)}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleTileset(projectStore.selectedNode.modelId, true)}
                />
              )}
            </Tooltip>)
        }
        {
          (isProjectLink(projectStore.selectedNode)) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (
            <Tooltip
              title={isVisibleProjectLink(projectStore.selectedNode.id) ? t('hide') : t('show')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              {isVisibleProjectLink(projectStore.selectedNode.id) ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleIcon(projectStore.selectedNode, 'show')}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleIcon(projectStore.selectedNode, 'show')}
                />
              )}
            </Tooltip>)
        }
        {
          isFolder(projectStore.selectedNode) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "both")) && (
            <Tooltip
              title={isVisibleFolder(treeData, projectStore.selectedNode.key) ? t('hide') : t('show')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              {isVisibleFolder(treeData, projectStore.selectedNode.key) ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleFolder(treeData, projectStore.selectedNode.key)}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleFolder(treeData, projectStore.selectedNode.key)}
                />
              )}
            </Tooltip>)
        }
        {
          isSketch(projectStore.selectedNode) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "both")) && (
            <Tooltip
              title={isVisibleSketch(projectStore.selectedNode.sketchId) ? t('hide') : t('show')}
              mouseEnterDelay={0} mouseLeaveDelay={0}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              {isVisibleSketch(projectStore.selectedNode.sketchId) ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleSketch(projectStore.selectedNode.sketchId)}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleSketch(projectStore.selectedNode.sketchId)}
                />
              )}
            </Tooltip>
          )
        }
        {
          checkedNodes.some(node => (isModel(node) || isSketch(node)) && (node && checkVisibleIconUserGroups(node, "both"))) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) ? (
            <Tooltip
              title={checkVisibleCheckedNodes() ? t('hide') : t('show')} mouseEnterDelay={0} mouseLeaveDelay={0}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              {checkVisibleCheckedNodes() ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisible()}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisible()}
                />
              )}
            </Tooltip>
          ) : null
        }
        {
          ((isModel(projectStore.selectedNode) && !isTerrain(projectStore.selectedNode)) || isSketch(projectStore.selectedNode)) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (projectStore.selectedNode && checkVisibleIconUserGroups(projectStore.selectedNode, "both")) ? (
            <Tooltip title={t('zoom-to-object')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<ZoomIcon />} width={32} height={32} />}
                onClick={() => isModel(projectStore.selectedNode) ? clickTileset(projectStore.selectedNode.modelId) : clickSketch(projectStore.selectedNode.sketchId)}
              />
            </Tooltip>
          ) : null
        }
        {
          isAttr(projectStore.selectedNode) && projectStore.selectedNode.GUID ? (
            <Tooltip title={t('object-info')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                icon={<SVGIcon content={<InfoIcon />} width={32} height={32} />}
                onClick={() => clickObjectInfo()}
              />
            </Tooltip>
          ) : null
        }
        {
          listModelRebuild.length > 0 && isNotModel.length === 0 && checkingFeatureRole("feature_data_tree_edit") && projectStore.isExistLicenses && projectStore.selectedNode?.modelType !== 'kmz'
            && !projectStore.modelList?.find(x => x.id === projectStore.selectedNode?.modelId)?.templateId && (
              (
                (projectStore.selectedNode && isAllChildrenNoRights(projectStore.selectedNode) && checkVisibleIconUserGroups(projectStore.selectedNode, "modify")) || (findTreeNodes(listModelRebuild)).every(node => checkVisibleIconUserGroups(node, "modify"))
              )
            ) ? (
            <Tooltip title={t('rebuild')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                style={{ border: '1px solid #000', borderRadius: '4px', top: '-11px' }}
                icon={<SVGIcon color={'#191919'} content={<ReloadOutlined />} />}
                onClick={() => handleRebuildModel()}
              />
            </Tooltip>
          ) : null
        }
        {
          (
            !projectStore.visitedMode &&
            (
              (
                projectStore.selectedNode && isAllChildrenNoRights(projectStore.selectedNode) && checkVisibleIconUserGroups(projectStore.selectedNode, "modify") && (
                  isModel(projectStore.selectedNode) || (isSketch(projectStore.selectedNode) && checkingFeatureRole("feature_sketch")) || isFolder(projectStore.selectedNode))
              )
              || (
                checkedNodes?.length > 0 && checkedNodes.every(node => (isModel(node) || isSketch(node) || isFolder(node)) && checkVisibleIconUserGroups(node, "modify"))
              )
            )
            && checkingFeatureRole("feature_data_tree_edit")
          ) ? (
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => handleDetete()}
              placement="bottomLeft"
              okText={t('commons.ok')}
              cancelText={t('commons.cancel')}
              okButtonProps={{ danger: true }}
              title={
                <span>
                  {t('are-you-sure-you-want-to-delete')}
                </span>
              }>
              <Tooltip
                title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  icon={<SVGIcon content={<DeleteIcon />} width={32} height={32} />}
                />
              </Tooltip>
            </Popconfirm>
          ) : ""
        }
        {
          (((isProjectLink(projectStore.selectedNode))
            || checkedNodes.some(node => isProjectLink(node))) && checkingFeatureRole("feature_data_tree_edit")) ? (
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => handleDeteteProjectLink()}
              placement="bottomLeft"
              okText={t('commons.ok')}
              cancelText={t('commons.cancel')}
              okButtonProps={{ danger: true }}
              title={
                <span>
                  {t('are-you-sure-you-want-to-delete')}
                </span>
              }>
              <Tooltip
                title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                  icon={<SVGIcon content={<DeleteIcon />} width={32} height={32} />}
                />
              </Tooltip>
            </Popconfirm>
          ) : ""
        }
        {
          (isFeedback(projectStore.selectedNode) || checkedNodes.some(node => isFeedback(node))) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) ? (
            <Tooltip
              title={checkVisibleFeedbackNodes() ? t('hide') : t('show')}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}
            >
              {checkVisibleFeedbackNodes() ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => checkboxVisibleFeedback()}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => checkboxVisibleFeedback()}
                />
              )}
            </Tooltip>
          ) : null
        }
        {
          (isTopic(projectStore.selectedNode) || checkedNodes.some(node => isTopic(node))) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) ? (
            <Tooltip
              title={checkVisibleTopicNodes() ? t('hide') : t('show')}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}
            >
              {checkVisibleTopicNodes() ? (
                <Button
                  icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleTopic()}
                />
              ) : (
                <Button
                  icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                  onClick={() => toggleVisibleTopic()}
                />
              )}
            </Tooltip>
          ) : null
        }
        <Tooltip
          title={t('commons.help')}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}
        >
          <Button
            style={{ top: '-6px', boxShadow: 'none' }}
            icon={
              <SVGIcon
                width={32} height={32}
                content={
                  <HelpButton
                    fontsize={20}
                    helppage={"datatree"}
                    styles={`
                          padding: 5px;
                          border-radius: 3px;`}
                  />
                }
              />
            }
          />
        </Tooltip>
      </LeftPanelActions>
      <Spin tip={t('loading-tree')} spinning={treeLoading}>
        {treeData && treeData.length ? (
          <TreeDataFolder>
            <Tree
              className="draggable-tree treeData-panel"
              checkable
              showIcon
              draggable={checkingFeatureRole("feature_data_tree_edit") ? true : false}
              blockNode
              onDrop={onDrop}
              switcherIcon={<DownOutlined />}
              treeData={treeData}
              onSelect={onSelect}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={onExpand}
              autoExpandParent={autoExpandParent}
              loadData={onLoadData}
              loadedKeys={loadedKeys}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              titleRender={(nodeData) => titleRender(nodeData)}
              allowDrop={({ dragNode, dropNode, dropPosition }) => {
                let dragAvailable = dragNode && isAllChildrenNoRights(dragNode) && checkVisibleIconUserGroups(dragNode, "modify")
                let dropAvailable = dropNode && checkAvailableDropNode(dropNode, dropPosition)
                return dragAvailable && dropAvailable
              }}
            >
            </Tree>
          </TreeDataFolder>
        ) : null}
        {treeDataProjectLink && treeDataProjectLink.length && projectStore.listProjectLink?.length > 0 ? (
          <Tree
            className="treeData-panel"
            checkable
            showIcon
            blockNode
            switcherIcon={<DownOutlined />}
            treeData={treeDataProjectLink}
            onSelect={onSelect}
            onCheck={onCheck}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            onExpand={onExpand}
            autoExpandParent={autoExpandParent}
            loadedKeys={loadedKeys}
            checkedKeys={checkedKeys}
            titleRender={(nodeData) => titleModelLinkRender(nodeData)}

          />
        ) : null}
        {treeDataFeedback && treeDataFeedback.length && (feedbackStore.visibleFeedback?.length > 0 || feedbackStore.feedbackformNormalVisualization.length > 0) ? (
          <Tree
            className="treeData-panel"
            checkable
            showIcon
            blockNode
            switcherIcon={<DownOutlined />}
            treeData={treeDataFeedback}
            onSelect={onSelect}
            onCheck={onCheck}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            onExpand={onExpand}
            autoExpandParent={autoExpandParent}
            loadedKeys={loadedKeys}
            checkedKeys={checkedKeys}
            titleRender={(nodeData) => titleFeedBackRender(nodeData)}

          />
        ) : null}
        {
          topicType && // check type of topic
          <Tree
            className="treeData-panel"
            checkable
            showIcon
            switcherIcon={<DownOutlined />}
            treeData={treeDataTopic}
            onSelect={onSelectTopic}
            onCheck={onCheckTopic}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            onExpand={onExpand}
            autoExpandParent={autoExpandParent}
            loadedKeys={loadedKeys}
            checkedKeys={checkedKeys}
            titleRender={(nodeData) => titleRender(nodeData)}
          />
        }
        {(!treeData || !treeData.length) && (!treeDataFeedback || !treeDataFeedback.length) ? (<Empty description={<span>{t('no-data')}</span>} />) : null}
      </Spin>
      <ModalAddFolder />
      <ModalEditFile />
      <ModalEditAccessControl />
    </>
  )
}

export default inject('projectStore', 'sketchingStore', 'uiStore', 'feedbackStore', 'topicStore', 'usersStore', 'adminStore', 'schedulingStore', 'fileStore', 'modelStore', 'userGroupStore','projectTeamsStore','commonStore')(observer(DataTreePanel))
