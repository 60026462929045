import styled from 'styled-components'
import {Button} from 'antd'

export const ToolbarWrapper = styled.div`
  border-bottom: 1px solid #333
  line-height: 40px;
  padding: 10px;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: column;
  align-items: center;
  height: fit-content;
  position: relative;
  gap: 15px;
  overflow: hidden;

  .disable-undo {
    cursor: not-allowed !important;
    opacity: 50%;
  }

  .toolbar-component {
    display: flex;
    align-items: center;
    height: fit-content;
    position: relative;
    gap: 15px;
    overflow: hidden;
  }
  .pb-7 {
    padding-bottom: 7px;
  }

  .ant-picker {
      border: 1px solid #000;
  }

  .onoffstyle {
    color: #404040;
    opacity: 25%;
  }

  /*
 *  STYLE 1
 */

  .toolbar-component::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .toolbar-component::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  .toolbar-component::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
  }

  .gantt_input_styled {
      border: 1px solid #333;
      background: #fff;
      padding: 4px 12px;      
      color: rgba(0,0,0,0.7);
      line-height: 20px;
  }
  .gantt_input_styled:hover {
    border-color: #f26524 !important;
  }
  .gantt-control .ant-select-selector {
    border-radius: 14px !important;
    border: 1px solid #333 !important;
  }
  .gantt-control .ant-select-arrow {
    color: #000 !important;
  }
  
  .gantt_input_styled:focus {
    border-color: #f26524 !important;
  }
  .max-content {
    text-wrap: nowrap;
  }

  .toolbar-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

export const MoreOptionWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 99999;
  background-color: #fff;
  height: 100%;
  justify-content: center;
  display: flex;
  padding: 20px 20px;
  .popover{
    margin-right: 10px;
  }

  .ant-btn {
    margin-right: 8px;
    cursor: pointer;
    background: #fff !important;
    border: none; 
  }
  .ant-btn-icon-only{  
    padding: 0;
  }
`

export const OptionWrapper = styled.div`
  .option {
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 2px;
    font-size: 14px;
  }
  .option:hover {
    background-color: rgb(245 245 245);
  }
`

export const PlayerWrapper = styled.div`
  cursor: pointer;
  ${({ active }) => (active ? `color: #ff8d4f;` : '')}
`
export const ButtonCustom = styled(Button)`
  padding: 0 !important;
  margin: 0;
  border-radius: 5px !important;
  vertical-align: middle !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5), inset 0px -3px 6px -2px rgba(0, 0, 0, 0.3) !important;
`
export const ButtonCustom1 = styled(Button)`
  padding: 0 !important;
  margin: 0;
  border-radius: 5px !important;
  vertical-align: middle !important;
  margin-right: 0 !important;
`

export const HeaderMenu = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;

  .add-column-btn {
    cursor: pointer;
  }
`