import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Empty, Modal, Tree } from 'antd'
import { convertKeyToModel, convertModelToKey } from '../Gantt/ganttUtils'
import { useTranslation } from 'react-i18next'
const { DirectoryTree } = Tree

const isModel = (node) => {
  return !node ? false : (node && node?.modelId) ? true : false;
}

const isFolder = (node) => {
  return !node ? false : (node && node?.type === 'FOLDER') ? true : false;
}

const is3DCityDB = (node) => {
  if (!node) return false
  return isModel(node) && node?.sourceType === 'City3DDB';
}

const isWMS = (node) => {
  if (!node) return false
  return isModel(node) && (node?.sourceType === 'WMS' || node?.sourceType === 'WFS');
}

const isLeaf = (item) => {
  return !((isFolder(item) && item?.children && item?.children?.length) || (isModel(item) && (item?.modelType === 'ifc' ||item?.modelType === 'cad' || (item?.modelType === 'landxml' || item?.modelType === 'landxmlBackground' || item?.modelType === 'external-plugin')))) || isWMS(item) || is3DCityDB(item)
}

const traversalTree = (data, parentKey) => {
  return data.map(item => {
    if (item.children) {
      return {
        ...item,
        children: traversalTree(item.children, item.key),
        parentKey,
        isLeaf: isLeaf(item),
      }
    }
    return {
      ...item,
      parentKey,
      isLeaf: isLeaf(item),
    }
  })
}

const ModalLinkGanttToDataTree = ({ projectGanttStore, projectStore }) => {
  const { t } = useTranslation()
  const [checkedNodes, setCheckedNodes] = useState([])
  const [treeData, setTreeData] = useState([])

  useEffect(() => {
    if (projectGanttStore.isOpenModalSelectTreeData.open) {
      if (projectStore.projectDetail?.treeData?.length) {
        const reStructureTreeData = traversalTree(
          projectStore.projectDetail?.treeData
        )
        setTreeData(reStructureTreeData)

        const normalizeData = convertModelToKey(
          projectStore.projectDetail?.treeData,
          projectGanttStore.selectedNodeInDataTree
        )
        setCheckedNodes(normalizeData || [])
      }
    }

    return () => {
      setCheckedNodes([])
    }
  }, [
    projectGanttStore.isOpenModalSelectTreeData.open,
    projectGanttStore.selectedNodeInDataTree,
  ])

  const handleCancelLinkToDatatree = () => {
    projectGanttStore.setIsOpenModalSelectTreeData({
      open: false,
      taskId: '',
    })
    setCheckedNodes([])
    //projectGanttStore.setSelectedNodeInDataTree([])
  }

  const onCheck = (checkedKeys, info) => {
    setCheckedNodes(checkedKeys)
  }

  const handlelinkDataTreetoGantt = () => {
    const normalizedData = convertKeyToModel(
      projectStore.projectDetail?.treeData,
      checkedNodes
    )
    projectGanttStore.setSelectedNodeInDataTree(normalizedData)
    projectGanttStore.setIsOpenModalSelectTreeData({
      open: false,
      taskId: '',
    })
    setCheckedNodes([])
  }

  return (
    <Modal
      title={t('gantt.select-4d-node-in-data-tree')}
      centered
      zIndex={10001}
      visible={projectGanttStore.isOpenModalSelectTreeData.open}
      okText={t('commons.save')}
      cancelText={t('commons.cancel')}
      onOk={handlelinkDataTreetoGantt}
      okButtonProps={{
        style: {
          display: projectGanttStore.isReadonlyMode ? 'none' : 'initial',
        },
      }}
      onCancel={handleCancelLinkToDatatree}
      style={{
        maxHeight: 'calc(100vh - 20px)',
        //overflowY: 'auto'
      }}
      bodyStyle={{
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
      }}>
      {treeData?.length ? (
        <DirectoryTree
          multiple
          defaultExpandAll
          checkable
          checkedKeys={checkedNodes}
          treeData={treeData}
          onCheck={onCheck}
          disabled={projectGanttStore.isReadonlyMode}
        />
      ) : (
        <Empty />
      )}
    </Modal>
  )
}

export default inject(
  'projectGanttStore',
  'projectStore'
)(observer(ModalLinkGanttToDataTree))
